import React, { Component } from 'react'
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Container
} from 'reactstrap';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import withRouter from "../../../../src/components/Common/withRouter";

import { Collapse } from 'antd';
const { Panel } = Collapse;

export class FormDetail extends Component<any,any> {

  constructor(props:any){
    super(props);
    console.log(this.props.route,'asjdkhaiwewoiqeq',props)
   
    this.state = {
      FormDatas:props.router?.location?.state.rowData

    }
  }


  render() {

    const {FormDatas} = this.state;
    console.log(this.state.FormDatas,'saoidj902j9rj2li');

    return (

      <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Form' breadcrumbItem={FormDatas.FormName} />
          <br />
         

   {/* <Panel header={`: Form Submission `} key={0}> */}

   

    {
      Object.keys(FormDatas).map((items:any,index:any)=>{

        if(FormDatas[items].includes('.pdf')){
          return(  
            <FormGroup row key={index}>
          <Label for='examplePassword' sm={2} style={{textAlign:"right",textTransform:"capitalize"}} >
            {items}
          </Label>
          <Col sm={4}>
          <a href={`https://api-news.nebrascorp.com/pivot-media/${FormDatas[items]}`} target="_blank" rel="noreferrer" style={{marginLeft:20,fontWeight:'bold'}}>Click here to open pdf</a>
          </Col>
        </FormGroup>
            )

        }
        else if(FormDatas[items].includes('.png')){
          return(
            <FormGroup row key={index}>
            <Label for='examplePassword' sm={2} style={{textAlign:"right",textTransform:"capitalize"}} >
              {items}
            </Label>
            <Col sm={4}>
            <a href={`https://api-news.nebrascorp.com/pivot-media/${FormDatas[items]}`} target="_blank" rel="noreferrer" style={{marginLeft:20,fontWeight:'bold'}}>Preview Image</a>
            </Col>
          </FormGroup>
          )

        }
        else{

        
          return(

            <FormGroup row key={index}>
        <Label for='examplePassword' sm={2} style={{textAlign:"right",textTransform:"capitalize"}} >
          {items}
        </Label>
        <Col sm={4}>
        <Input
          id='exampleSelect'
          name='select'
          value={FormDatas[items]}
          />
        </Col>
      </FormGroup>
          )

        }

        


  })

    }

                                   



    {/* </Panel> */}

   
  



          </Container>
          </div>
          </React.Fragment>

    )
  }
}

export default withRouter(FormDetail);
