import { combineReducers } from "redux"

// Layout
import Layout from "./layout/reducer";

//login
import login from "./auth/login/reducer";

// User Profile 
import profile from "./auth/profile/reducer";

// Forget Password
import forgetPassword from "./auth/forgetpwd/reducer";

//Dashboard
import dashboard from "./Dashboard/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  login,

  profile,
  forgetPassword,
  dashboard
})

export default rootReducer
