import React, { useState, useEffect, Component } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
} from "reactstrap";
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader";
import { Progress, Space } from "antd";

import _ from "lodash";
import { post } from "src/helpers/api_helper";
import RichTextEditor from "react-rte";
import axios from "axios";
// import { message, Upload } from 'antd'
import withRouter from "../../../src/components/Common/withRouter";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  EditOutlined,
  DeleteOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";

firebase.initializeApp({
  apiKey: "AIzaSyC6Qq4wX7cJnKRIqk2zx4QZzLy4kZe7_UA",
  authDomain: "neb-cms.firebaseapp.com",
  projectId: "neb-cms",
  storageBucket: "neb-cms.appspot.com",
  messagingSenderId: "261403062939",
  appId: "1:261403062939:web:9e920cf249263f6671cc3e",
  measurementId: "G-QSQK36QZR9",
});

// const uploadButton = (
//   <div>
//     <PlusOutlined />
//     <div style={{ marginTop: 8 }}>Upload</div>
//   </div>
// )
var objsAddmore: any = {};
var arrAddMore: any = {};
var objs2orderAddmore: any = {};
var save2orderDatas: any = [];
var addMoreGridsData: any = {};
var saveArrForRTE: any = [];
var once = true;
var stateOnce = true;
var objsHamza: any = {};
var completeFirstOrderArrayList: any = [];
var firstOrderArrayOnce = true;
var addmore2orderconverObjsintoArrs: any = [];


var indexForPagination: number;

class LayoutDetail extends Component<any, any> {
  private inputRef: React.RefObject<HTMLElement>;
  data: any = {};

  constructor(props: any) {
    super(props);

    this.inputRef = React.createRef();

    if (this.props.router !== undefined && this.props.router.location.state) {
      this.getSchema(
        this.props.router.location.state.data,
        this.props.router.location.state.pageName
      );
    }

    this.state = {
      SchemaData: [],
      pageData: "",

      // new Sortings with new States.....

      openHelpArrayObj1: false,
      openHelpArrayObj1Data: [],
      openHelpArrayObj1ParentArrName: "",
      rteDatas: null,
      emptyState: false,
      addMoreOfObjSection1Name: "",
      addMoreOfObjSection1: {},
      openAddMoreObjOfSection1: false,
      openDeleteForObjSectionArrs: false,
      openAddMoreArrOfSection1: false,
      addMoreOfArrSection1Name: "",
      addMoreOfArrSection1: {},
      open2orderArrOfAddMore: false,
      data2OrderArrOfAddMore: [],
      order2NameOfAddMore: "",
      openDeleteForArrSection: false,
      openHelpForArrSectionEdit: false,
      ArrSectionEditName: "",
      ArrSectionEditData: {},
      dataOfOrder2ArrayEdit: [],
      open2orderArrOfEdit: false,
      rteStateManagement: RichTextEditor.createValueFromString(
        "Hello Mr Hamzzaaa",
        "html"
      ),
      order2ArrEditName: "",
      openDeleteFor2orderOfArr: false,
      openHelpFor2OrderArrSectionEdit: false,
      ArrSectionFor2OrderEditName: "",
      ArrSection2OrderEditData: [],
      Layout: [],
      ObjectSectionData: {},
      LayoutEditData: [],
      objectsArrDataForEdit: {},
      mainObjectName: "",
      openedInnerObjectOfArrIndex: null,
      HelpForArrOfObjDataEdit: [],
      indexOfPartData: null,
      actualFieldToEdit: "",
      dataofInnerObjectsOfArrEditSec: [],
      indexessOfRows: null,
      indexOfRowForDelete: null,
      ParrentFieldName: "",
      mainObjectNameForDelete: "",
      pageNameForSaving: "",
      tableRowIndexForArr: null,
      mainObjNamesForArrDelete: "",
      array2OrderDeletefieldName: "",
      rowIndexOf2Order: null,
      addMoreDataForObject: {},
      parrentDataNameForAddMore: "",
      addMoreDataForArray: {},
      addMore2OrderDataForObject: {},
      arr2orderArraySavingData: [],
      isFileUploading: false,
      progressOfImgUploading: null,
      imageProgressModel: false,
      loader: false,
      objectsOf2orderAddMore: {},
      vald1: "",
      vald2: null,
      vald3: "",
      mainObjs: {},
      alertOpen: false,
      rteValues: RichTextEditor.createEmptyValue(),
      //pagination states
      prev: 0,
      next: 10,
      page: 1,

      // saved alert notify
      savingSuccessAlert: false,
    };

    this.data = {};
    this.setRTEsData = this.setRTEsData.bind(this);
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    let simpleRTEObjs: any = [];
    let firstOrderArrayRTE: any = [];
    let secondOrderArrayRTE: any = [];

    console.log(
      nextProps,
      "checking props and states in get Derived state from props",
      prevState
    );

    if (prevState.Layout && prevState.Layout.length > 0) {
      const filteringOutObjectsOfRTE =
        prevState &&
        prevState.SchemaData.length > 0 &&
        prevState.SchemaData.map((item: any) => {
          Object.keys(item).map((items: any, indexs: any) => {
            if (
              !Array.isArray(item[items]) &&
              typeof item[items] !== "string"
            ) {
              // console.log(item,'checking here all the truths',items)
              simpleRTEObjs.push(item[items]);
              console.log(item[items], "saidjwioru892u98hr");

              if (once) {
                Object.keys(item[items]).map((itemA: any) => {
                  console.log(itemA, "values of the meta of phorous");
                  if (item[items][itemA].controlType == "RTE") {
                    saveArrForRTE.push({
                      [item[items][itemA].ref]:
                        RichTextEditor.createValueFromString(
                          prevState.Layout[0][items][itemA],
                          "html"
                        ),
                    });

                    // return({

                    //  this.data[item[items][itemA].ref] = prevState && prevState.Layout.length>0 ? RichTextEditor.createValueFromString(prevState.Layout[0][items][itemA],'html') : RichTextEditor.createEmptyValue()
                    // })
                  }
                });
                once = false;
              }
            }
            // else if( Array.isArray(item[items]) && typeof(item[items]) !== "string" ) {
            //   item[items].map((itemX:any,indexX:any)=>{
            //     Object.keys(itemX).map((itemll:any,indexll:any)=>{
            //       if( !Array.isArray(itemX[itemll]) && typeof(itemX[itemll]) !== "string" ){

            //         if(itemX[itemll].controlType == 'RTE'){

            //           prevState.Layout[0][items].map((itemHG:any,indexHG:any)=>{

            //           firstOrderArrayRTE.push( {

            //               [itemll]:  RichTextEditor.createValueFromString(prevState.Layout[0][items][indexHG][itemll],'html')

            //           })

            //         })

            //         if(firstOrderArrayOnce){

            //           completeFirstOrderArrayList.push({
            //             [items] : [...firstOrderArrayRTE]
            //           })
            //           firstOrderArrayOnce = false

            //         }

            //         }
            //       }
            //       else if(Array.isArray(itemX[itemll]) && typeof(itemX[itemll]) !== "string"){
            //         itemX[itemll] && itemX[itemll].length>0 && itemX[itemll].map((itemJ:any,indexJ:any)=>{
            //           Object.keys(itemJ).map((itemQ:any)=>{
            //             if(!Array.isArray(itemJ[itemQ]) && typeof(itemJ[itemQ]) !== "string"){

            //               secondOrderArrayRTE.push(itemJ[itemQ])

            //             }
            //           })
            //         })
            //       }
            //     })

            //   })
            // }
          });
        });

      // const newArray = [...saveArrForRTE,...completeFirstOrderArrayList,...secondOrderArrayRTE];
      // const findOnlyRTEfields = newArray.filter((itemY:any)=> itemY.controlType == 'RTE')
      // findOnlyRTEfields && findOnlyRTEfields.length>0 && findOnlyRTEfields.map((items:any)=>{

      // })

      // console.log(saveArrForRTE,'checking data values for RTE',completeFirstOrderArrayList)

      saveArrForRTE &&
        saveArrForRTE.length > 0 &&
        saveArrForRTE.map((itemR: any) => {
          Object.keys(itemR).map((itemY) => {
            console.log(itemY, "sadkaj98qu9e8uqw");

            objsHamza[itemY] = itemR[itemY];
          });
        });

      console.log(objsHamza, "askjdhwqiey923u21ejkwd");

      return {
        rteDatas: objsHamza,
      };
    }
  }

  componentDidMount(): void {
    // post('https://api-news.nebrascorp.com/api/collection',{datas:"askldjaiewho"});
    console.log("sadjalskd");
    axios.get("https://api-news.nebrascorp.com/api/collection").then((res) => {
      console.log(res, "salkjdaoidwio983");

      this.setState(
        {
          pagesData: res.data.data,
        },
        () => console.log(this.state.rteDatas, "asjkhdiuwqhriuo342u849u2r")
      );
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    console.log(prevProps, "iaseuwiqoe2310921", prevState);
    console.log(this.props.router.location.state, "aksdhi23ye2389iksjad");
    if (
      this.props.router.location?.state?.data !==
      prevProps.router.location?.state?.data
    ) {
      if (
        this.props.router !== undefined &&
        this.props.router.location !== undefined &&
        this.props.router.location.state !== undefined &&
        this.props.router.location.state !== null &&
        this.props.router.location.state.data !== undefined &&
        this.props.router.location.state.data !== null
      ) {
        this.getSchema(
          this.props.router.location.state.data,
          this.props.router.location.state.pageName
        );
      }
    }
    prevState.Layout = prevState.Layout;
  }

  getSchema = (value: any, pageName: any) => {
    // ${{optionalQueryObject : value.target.value}}x
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;

    console.log(pageName, "asldjquiw");
    axios
      .get(`https://api-news.nebrascorp.com/api/schema`, {
        params: {
          collection: value,
          orgName: userChecks.companyColName,
        },
      })
      .then((res) => {
        console.log(res, "sadkjahsduiew");

        this.setState({
          SchemaData: [res.data.data],
          pageData: pageName,
          pageNameForSaving: value,
        });
      });

    axios
      .post(`https://api-news.nebrascorp.com/api/get-specific-update`, {
        data: value,
        orgName: userChecks.companyColName,
      })
      .then((res) => {
        console.log(res.data.data[0], "checking get specific update datasss");

        Object.keys(res.data.data[0]).map((items: any) => {
          if (Array.isArray(res.data.data[0][items])) {
          }
        });

        this.setState(
          {
            Layout: res.data.data,
            LayoutEditData: res.data.data,
          },
          () => {
            console.log(this.state.Layout, "ajskhdqiwu293u");
          }
        );
      });
  };

  onImageChange = (value: any, fieldName: any) => {
    var datas = new FormData();
    datas.append(`${fieldName}`, value.target.files[0]);
    axios({
      method: "post",
      url: "https://api-news.nebrascorp.com/api/image-url-path",
      data: datas,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        Object.keys(res.data).map((item) => {
          //stateChanges Here..........
        });
      })
      .catch((err) => console.log(err, "errpr"));
  };

  handleInputChange = (
    e: any,
    InnerArrFirstName: any,
    val1: any,
    val2: any,
    val3: any,
    isFile: any,
    objectFields: any,
    objectArrs: any,
    arrOfObj: any,
    innerArrOfArrOfObject: any,
    arrFieldName: any,
    arrFieldsObjIndex: any,
    isBoolean: any
  ) => {
    const { Layout } = this.state;
    var data: any = [];
    var currIndex: any = [];
    console.log(Layout, "sakdljasidoqwu9wewqe");
    const splitStateData = Object.keys(this.state.LayoutEditData[0]).map(
      (item: any) =>
        data.push({ [`${item}`]: this.state.LayoutEditData[0][item] })
    );
    const findIndex = Object.keys(this.state.Layout[0]).filter(
      (item: any, index: any) =>
        item === (innerArrOfArrOfObject == true ? InnerArrFirstName : val1)
          ? currIndex.push(index)
          : undefined
    );
    const stateDataCopy = data.slice();
    console.log(stateDataCopy, "ioqweuqwoieu982391823", currIndex);
    const objectCopy = Object.assign({}, stateDataCopy[currIndex[0]]);

    console.log(
      objectCopy,
      "checking datas for array sortings",
      val1,
      "value1",
      val2,
      "value2",
      val3,
      "value3"
    );

    if (objectFields) {
      if (isFile && isFile !== undefined && isFile == "file") {
        var datas = new FormData();
        datas.append(`${val2}`, e.target.files[0]);
        this.setState({
          imageProgressModel: true,
        });
        axios({
          method: "post",
          url: "https://api-news.nebrascorp.com/api/image-url-path",
          data: datas,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (data: any) => {
            //Set the progress value to show the progress bar
            this.setState(
              {
                progressOfImgUploading: Math.round(
                  (100 * data.loaded) / data.total
                ),
              },
              () =>
                console.log(
                  this.state.progressOfImgUploading,
                  "asudqwiuey1298312"
                )
            );
          },
        })
          .then((res) => {
            if (res && res.data) {
              console.log(res.data, "iu23129uiojdnsakdq");

              this.setState(
                {
                  isFileUploading: false,
                },
                () => {
                  Object.keys(res.data).map((item) => {
                    //stateChanges Here..........
                    objectCopy[`${val1}`][`${val2}`] = res.data[item];
                  });
                }
              );
            }
          })
          .catch((err) => console.log(err, "errpr"));
      } else {
        if (isBoolean) {
          if (e.target.value === "true") {
            objectCopy[`${val1}`][`${val2}`] = true;
          } else {
            objectCopy[`${val1}`][`${val2}`] = false;
          }
        } else {
  
          objectCopy[`${val1}`][`${val2}`]  = e.target.value;
         }
      }
    } else if (objectArrs) {
      if (isFile && isFile !== undefined && isFile == "file") {
        var datas = new FormData();
        datas.append(`${val2}`, e.target.files[0]);
        this.setState({
          imageProgressModel: true,
        });
        axios({
          method: "post",
          url: "https://api-news.nebrascorp.com/api/image-url-path",
          data: datas,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (data: any) => {
            //Set the progress value to show the progress bar
            this.setState(
              {
                progressOfImgUploading: Math.round(
                  (100 * data.loaded) / data.total
                ),
              },
              () =>
                console.log(
                  this.state.progressOfImgUploading,
                  "asudqwiuey1298312"
                )
            );
          },
        })
          .then((res) => {
            if (res && res.data) {
              console.log(res.data, "iu23129uiojdnsakdq");

              this.setState(
                {
                  isFileUploading: false,
                },
                () => {
                  Object.keys(res.data).map((item) => {
                    //stateChanges Here..........
                    objectCopy[`${val1}`][`${val2}`][arrFieldsObjIndex][
                      arrFieldName
                    ] = res.data[item];
                  });
                }
              );
            }
          })
          .catch((err) => console.log(err, "errpr"));
      } else {
        console.log(objectCopy, "asiu2834u28949230rqw");

        if (isBoolean) {
          if (e.target.value === "true") {
            objectCopy[`${val1}`][`${val2}`][arrFieldsObjIndex][arrFieldName] =
              true;
          } else {
            objectCopy[`${val1}`][`${val2}`][arrFieldsObjIndex][arrFieldName] =
              false;
          }
        } else {
          objectCopy[`${val1}`][`${val2}`][arrFieldsObjIndex][arrFieldName] =
            e.target.value;
        }

        // console.log(val1,val2,'askjdhaiqwueqw',arrFieldName,arrFieldsObjIndex,'2387498247ew98ru',objectCopy)
      }
    } else if (arrOfObj) {
      if (isFile && isFile !== undefined && isFile == "file") {
        console.log(val1, val2, val3, "asiduoqwieu9238041239");

        var datas = new FormData();
        datas.append(`${val2}`, e.target.files[0]);
        this.setState({
          imageProgressModel: true,
        });
        axios({
          method: "post",
          url: "https://api-news.nebrascorp.com/api/image-url-path",
          data: datas,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (data: any) => {
            //Set the progress value to show the progress bar
            this.setState(
              {
                progressOfImgUploading: Math.round(
                  (100 * data.loaded) / data.total
                ),
              },
              () =>
                console.log(
                  this.state.progressOfImgUploading,
                  "asudqwiuey1298312"
                )
            );
          },
        })
          .then((res) => {
            if (res && res.data) {
              this.setState(
                {
                  isFileUploading: false,
                },
                () => {
                  Object.keys(res.data).map((item) => {
                    //stateChanges Here..........
                    objectCopy[`${val1}`][`${val2}`][`${val3}`] =
                      res.data[item];
                  });
                }
              );
            }
          })
          .catch((err) => console.log(err, "errpr"));
      } else {
        console.log(objectCopy, "asiu2834u28949230rqw", val1, val2, val3);

        // objectCopy && objectCopy.length>0 && objectCopy.map((items:any)=> )

        if (isBoolean) {
          if (e.target.value === "true") {
            objectCopy[`${val1}`][`${val2}`][`${val3}`] = true;
          } else {
            objectCopy[`${val1}`][`${val2}`][`${val3}`] = false;
          }
        } else {
          objectCopy[`${val1}`][`${val2}`][`${val3}`] = e.target.value;
        }

        // console.log(val1,val2,'askjdhaiqwueqw',arrFieldName,arrFieldsObjIndex,'2387498247ew98ru',objectCopy)
      }
    } else if (innerArrOfArrOfObject) {
      if (isFile && isFile !== undefined && isFile == "file") {
        var datas = new FormData();
        datas.append(`${val2}`, e.target.files[0]);
        this.setState({
          imageProgressModel: true,
        });
        axios({
          method: "post",
          url: "https://api-news.nebrascorp.com/api/image-url-path",
          data: datas,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (data: any) => {
            //Set the progress value to show the progress bar
            this.setState(
              {
                progressOfImgUploading: Math.round(
                  (100 * data.loaded) / data.total
                ),
              },
              () =>
                console.log(
                  this.state.progressOfImgUploading,
                  "asudqwiuey1298312"
                )
            );
          },
        })
          .then((res) => {
            if (res && res.data) {
              console.log(res.data, "iu23129uiojdnsakdq");

              this.setState(
                {
                  isFileUploading: false,
                },
                () => {
                  Object.keys(res.data).map((item) => {
                    //stateChanges Here..........
                    objectCopy[`${InnerArrFirstName}`][
                      this.state.indexOfPartData
                    ][`${val1}`][`${val2}`][`${val3}`] = res.data[item];
                  });
                }
              );
            }
          })
          .catch((err) => console.log(err, "errpr"));

        // var datas = new FormData()
        // datas.append(`${val2}`, e.target.files[0])
        // axios({
        //   method: 'post',
        //   url: 'https://api-news.nebrascorp.com/api/image-url-path',
        //   data: datas,
        //   headers: { 'Content-Type': 'multipart/form-data' }
        // })
        //   .then(res => {
        //     Object.keys(res.data).map(item => {

        //       //stateChanges Here..........
        //       objectCopy[`${InnerArrFirstName}`][0][this.state.indexOfPartData][`${val1}`][`${val2}`][`${val3}`]  = res.data[item];

        //     })
        //   })
        //   .catch(err => console.log(err, 'errpr'))

        // objectCopy[`${val1}`][`${val2}`][arrFieldsObjIndex][arrFieldName] = e.target.value;
      } else {
        console.log(objectCopy, "asiu2834u28949230rqw");

        if (isBoolean) {
          if (e.target.value === "true") {
            objectCopy[`${InnerArrFirstName}`][this.state.indexOfPartData][
              `${val1}`
            ][`${val2}`][`${val3}`] = true;
          } else {
            objectCopy[`${InnerArrFirstName}`][this.state.indexOfPartData][
              `${val1}`
            ][`${val2}`][`${val3}`] = false;
          }
        } else {
          objectCopy[`${InnerArrFirstName}`][this.state.indexOfPartData][
            `${val1}`
          ][`${val2}`][`${val3}`] = e.target.value;
        }
      }
    }

    console.log(Layout, "asioduqwoeu90qu3902112");

    this.setState({
      Layout: this.state.Layout,
    });
  };

  savingEditFormData = () => {
    const { Layout } = this.state;
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;

    this.setState({ savingSuccessAlert: true });
    setTimeout(() => {
      this.setState({ savingSuccessAlert: false });
    }, 2000);

    // this.setState({
    //   loader:true,
    // })

    console.log(Layout, "asiuwqeiywqiey289312");
    axios
      .post("https://api-news.nebrascorp.com/api/layout", {
        obj: Layout[0],
        title: this.state.pageNameForSaving.replaceAll(" ", ""),
        orgName: userChecks.companyColName,
      })
      .then((res) => {
        if (res) {
          //changes for saving alert
          this.setState({ loader: true });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleDeleteOfAll = (
    rowIndex: any,
    fieldName: any,
    parrentArrayName: any,
    objInArr: any,
    arrOfObj: any,
    innerArrOfObj: any
  ) => {
    const { Layout, pageData } = this.state;
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;

    if (objInArr) {
      this.setState(
        {
          openDeleteForObjSectionArrs: false,
          loader: true,
        },
        () => {
          Layout[0][parrentArrayName][fieldName].splice(rowIndex, 1);

          axios
            .post("https://api-news.nebrascorp.com/api/layout", {
              obj: Layout[0],
              title: this.state.pageNameForSaving.replaceAll(" ", ""),
              orgName: userChecks.companyColName,
            })
            .then((res) => {
              if (res) {
                window.location.reload();
              }
            })
            .catch((e) => {
              console.log(e, "asduwqiue89231");
            });
        }
      );
    } else if (arrOfObj) {
      const datas: any = [];

      this.setState(
        {
          openDeleteForArrSection: false,
          loader: true,
        },
        () => {
          Layout[0][fieldName].splice(rowIndex, 1);

          axios
            .post("https://api-news.nebrascorp.com/api/layout", {
              obj: Layout[0],
              title: this.state.pageNameForSaving.replaceAll(" ", ""),
              orgName: userChecks.companyColName,
            })
            .then((res) => {
              if (res) {
                window.location.reload();
              }
            })
            .catch((e) => {
              console.log(e, "asduwqiue89231");
            });
        }
      );
    } else if (innerArrOfObj) {
      this.setState(
        {
          openDeleteFor2orderOfArr: false,
          loader: true,
        },
        () => {
          const datal: any = [];
          Layout[0][parrentArrayName][this.state.indexOfPartData][
            fieldName
          ].splice(rowIndex, 1);
          // Object.keys(Layout[0][parrentArrayName][0][this.state.indexOfPartData][fieldName]).map
          console.log(Layout, "aslkdjaioeuqweiu1231");

          axios
            .post("https://api-news.nebrascorp.com/api/layout", {
              obj: Layout[0],
              title: this.state.pageNameForSaving.replaceAll(" ", ""),
              orgName: userChecks.companyColName,
            })
            .then((res) => {
              if (res) {
                window.location.reload();
              }
            })
            .catch((e) => {
              console.log(e, "asduwqiue89231");
            });
        }
      );
    }
  };

  addMoreOnChange = (e: any, fieldName: any, isFile: any) => {
    console.log(e, fieldName, isFile, "askdasncsai382u4982u3012rekjd");

    if (isFile && isFile !== undefined && isFile == "file") {
      var datas = new FormData();
      datas.append(`${fieldName}`, e.target.files[0]);
      this.setState({
        imageProgressModel: true,
      });
      axios({
        method: "post",
        url: "https://api-news.nebrascorp.com/api/image-url-path",
        data: datas,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (data: any) => {
          //Set the progress value to show the progress bar
          this.setState(
            {
              progressOfImgUploading: Math.round(
                (100 * data.loaded) / data.total
              ),
            },
            () =>
              console.log(
                this.state.progressOfImgUploading,
                "asudqwiuey1298312"
              )
          );
        },
      })
        .then((res) => {
          if (res && res.data) {
            console.log(res.data, "iu23129uiojdnsakdq");

            this.setState(
              {
                isFileUploading: false,
              },
              () => {
                Object.keys(res.data).map((item) => {
                  //stateChanges Here..........
                  objsAddmore[fieldName] = res.data[item];
                });
              }
            );
          }
        })
        .catch((err) => console.log(err, "errpr"));

      // var datas = new FormData()
      // datas.append(`${fieldName}`, e.target.files[0])
      // axios({
      //   method: 'post',
      //   url: 'https://api-news.nebrascorp.com/api/image-url-path',
      //   data: datas,
      //   headers: { 'Content-Type': 'multipart/form-data' }
      // })
      //   .then(res => {
      //     Object.keys(res.data).map(item => {

      //       //stateChanges Here..........
      //       objsAddmore[fieldName]  = res.data[item];

      //     })
      //   })
      //   .catch(err => console.log(err, 'errpr'))
    } else {
      objsAddmore[fieldName] = e.target.value;
    }

    this.setState(
      {
        addMoreDataForObject: { ...objsAddmore },
      },
      () => console.log(this.state.addMoreDataForObject, "iusadqwioeuoqiweuo")
    );
    // console.log(objs,'asdkjqwuiey1298312')
  };

  savingAddmoreData = (arrName: any, parrentArrName: any) => {
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;

    objsAddmore = {};
    const { addMoreDataForObject, Layout } = this.state;

    this.setState({ savingSuccessAlert: true });
    setTimeout(() => {
      this.setState({ savingSuccessAlert: false });
    }, 2000);

    // this.setState({
    //   loader:true
    // })

    Layout[0][parrentArrName][arrName].unshift(addMoreDataForObject);

    console.log(Layout, "asudywqeu89213219jwksdha");

    axios
      .post("https://api-news.nebrascorp.com/api/layout", {
        obj: Layout[0],
        title: this.state.pageNameForSaving.replaceAll(" ", ""),
        orgName: userChecks.companyColName,
      })
      .then((res) => {
        //changes for saving alert
        this.setState({ loader: true });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((e) => {
        console.log(e, "asduwqiue89231");
      });
  };

  addMoreOnChangeOfArr = (e: any, fieldName: any, isFile: any) => {
    console.log(e, "checking for id", fieldName, isFile);
    const { Layout } = this.state;

    if (isFile && isFile === "file") {
      console.log("INSIDE FUNCSSSSSSSSSSSS IMG");
      var datas = new FormData();
      datas.append(`${fieldName}`, e.target.files[0]);
      this.setState(
        {
          imageProgressModel: true,
        },
        () => {
          axios({
            method: "post",
            url: "https://api-news.nebrascorp.com/api/image-url-path",
            data: datas,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (data: any) => {
              //Set the progress value to show the progress bar
              this.setState(
                {
                  progressOfImgUploading: Math.round(
                    (100 * data.loaded) / data.total
                  ),
                },
                () =>
                  console.log(
                    this.state.progressOfImgUploading,
                    "asudqwiuey1298312"
                  )
              );
            },
          })
            .then((res) => {
              console.log(res, "adsfjdsif9eur32scnsjcndj84");
              if (res.data) {
                console.log(res.data, "iu23129uiojdnsakdq");

                this.setState(
                  {
                    isFileUploading: false,
                  },
                  () => {
                    arrAddMore[`${fieldName}`] = res.data[fieldName];
                  }
                );

                //   Object.keys(res.data).map(item => {

                //     //stateChanges Here..........
                //     arrAddMore[fieldName]  = res.data[item];

                //   })
                // })
              }
            })
            .catch((err) => console.log(err, "errpr"));
        }
      );

      console.log(arrAddMore, "asdkjhqeiqw982342434");
    } else {
      if (fieldName == "id") {
        const datas: any = [];
        Layout[0][this.state.addMoreOfArrSection1Name].map((item: any) => {
          datas.push({ ...item });
        });

        console.log(datas, "asiuqy98r4234u9823");

        const largest = Math.max(...datas.map((o: any) => o.id));

        // const largest = datas.sort((a:any,b:any)=> parseInt(b.id) - parseInt(a.id) ).reverse()[0];

        arrAddMore["id"] = (largest + 1).toString();

        console.log(largest, "haskdhwoiqwe0932849234");
        // arrAddMore['id'] =  parseInt(largest[0].id) + 1 ;
      } else {
        arrAddMore[fieldName] = e.target.value;
      }
    }

    this.setState(
      {
        addMoreDataForArray: { ...arrAddMore },
      },
      () => console.log(this.state.addMoreDataForArray, "iusadqwioeuoqiweuo")
    );
    // console.log(objs,'asdkjqwuiey1298312')
  };

  addMoreSavingArray = (arrName: any) => {
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;

    if (arrAddMore && arrAddMore.id !== undefined && arrAddMore.id !== null) {
      arrAddMore = {};
      const arrs: any = [];

      const {
        addMoreDataForArray,
        Layout,
        order2NameOfAddMore,
        addMore2OrderDataForObject,
        arr2orderArraySavingData,
      } = this.state;

      console.log(arr2orderArraySavingData, "asdiju98w294u2398reishdjshgf87");

      this.setState({ savingSuccessAlert: true });
      setTimeout(() => {
        this.setState({ savingSuccessAlert: false });
      }, 2000);

      // this.setState({
      //   loader:true
      // })

      const dataOFObjs = addMoreDataForArray;

      if (arr2orderArraySavingData && arr2orderArraySavingData.length > 0) {
        dataOFObjs[`${order2NameOfAddMore}`] = arr2orderArraySavingData;

        this.setState(
          {
            addMoreDataForArray: dataOFObjs,
          },
          () =>
            console.log(
              this.state.addMoreDataForArray,
              "asidouwqieuqw98e798213"
            )
        );
      }

      // console.log(this.state.addMoreDataForArray,'checking asiuy98721312')

      Layout[0][arrName].unshift(this.state.addMoreDataForArray);

      console.log(Layout, "in side add more function");

      //  axios.post('https://api-news.nebrascorp.com/api/layout', {
      //   obj: Layout[0],
      //   title: this.state.pageNameForSaving.replaceAll(' ', ''),
      //   orgName: userChecks.companyColName
      // }).then((res)=>{

      //       //changes for saving alert
      //       this.setState({loader: true})
      //       setTimeout(() => { window.location.reload(); }, 3000);

      // }).catch((e)=>{
      //   console.log(e,'asduwqiue89231')
      // })

      // console.log('Inside Saving Method')
    } else {
      this.setState({
        alertOpen: true,
      });
    }
  };

  changeAddMoreValuefor2order = (e: any, fieldName: any, isFile: any) => {
    if (isFile && isFile !== undefined && isFile == "file") {
      console.log(e, fieldName, isFile, "askdasncsai382u4982u3012rekjd");

      var datas = new FormData();
      datas.append(`${fieldName}`, e.target.files[0]);
      this.setState({
        imageProgressModel: true,
      });
      axios({
        method: "post",
        url: "https://api-news.nebrascorp.com/api/image-url-path",
        data: datas,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (data: any) => {
          //Set the progress value to show the progress bar
          this.setState(
            {
              progressOfImgUploading: Math.round(
                (100 * data.loaded) / data.total
              ),
            },
            () =>
              console.log(
                this.state.progressOfImgUploading,
                "asudqwiuey1298312"
              )
          );
        },
      })
        .then((res) => {
          if (res.data) {
            console.log(res.data, "i am inside of image data response");

            this.setState(
              {
                isFileUploading: false,
              },
              () => {
                Object.keys(res.data).map((item) => {
                  //stateChanges Here..........
                  objs2orderAddmore[item] = res.data[item];
                });
              }
            );
          }
        })
        .catch((err) => console.log(err, "errpr"));

      addmore2orderconverObjsintoArrs.push({
        ...objs2orderAddmore,
      });

      console.log(objs2orderAddmore, "checking add more data");

      // var datas = new FormData()
      // datas.append(`${fieldName}`, e.target.files[0])
      // axios({
      //   method: 'post',
      //   url: 'https://api-news.nebrascorp.com/api/image-url-path',
      //   data: datas,
      //   headers: { 'Content-Type': 'multipart/form-data' }
      // })
      //   .then(res => {
      //     Object.keys(res.data).map(item => {

      //       //stateChanges Here..........
      //       objs2orderAddmore[fieldName]  = res.data[item];

      //     })
      //   })
      //   .catch(err => console.log(err, 'errpr'))
    } else {
      objs2orderAddmore[fieldName] = e.target.value;

      addmore2orderconverObjsintoArrs.push({
        ...objs2orderAddmore,
      });
    }

    // console.log(addmore2orderconverObjsintoArrs.slice(-1),'asdkjhasdjncheiry89239324')

    var conversions = addmore2orderconverObjsintoArrs.slice(-1);

    this.setState(
      {
        addMore2OrderDataForObject: { ...conversions[0] },
      },
      () =>
        console.log(this.state.addMore2OrderDataForObject, "iusadqwioeuoqiweuo")
    );
  };

  savingDataForArray = () => {
    const { addMore2OrderDataForObject } = this.state;
    const datas = addMore2OrderDataForObject;

    save2orderDatas.push(datas);

    console.log(addMore2OrderDataForObject, "iquwewoqieuqwoieuqw");
    this.setState(
      {
        arr2orderArraySavingData: save2orderDatas,
      },
      () =>
        console.log(this.state.arr2orderArraySavingData, "saiud8eiuywq89e312")
    );
  };

  savingAddMoreForEditGrids = (
    e: any,
    keys: any,
    val1: any,
    val2: any,
    firstOrderArrIndex: any,
    isFile: any
  ) => {
    const { Layout } = this.state;
    var data: any = [];
    var currIndex: any = [];
    console.log(val1, "sakdljasidoqwu9wewqe");
    const splitStateData = Object.keys(this.state.LayoutEditData[0]).map(
      (item: any) =>
        data.push({ [`${item}`]: this.state.LayoutEditData[0][item] })
    );
    const findIndex = Object.keys(this.state.Layout[0]).filter(
      (item: any, index: any) =>
        item === val1 ? currIndex.push(index) : undefined
    );
    const stateDataCopy = data.slice();
    console.log(stateDataCopy, "ioqweuqwoieu982391823", currIndex);
    const objectCopy = Object.assign({}, stateDataCopy[currIndex[0]]);

    console.log(objectCopy, "asdkuwqieuoqiw");

    if (isFile && isFile == "file") {
      var datas = new FormData();
      datas.append(`${keys}`, e.target.files[0]);
      this.setState({
        imageProgressModel: true,
      });
      axios({
        method: "post",
        url: "https://api-news.nebrascorp.com/api/image-url-path",
        data: datas,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (data: any) => {
          //Set the progress value to show the progress bar
          this.setState(
            {
              progressOfImgUploading: Math.round(
                (100 * data.loaded) / data.total
              ),
            },
            () =>
              console.log(
                this.state.progressOfImgUploading,
                "asudqwiuey1298312"
              )
          );
        },
      })
        .then((res) => {
          if (res && res.data) {
            console.log(res.data, "iu23129uiojdnsakdq");

            this.setState(
              {
                isFileUploading: false,
              },
              () => {
                Object.keys(res.data).map((item) => {
                  //stateChanges Here..........
                  addMoreGridsData[keys] = res.data[item];
                });
              }
            );
          }
        })
        .catch((err) => console.log(err, "errpr"));
    } else {
    }

    addMoreGridsData[keys] = e.target.value;

    this.setState({
      objectsOf2orderAddMore: addMoreGridsData,
      vald1: val1,
      vald2: firstOrderArrIndex,
      vald3: val2,
      mainObjs: objectCopy,
    });

    // console.log(firstOrderArrIndex,'iasud9qw8iueq0',val2)

    // objectCopy[val1][0][firstOrderArrIndex][val2].push(addMoreGridsData);

    // console.log('8498234uiwhdkashjd',addMoreGridsData)
  };

  saveAddMoreForEditInArr = () => {
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;

    const { objectsOf2orderAddMore, vald1, vald2, vald3, mainObjs, Layout } =
      this.state;
    console.log(mainObjs, "askdhiwyeq83y29131", vald1, vald2, vald3);
    mainObjs[vald1][vald2][vald3]?.push(objectsOf2orderAddMore);

    // console.log(Layout,'jkashdkjwqheoiqeu923871293',mainObjs)

    // const {Layout} = this.state;

    this.setState({ savingSuccessAlert: true });
    setTimeout(() => {
      this.setState({ savingSuccessAlert: false });
    }, 2000);

    // this.setState({
    //   loader:true
    // })

    console.log(Layout, "asiuwqeiywqiey289312");
    axios
      .post("https://api-news.nebrascorp.com/api/layout", {
        obj: Layout[0],
        title: this.state.pageNameForSaving.replaceAll(" ", ""),
        orgName: userChecks.companyColName,
      })
      .then((res) => {
        if (res) {
          //changes for saving alert
          this.setState({ loader: true });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  setRTEsData = (name: any, value: any, objName: any) => {
    var layoutEditDatas = Object.keys(this.state.Layout[0])
      .map((items: any) => items === objName && this.state.Layout[0][items])
      .filter(Boolean);
    this.setState(
      {
        [name]: value,
      },
      () => {
        // if( layoutEditDatas !== null){
        layoutEditDatas[0][name] = value.toString("html");
        //   layoutEditDatas
        // }
        // this.setState({
        //    :
        // })
      }
    );

    console.log(this.state.Layout, "askudhq8wry923u490qruou", layoutEditDatas);

    // var ddf = this.state.rteDatas;
    // let aarrs = [];

    // aarrs.push(ddf);
    // aarrs[0][name] = value;

    // console.log(aarrs[0],'askjdashd98u84912e')

    // this.setState({

    //   rteDatas : aarrs[0]

    // })
    // console.log(value,'askjdhair32u894fhjkahdfs',this.state.rteDatas)
  };
  render() {
    console.log(objsHamza, "checking the RTE before once logic");
    if (stateOnce && this.state.rteDatas !== null) {
      console.log(objsHamza, "checking the RTE after once logic");

      Object.keys(this.state.rteDatas).map((items: any) => {
        // this.state[items] = objsHamza[items]
        this.setState(
          {
            [items]: this.state.rteDatas[items],
          },
          () => console.log(this.state, "checkinidsfh8932473249823djfs")
        );
      });

      //   this.setState({
      //     rteDatas:objsHamza
      //   },()=> console.log(this.state.rteDatas,'askjduqw8eu982392349012'))

      stateOnce = false;
    }

    console.log(this.state, "askdhqw89ye8923y12iheoi");

    const {
      pageData,
      SchemaData,
      openHelpArrayObj1Data,
      addMoreOfObjSection1,
      openDeleteForObjSectionArrs,
      imageProgressModel,
      addMoreOfArrSection1,
      data2OrderArrOfAddMore,
      openDeleteForArrSection,
      ArrSectionEditData,
      dataOfOrder2ArrayEdit,
      openDeleteFor2orderOfArr,
      ArrSection2OrderEditData,
      Layout,
      LayoutEditData,
      HelpForArrOfObjDataEdit,
    } = this.state;

    // console.log('asdkjhwa8duh33892473289472398498rfq', this.state.rteDatas)

    return (
      <React.Fragment>
        {this.state.loader ? (
          <ScreenLoader />
        ) : (
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs title="Pages" breadcrumbItem={pageData} />
              <br />

              <Container fluid className="dynamic-rend-comp">
                <Row>
                  {pageData && pageData !== "" && Layout.length > 0 ? (
                    <Card>
                      <CardBody>
                        {SchemaData &&
                          SchemaData.length > 0 &&
                          Object.keys(SchemaData[0]).map(
                            (item: any, index3: any) => {
                              if (
                                typeof SchemaData[0][item] == "object" &&
                                !Array.isArray(SchemaData[0][item])
                              ) {
                                console.log(
                                  item,
                                  "checking objects data",
                                  SchemaData[0][item]
                                );
                                var dataOfObjects = SchemaData[0][item];

                                return (
                                  <>
                                    <Row>
                                      <Card>
                                        <CardBody>
                                          {Object.keys(dataOfObjects).map(
                                            (item1: any, index1: any) => {
                                              if (
                                                typeof dataOfObjects[item1] ==
                                                  "object" &&
                                                !Array.isArray(
                                                  dataOfObjects[item1]
                                                )
                                              ) {
                                                if (
                                                  dataOfObjects[item1]
                                                    .controlType == "text"
                                                ) {
                                                  return (
                                                    <div key={index1}>
                                                      <FormGroup row>
                                                        <Label
                                                          for="examplePassword"
                                                          sm={2}
                                                          style={{
                                                            textAlign: "right",
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {
                                                            dataOfObjects[item1]
                                                              .label
                                                          }
                                                        </Label>

                                                        <Col sm={4}>
                                                          <Input
                                                            id={item1}
                                                            name={item1}
                                                            type="text"
                                                            value={
                                                              Layout &&
                                                              Layout.length >
                                                                0 &&
                                                              !Array.isArray(
                                                                Layout[0][item]
                                                              ) &&
                                                              Layout[0][
                                                                item
                                                              ] !== undefined &&
                                                              Layout[0][item][
                                                                item1
                                                              ] !== undefined &&
                                                              Layout[0][item][
                                                                item1
                                                              ]
                                                            }
                                                            onChange={(e) =>
                                                              this.handleInputChange(
                                                                e,
                                                                "",
                                                                item,
                                                                item1,
                                                                "",
                                                                "",
                                                                true,
                                                                false,
                                                                false,
                                                                false,
                                                                null,
                                                                null,
                                                                null
                                                              )
                                                            }
                                                          ></Input>
                                                        </Col>
                                                      </FormGroup>
                                                    </div>
                                                  );
                                                } else if (
                                                  dataOfObjects[item1]
                                                    .controlType == "Boolean"
                                                ) {
                                                  return (
                                                    <div key={index1}>
                                                      <FormGroup row>
                                                        <Label
                                                          for="examplePassword"
                                                          sm={2}
                                                          style={{
                                                            textAlign: "right",
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {
                                                            dataOfObjects[item1]
                                                              .label
                                                          }
                                                        </Label>

                                                        <Col sm={4}>
                                                          <select
                                                            style={{
                                                              width: "100%",
                                                              height: "100%",
                                                              border:
                                                                "1px solid #E9E9EF",
                                                              borderRadius:
                                                                "2px",
                                                            }}
                                                            id={item1}
                                                            name={item1}
                                                            value={
                                                              Layout &&
                                                              Layout.length >
                                                                0 &&
                                                              !Array.isArray(
                                                                Layout[0][item]
                                                              ) &&
                                                              Layout[0][
                                                                item
                                                              ] !== undefined &&
                                                              Layout[0][item][
                                                                item1
                                                              ] !== undefined &&
                                                              Layout[0][item][
                                                                item1
                                                              ]
                                                            }
                                                            onChange={(e) =>
                                                              this.handleInputChange(
                                                                e,
                                                                "",
                                                                item,
                                                                item1,
                                                                "",
                                                                "",
                                                                true,
                                                                false,
                                                                false,
                                                                false,
                                                                null,
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          >
                                                            <option
                                                              value="true"
                                                              selected
                                                            >
                                                              true
                                                            </option>
                                                            <option value="false">
                                                              false
                                                            </option>
                                                          </select>
                                                        </Col>
                                                      </FormGroup>
                                                    </div>
                                                  );
                                                } else if (
                                                  dataOfObjects[item1]
                                                    .controlType == "image"
                                                ) {
                                                  return (
                                                    <div key={index1}>
                                                      <FormGroup row>
                                                        <Label
                                                          for="examplePassword"
                                                          sm={2}
                                                          style={{
                                                            textAlign: "right",
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {
                                                            dataOfObjects[item1]
                                                              .label
                                                          }
                                                        </Label>

                                                        <Col sm={4}>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            {Layout &&
                                                              Layout.length >
                                                                0 &&
                                                              !Array.isArray(
                                                                Layout[0][item]
                                                              ) &&
                                                              Layout[0] &&
                                                              Layout[0][item] &&
                                                              Layout[0][item][
                                                                item1
                                                              ] && (
                                                                <img
                                                                  src={`https://api-news.nebrascorp.com/pivot-media/${Layout[0][item][item1]}`}
                                                                  height="50"
                                                                  width="50"
                                                                />
                                                              )}

                                                            <Input
                                                              id="exampleSelect"
                                                              name="textField"
                                                              type="file"
                                                              // style={{marginLeft:10}}
                                                              onChange={(e) => {
                                                                this.setState(
                                                                  {
                                                                    isFileUploading:
                                                                      true,
                                                                  },
                                                                  () => {
                                                                    this.handleInputChange(
                                                                      e,
                                                                      "",
                                                                      item,
                                                                      item1,
                                                                      "",
                                                                      "file",
                                                                      true,
                                                                      false,
                                                                      false,
                                                                      false,
                                                                      null,
                                                                      null,
                                                                      null
                                                                    );
                                                                  }
                                                                );
                                                              }}
                                                            ></Input>
                                                          </div>
                                                          <p
                                                            style={{
                                                              color: "#74788D",
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            {
                                                              dataOfObjects[
                                                                item1
                                                              ].inputValidation
                                                            }{" "}
                                                          </p>
                                                        </Col>
                                                      </FormGroup>
                                                    </div>
                                                  );
                                                } else if (
                                                  dataOfObjects[item1]
                                                    .controlType == "RTE"
                                                ) {
                                                  // this.data[dataOfObjects[item1].label] = RichTextEditor.createEmptyValue();
                                                  // this.data[dataOfObjects[item1].label] = RichTextEditor.createValueFromString(Layout && Layout.length>0 && !Array.isArray(Layout[0][item]) && Layout[0][item]!==undefined && Layout[0][item][item1]!==undefined && Layout[0][item][item1],'html') ;
                                                  // console.log(RichTextEditor.createValueFromString("ERP software standardizes and streamlines all business processes including finance, human resources, quality,procurement, manufacturing, inventory, sales and reporting. With a shared common database, real-time data visibility provides a single source of information about your business’s performance available at your fingertips—for all company stakeholders—day or night in one software system.Departments across your business all have their own ways of storing important data like accounting documents, batch notes or sales orders. Maybe some teams are still working manually in paper or spreadsheets while others have basic systems. This siloed and disjointed approach means staff may be wasting time searching for missing data, duplicating work or analyzing why figures are different across departments—all of which hinders efficiency and collaboration. An ERP system helps change all of that.",'html'),"hello hamza from RTE comps",index1)
                                                  // console.log(this.state[dataOfObjects[item1].ref],'askjdhqwieqw98euq89wnnb')

                                                  return (
                                                    <div key={index1}>
                                                      <FormGroup row>
                                                        <Label
                                                          for="examplePassword"
                                                          sm={2}
                                                          style={{
                                                            textAlign: "right",
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {
                                                            dataOfObjects[item1]
                                                              .label
                                                          }
                                                        </Label>

                                                        <Col sm={6}>
                                                          <Input
                                                                    id='exampleSelect'
                                                                    name='textField'
                                                                    type='textarea'
                                                                    rows="6"
                                                                    value={ Layout && Layout.length>0 && !Array.isArray(Layout[0][item]) && Layout[0][item]!==undefined && Layout[0][item][item1]!==undefined && Layout[0][item][item1] }
                                                                    onChange={(e)=> this.handleInputChange(e,'',item,item1,'','',true,false,false,false,null,null,null)}
                                                                  >
                                                                   
                                                                  </Input>

                                                          {/* {this.state[
                                                            dataOfObjects[item1]
                                                              .ref
                                                          ] && (
                                                            <RichTextEditor
                                                              //  value={}
                                                              value={
                                                                this.state[
                                                                  dataOfObjects[
                                                                    item1
                                                                  ].ref
                                                                ]
                                                              }
                                                              onChange={(
                                                                value
                                                              ) =>
                                                                this.setRTEsData(
                                                                  dataOfObjects[
                                                                    item1
                                                                  ].ref,
                                                                  value,
                                                                  item
                                                                )
                                                              }
                                                            />
                                                          )} */}
                                                        </Col>
                                                      </FormGroup>
                                                    </div>
                                                  );
                                                } else {
                                                  <></>;
                                                }
                                              } else if (
                                                typeof dataOfObjects[item1] ==
                                                  "object" &&
                                                Array.isArray(
                                                  dataOfObjects[item1]
                                                )
                                              ) {
                                                console.log(
                                                  item1,
                                                  "asdjasiodwqeiqwe",
                                                  dataOfObjects,
                                                  item
                                                );

                                                return (
                                                  <div
                                                    key={index1}
                                                    style={{
                                                      marginTop: 10,
                                                      marginBottom: 10,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <Label
                                                        for="examplePassword"
                                                        sm={2}
                                                        style={{
                                                          textAlign: "left",
                                                          textTransform:
                                                            "capitalize",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {item1}
                                                      </Label>

                                                      <Button
                                                        color="primary"
                                                        style={{
                                                          marginBottom: 10,
                                                        }}
                                                        onClick={() => {
                                                          this.setState({
                                                            addMoreOfObjSection1:
                                                              dataOfObjects[
                                                                item1
                                                              ][0],
                                                            openAddMoreObjOfSection1:
                                                              true,
                                                            addMoreOfObjSection1Name:
                                                              item1,
                                                            parrentDataNameForAddMore:
                                                              item,
                                                          });
                                                        }}
                                                      >
                                                        Add More
                                                      </Button>
                                                    </div>
                                                    <div className="table-responsive">
                                                      <Table
                                                        hover
                                                        responsive
                                                        className="table mb-0"
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <thead className="table-light">
                                                          <tr>
                                                            <th>Sno</th>

                                                            {Object.keys(
                                                              dataOfObjects[
                                                                item1
                                                              ][0]
                                                            ).map(
                                                              (
                                                                item2: any,
                                                                index2: any
                                                              ) => (
                                                                <th
                                                                  key={index2}
                                                                >
                                                                  {
                                                                    dataOfObjects[
                                                                      item1
                                                                    ][0][item2]
                                                                      .label
                                                                  }
                                                                </th>
                                                              )
                                                            )}

                                                            <th>Edit</th>
                                                            <th>Delete</th>
                                                          </tr>
                                                        </thead>

                                                        <tbody>
                                                          {Layout &&
                                                            Layout.length > 0 &&
                                                            Layout[0] &&
                                                            Layout[0][item] &&
                                                            Layout[0][item][
                                                              item1
                                                            ] &&
                                                            Layout[0][item][
                                                              item1
                                                            ].map(
                                                              (
                                                                item3: any,
                                                                index: any
                                                              ) => (
                                                                // console.log(Layout[0][item][item1],'238u4792uewehdkjd',item1)

                                                                <tr key={index}>
                                                                  <td>#</td>

                                                                  {Object.keys(
                                                                    item3
                                                                  ).map(
                                                                    (
                                                                      items: any,
                                                                      indexs: any
                                                                    ) => {
                                                                      if (
                                                                        item3[
                                                                          items
                                                                        ]
                                                                          .toString()
                                                                          .includes(
                                                                            "public/uploads/"
                                                                          )
                                                                      ) {
                                                                        return (
                                                                          <td
                                                                            key={
                                                                              indexs
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={`https://api-news.nebrascorp.com/pivot-media/${item3[items]}`}
                                                                              height="50"
                                                                              width="50"
                                                                            />
                                                                          </td>
                                                                        );
                                                                      } else {
                                                                        return (
                                                                          <td
                                                                            key={
                                                                              indexs
                                                                            }
                                                                          >
                                                                            {item3[
                                                                              items
                                                                            ].toString()}
                                                                          </td>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}

                                                                  <td
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      this.setState(
                                                                        {
                                                                          openHelpArrayObj1:
                                                                            true,
                                                                          openHelpArrayObj1ParentArrName:
                                                                            item1,
                                                                          mainObjectName:
                                                                            item,
                                                                          openedInnerObjectOfArrIndex:
                                                                            index,
                                                                          openHelpArrayObj1Data:
                                                                            dataOfObjects[
                                                                              item1
                                                                            ],
                                                                          objectsArrDataForEdit:
                                                                            item3,
                                                                        },
                                                                        () =>
                                                                          console.log(
                                                                            this
                                                                              .state
                                                                              .openHelpArrayObj1ParentArrName,
                                                                            "aisudqwoieuq9we321"
                                                                          )
                                                                      );
                                                                    }}
                                                                  >
                                                                    <EditOutlined />
                                                                  </td>

                                                                  <td
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      this.setState(
                                                                        {
                                                                          openDeleteForObjSectionArrs:
                                                                            true,
                                                                          indexOfRowForDelete:
                                                                            index,
                                                                          ParrentFieldName:
                                                                            item1,
                                                                          mainObjectNameForDelete:
                                                                            item,
                                                                        },
                                                                        () =>
                                                                          console.log(
                                                                            this
                                                                              .state
                                                                              .ParrentFieldName,
                                                                            "asjdkashidqweq3",
                                                                            this
                                                                              .state
                                                                              .indexOfRowForDelete
                                                                          )
                                                                      );
                                                                    }}
                                                                  >
                                                                    <DeleteOutlined />
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                        </tbody>
                                                      </Table>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                          {/* <Button color='primary' >Done</Button> */}
                                        </CardBody>
                                      </Card>
                                    </Row>
                                  </>
                                );
                              } else if (
                                typeof SchemaData[0][item] == "object" &&
                                Array.isArray(SchemaData[0][item])
                              ) {
                                console.log(
                                  item,
                                  "checking arrays data",
                                  SchemaData[0]
                                );

                                return (
                                  <>
                                    <Row>
                                      <Card>
                                        <CardBody>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Label>
                                              <strong>{item}</strong>
                                            </Label>
                                            <Button
                                              color="primary"
                                              style={{ marginBottom: 10 }}
                                              onClick={() =>
                                                this.setState({
                                                  openAddMoreArrOfSection1:
                                                    true,
                                                  addMoreOfArrSection1Name:
                                                    item,
                                                  addMoreOfArrSection1:
                                                    SchemaData[0][item][0],
                                                })
                                              }
                                            >
                                              {" "}
                                              Add More{" "}
                                            </Button>
                                          </div>
                                          <div className="table-responsive">
                                            {
                                              <Table
                                                hover
                                                responsive
                                                className="table mb-0"
                                                style={{ width: "100%" }}
                                              >
                                                <thead className="table-light">
                                                  <tr>
                                                    <th>Sno</th>

                                                    {Object.keys(
                                                      SchemaData[0][item][0]
                                                    ).map(
                                                      (
                                                        item2: any,
                                                        index2: any
                                                      ) => {
                                                        if (
                                                          !Array.isArray(
                                                            SchemaData[0][
                                                              item
                                                            ][0][item2]
                                                          )
                                                        ) {
                                                          return (
                                                            <th key={index2}>
                                                              {
                                                                SchemaData[0][
                                                                  item
                                                                ][0][item2]
                                                                  ?.label
                                                              }
                                                            </th>
                                                          );
                                                        }
                                                      }
                                                    )}

                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                  </tr>
                                                </thead>

                                                <tbody>
                                                  {Layout &&
                                                    Layout.length > 0 &&
                                                    Layout[0] &&
                                                    Layout[0][item] &&
                                                    Array.isArray(
                                                      Layout[0][item]
                                                    ) &&
                                                    Layout[0][item].length >
                                                      0 &&
                                                    Layout[0][item]
                                                      ?.slice(
                                                        this.state.prev,
                                                        this.state.next
                                                      )
                                                      .map(
                                                        (
                                                          item5: any,
                                                          index5: any
                                                        ) => {
                                                          // indexForPagination= index5;
                                                          console.log(
                                                            "asduyqwiey2893123891",
                                                            indexForPagination
                                                          );
                                                          return (
                                                            <tr key={index5}>
                                                              <td>#</td>
                                                              {Object.keys(
                                                                item5
                                                              ).map(
                                                                (
                                                                  item6: any,
                                                                  index6: any
                                                                ) => {
                                                                  if (
                                                                    typeof item5[
                                                                      item6
                                                                    ] !==
                                                                    "object"
                                                                  ) {
                                                                    // console.log(Layout[0][item][0][item5][item6],item5,'weuioiqeuqwioeifkjsk',item6)
                                                                    if (
                                                                      typeof item5[
                                                                        item6
                                                                      ] ===
                                                                        "string" &&
                                                                      item5[
                                                                        item6
                                                                      ]
                                                                        ?.toString()
                                                                        .includes(
                                                                          "public/uploads/"
                                                                        )
                                                                    ) {
                                                                      return (
                                                                        <td
                                                                          key={
                                                                            index6
                                                                          }
                                                                        >
                                                                          <img
                                                                            src={`https://api-news.nebrascorp.com/pivot-media/${item5[item6]}`}
                                                                            height="50"
                                                                            width="50"
                                                                          />
                                                                        </td>
                                                                      );
                                                                    } else {
                                                                      return (
                                                                        <td
                                                                          key={
                                                                            index6
                                                                          }
                                                                        >
                                                                          {item5[
                                                                            item6
                                                                          ].toString()}
                                                                        </td>
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                              )}
                                                              <td
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  this.setState(
                                                                    {
                                                                      openHelpForArrSectionEdit:
                                                                        true,
                                                                      ArrSectionEditName:
                                                                        item,
                                                                      indexOfPartData:
                                                                        this.state.prev + index5,
                                                                      ArrSectionEditData:
                                                                        SchemaData[0][
                                                                          item
                                                                        ][0],
                                                                      HelpForArrOfObjDataEdit:
                                                                        item5,
                                                                    },
                                                                    () =>
                                                                      console.log(
                                                                        this
                                                                          .state
                                                                          .indexOfPartData,
                                                                        "asldijwq8eu9281213"
                                                                      )
                                                                  );
                                                                }}
                                                              >
                                                                <EditOutlined />
                                                              </td>

                                                              <td
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  this.setState(
                                                                    {
                                                                      openDeleteForArrSection:
                                                                        true,
                                                                      tableRowIndexForArr:
                                                                      this.state.prev +  index5,
                                                                      mainObjNamesForArrDelete:
                                                                        item,
                                                                    },
                                                                    () =>
                                                                      console.log(this.state.tableRowIndexForArr,  "dsbdjhshsddsddsd")
                                                                  );
                                                                }}
                                                              >
                                                                <DeleteOutlined />
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}

                                                  {/* <tr>
                                       
                                       
                                       <td style={{cursor:"pointer"}} onClick={()=>{
                                         this.setState({
                                          openHelpForArrSectionEdit:true,
                                          ArrSectionEditName:item,
                                          ArrSectionEditData:SchemaData[0][item][0]


                                         })
                                       }}><EditOutlined /></td>
                                       <td style={{cursor:"pointer"}} onClick={()=>{
                                             this.setState({
                                              openDeleteForArrSection:true,

                                             })
                                       }}><DeleteOutlined /></td>
                                      

                                       </tr> */}
                                                </tbody>
                                              </Table>
                                            }
                                          </div>

                                          {Layout[0][item]?.length > 10 && (
                                            <div>
                                              <Container fluid>
                                                <Row>
                                                  <Col
                                                    sm={11}
                                                    style={{
                                                      textAlign: "right",
                                                      marginTop: 2,
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    Page {this.state.page}{" "}
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      of
                                                    </span>{" "}
                                                    {Math.ceil(
                                                      Layout[0][item]?.length /
                                                        10
                                                    )}
                                                  </Col>

                                                  <Col
                                                    style={{
                                                      margin: "0px 16px",
                                                    }}
                                                  >
                                                    <button
                                                      style={{
                                                        border: 0,
                                                        background:
                                                          "transparent",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        this.setState({
                                                          prev:
                                                            this.state.prev -
                                                            10,
                                                          next:
                                                            this.state.next -
                                                            10,
                                                          page:
                                                            this.state.page - 1,
                                                        });

                                                        // if(indexForPagination !== null){
                                                        //   console.log(indexForPagination, "dbdbdbdbdbdbdbdbdbd")
                                                        // }
                                                      }}
                                                      disabled={
                                                        this.state.prev == 0
                                                          ? true
                                                          : false
                                                      }
                                                    >
                                                      <DoubleLeftOutlined />
                                                    </button>
                                                  </Col>
                                                  <Col>
                                                    <button
                                                      style={{
                                                        border: 0,
                                                        background:
                                                          "transparent",
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        this.setState({
                                                          prev: this.state.next,
                                                          next:
                                                            this.state.next +
                                                            10,
                                                          page:
                                                            this.state.page + 1,
                                                        });
                                                      }}
                                                      disabled={
                                                        Layout[0][item]
                                                          ?.length <
                                                        this.state.next
                                                          ? true
                                                          : false
                                                      }
                                                    >
                                                      <DoubleRightOutlined />
                                                    </button>
                                                  </Col>
                                                </Row>
                                              </Container>
                                            </div>
                                          )}
                                        </CardBody>
                                      </Card>
                                    </Row>
                                  </>
                                );
                              } else {
                                <></>;
                              }
                            }
                          )}
                      </CardBody>
                      <Alert
                        color="success"
                        isOpen={this.state.savingSuccessAlert}
                      >
                        Your Changes have been Saved Successfully ! Please wait
                        while Reload
                      </Alert>
                    </Card>
                  ) : null}
                </Row>
              </Container>
            </Container>

            {/* Array Section Helps */}

            {/* Arrays Add More Work */}

            <Offcanvas
              fade={false}
              isOpen={this.state.openAddMoreArrOfSection1}
              style={{ width: "60%" }}
              scrollable
              direction="end"
              toggle={() => this.setState({ openAddMoreArrOfSection1: false })}
            >
              <OffcanvasHeader
                toggle={() =>
                  this.setState({ openAddMoreArrOfSection1: false })
                }
              >
                {this.state.addMoreOfArrSection1Name}
              </OffcanvasHeader>

              <OffcanvasBody>
                <Container>
                  <Row>
                    {addMoreOfArrSection1 &&
                      Object.keys(addMoreOfArrSection1).map(
                        (item: any, index: any) => {
                          console.log("i am here hamza");
                          if (
                            typeof addMoreOfArrSection1[item] == "object" &&
                            !Array.isArray(addMoreOfArrSection1[item])
                          ) {
                            if (
                              addMoreOfArrSection1[item].controlType == "text"
                            ) {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {addMoreOfArrSection1[item].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id={`${addMoreOfArrSection1[item].ref}#section`}
                                      name="textField"
                                      type="text"
                                      onChange={(e) =>
                                        this.addMoreOnChangeOfArr(
                                          e,
                                          addMoreOfArrSection1[item].ref,
                                          ""
                                        )
                                      }
                                    ></Input>
                                    {addMoreOfArrSection1[item]
                                      .inputValidation !== "text" && (
                                      <p
                                        style={{
                                          color: "#74788D",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {
                                          addMoreOfArrSection1[item]
                                            .inputValidation
                                        }{" "}
                                      </p>
                                    )}

                                    <p>
                                      {addMoreOfArrSection1[item].ref ==
                                      "id" ? (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          * This field is mandatory{" "}
                                        </p>
                                      ) : null}
                                    </p>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (
                              addMoreOfArrSection1[item].controlType == "image"
                            ) {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {addMoreOfArrSection1[item].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="file"
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            isFileUploading: true,
                                          },
                                          () => {
                                            this.addMoreOnChangeOfArr(
                                              e,
                                              addMoreOfArrSection1[item].ref,
                                              "file"
                                            );
                                          }
                                        );
                                      }}
                                    ></Input>

                                    <p
                                      style={{
                                        color: "#74788D",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        addMoreOfArrSection1[item]
                                          .inputValidation
                                      }{" "}
                                    </p>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (
                              addMoreOfArrSection1[item].controlType == "RTE"
                            ) {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {addMoreOfArrSection1[item].label}
                                  </Label>

                                  <Col sm={6}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="textarea"
                                      rows="6"
                                      onChange={(e) =>
                                        this.addMoreOnChangeOfArr(
                                          e,
                                          addMoreOfArrSection1[item].ref,
                                          ""
                                        )
                                      }
                                    ></Input>
                                  </Col>
                                </FormGroup>
                              );
                            } else {
                              <></>;
                            }
                          } else if (
                            typeof addMoreOfArrSection1[item] == "object" &&
                            Array.isArray(addMoreOfArrSection1[item])
                          ) {
                            return (
                              <FormGroup row key={index}>
                                <Label
                                  for="examplePassword"
                                  sm={2}
                                  style={{
                                    textAlign: "right",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item}
                                </Label>

                                <Col sm={4}>
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          open2orderArrOfAddMore: true,
                                          data2OrderArrOfAddMore:
                                            addMoreOfArrSection1[item],
                                          order2NameOfAddMore: item,
                                        },
                                        () =>
                                          console.log(
                                            this.state.data2OrderArrOfAddMore,
                                            "asiduqwe8u298313"
                                          )
                                      );
                                    }}
                                  >
                                    Open
                                  </Button>
                                </Col>
                              </FormGroup>
                            );
                          } else {
                            <></>;
                          }
                        }
                      )}

                    {
                      // !this.state.isFileUploading ?

                      <Button
                        color="primary"
                        style={{ width: "10%" }}
                        onClick={() => {
                          this.addMoreSavingArray(
                            this.state.addMoreOfArrSection1Name
                          );
                        }}
                      >
                        Save
                      </Button>

                      // :
                      // <Button color='primary' style={{width:"10%",cursor:'not-allowed'}}>Save</Button>
                    }
                  </Row>
                  <Alert color="danger" isOpen={this.state.alertOpen}>
                    ID Field Is Mandatory !
                  </Alert>

                  <Alert color="success" isOpen={this.state.savingSuccessAlert}>
                    Your Changes have been Saved Successfully ! Please wait
                    while Reload
                  </Alert>
                </Container>
              </OffcanvasBody>
            </Offcanvas>

            {/* Ends Here */}

            {/* 2 order array for Add More */}

            {/* <Offcanvas
            fade={false}
            isOpen={this.state.open2orderArrOfAddMore}
            style={{ width: '60%' }}
            scrollable
            direction='start'
            toggle={() => this.setState({ open2orderArrOfAddMore: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ open2orderArrOfAddMore: false })}
            >
              {this.state.order2NameOfAddMore}

            </OffcanvasHeader>

            <OffcanvasBody>
              <Container>
                <Row>
                {
                  data2OrderArrOfAddMore && data2OrderArrOfAddMore.length>0 &&
                  data2OrderArrOfAddMore.map((item:any,index:any)=>{
                    return(
                   Object.keys(item).map((items:any,indexs:any)=>{
                    if(item[items].controlType == 'text'){
                    return(

                      <FormGroup row key={indexs}>
                      <Label
                                  
                                  for='examplePassword'
                                  sm={2}
                                  style={{
                                    textAlign: 'right',
                                    textTransform: 'capitalize',
                                   
                                  }}
                                >
                                  {item[items].label}
                                </Label>
                                
                                   
                                    <Col sm={4}>
                                      <Input
                                        id='exampleSelect'
                                        name='textField'
                                        type='text'
                                       
                                      >
                                       
                                      </Input>
                                    </Col>
                                  </FormGroup>


                      
                    )
                                }
                                else if(item[items].controlType == 'image'){
                                  return(

                                    <FormGroup row key={indexs}>
                                    <Label
                                                
                                                for='examplePassword'
                                                sm={2}
                                                style={{
                                                  textAlign: 'right',
                                                  textTransform: 'capitalize',
                                                 
                                                }}
                                              >
                                                {item[items].label}
                                              </Label>
                                              
                                                 
                                                  <Col sm={4}>
                                                    <Input
                                                      id='exampleSelect'
                                                      name='textField'
                                                      type='file'
                                                     
                                                    >
                                                     
                                                    </Input>

                                            <p style={{color: "#74788D" , fontSize: "12px"}}>{item[items].inputValidation} </p>

                                                  </Col>
                                                </FormGroup>
              
              
                                    
                                  )
                                }
                                else if(item[items].controlType == 'RTE'){
                                  return(

                                    <FormGroup row key={indexs}>
                                    <Label
                                                
                                                for='examplePassword'
                                                sm={2}
                                                style={{
                                                  textAlign: 'right',
                                                  textTransform: 'capitalize',
                                                 
                                                }}
                                              >
                                                {item[items].label}
                                              </Label>
                                              
                                                 
                                                  <Col sm={4}>
                                                    <Input
                                                      id='exampleSelect'
                                                      name='textField'
                                                      type='textarea'
                                                      rows="6"
                                                    >
                                                     
                                                    </Input>
                                                  </Col>
                                                </FormGroup>
              
              
                                    
                                  )
                                }
                                else{
                                  <>
                                  </>
                                }
                   })
                    )
                  })
                }
                
                <Button color='primary' style={{width:"10%"}} onClick={()=>{
                  this.setState({
                    open2orderArrOfAddMore:false
                  })
                }} >
                      Close
                  </Button>
                 
                </Row>
              </Container>
            </OffcanvasBody>
          </Offcanvas>
 */}

            {/* Endes here */}

            {/* Arrays Section Help Second Order Data Edit */}

            <Offcanvas
              fade={false}
              isOpen={this.state.open2orderArrOfAddMore}
              style={{ width: "60%" }}
              scrollable
              direction="start"
              toggle={() => this.setState({ open2orderArrOfAddMore: false })}
            >
              <OffcanvasHeader
                toggle={() => this.setState({ open2orderArrOfAddMore: false })}
              >
                {this.state.order2NameOfAddMore}
              </OffcanvasHeader>

              <OffcanvasBody>
                <Container>
                  <Row>
                    {data2OrderArrOfAddMore &&
                      data2OrderArrOfAddMore.length > 0 &&
                      data2OrderArrOfAddMore.map((item: any, index: any) => {
                        return Object.keys(item).map(
                          (items: any, indexs: any) => {
                            if (item[items].controlType == "text") {
                              return (
                                <FormGroup row key={indexs}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="text"
                                      onChange={(e) =>
                                        this.changeAddMoreValuefor2order(
                                          e,
                                          item[items].ref,
                                          ""
                                        )
                                      }
                                    ></Input>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (item[items].controlType == "image") {
                              return (
                                <FormGroup row key={indexs}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="file"
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            isFileUploading: true,
                                          },
                                          () => {
                                            this.changeAddMoreValuefor2order(
                                              e,
                                              item[items].ref,
                                              "file"
                                            );
                                          }
                                        );
                                      }}
                                    ></Input>

                                    <p
                                      style={{
                                        color: "#74788D",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item[items].inputValidation}{" "}
                                    </p>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (item[items].controlType == "RTE") {
                              return (
                                <FormGroup row key={indexs}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={6}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="textarea"
                                      rows="6"
                                      onChange={(e) =>
                                        this.changeAddMoreValuefor2order(
                                          e,
                                          item[items].ref,
                                          ""
                                        )
                                      }
                                    ></Input>
                                  </Col>
                                </FormGroup>
                              );
                            } else {
                              <></>;
                            }
                          }
                        );
                      })}

                    <Button
                      color="primary"
                      style={{ width: "10%" }}
                      onClick={() =>
                        this.setState({ open2orderArrOfAddMore: false }, () =>
                          this.savingDataForArray()
                        )
                      }
                    >
                      Save!
                    </Button>
                  </Row>
                </Container>
              </OffcanvasBody>
            </Offcanvas>

            {/* Ends Here */}

            {/* Array Section Edit Help */}

            <Offcanvas
              fade={false}
              isOpen={this.state.openHelpForArrSectionEdit}
              style={{ width: "90%" }}
              scrollable
              direction="end"
              toggle={() => this.setState({ openHelpForArrSectionEdit: false })}
            >
              <OffcanvasHeader
                toggle={() =>
                  this.setState({ openHelpForArrSectionEdit: false })
                }
              >
                {this.state.ArrSectionEditName}
              </OffcanvasHeader>

              <OffcanvasBody>
                <Container>
                  <Row>
                    {ArrSectionEditData &&
                      Object.keys(ArrSectionEditData).map(
                        (item: any, index: any) => {
                          if (
                            typeof ArrSectionEditData[item] == "object" &&
                            !Array.isArray(ArrSectionEditData[item])
                          ) {
                            if (
                              ArrSectionEditData[item].controlType == "text"
                            ) {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {ArrSectionEditData[item].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="text"
                                      value={
                                        this.state.HelpForArrOfObjDataEdit[
                                          ArrSectionEditData[item].ref
                                        ]
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          "",
                                          this.state.ArrSectionEditName,
                                          this.state.indexOfPartData,
                                          ArrSectionEditData[item].ref,
                                          "",
                                          false,
                                          false,
                                          true,
                                          false,
                                          null,
                                          null,
                                          null
                                        )
                                      }
                                    ></Input>
                                    {ArrSectionEditData[item]
                                      .inputValidation !== "text" && (
                                      <p
                                        style={{
                                          color: "#74788D",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {
                                          ArrSectionEditData[item]
                                            .inputValidation
                                        }{" "}
                                      </p>
                                    )}
                                  </Col>
                                </FormGroup>
                              );
                            } else if (
                              ArrSectionEditData[item].controlType == "Boolean"
                            ) {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {ArrSectionEditData[item].label}
                                  </Label>

                                  <Col sm={4}>
                                    <select
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        border: "1px solid #E9E9EF",
                                        borderRadius: "2px",
                                      }}
                                      id="exampleSelect"
                                      name="textField"
                                      value={
                                        this.state.HelpForArrOfObjDataEdit[
                                          ArrSectionEditData[item].ref
                                        ]
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          "",
                                          this.state.ArrSectionEditName,
                                          this.state.indexOfPartData,
                                          ArrSectionEditData[item].ref,
                                          "",
                                          false,
                                          false,
                                          true,
                                          false,
                                          null,
                                          null,
                                          true
                                        )
                                      }
                                    >
                                      <option value="true" selected>
                                        true
                                      </option>
                                      <option value="false">false</option>
                                    </select>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (
                              ArrSectionEditData[item].controlType == "image"
                            ) {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {ArrSectionEditData[item].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="file"
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            isFileUploading: true,
                                          },
                                          () => {
                                            this.handleInputChange(
                                              e,
                                              "",
                                              this.state.ArrSectionEditName,
                                              this.state.indexOfPartData,
                                              ArrSectionEditData[item].ref,
                                              "file",
                                              false,
                                              false,
                                              true,
                                              false,
                                              null,
                                              null,
                                              null
                                            );
                                          }
                                        );
                                      }}
                                    ></Input>

                                    <p
                                      style={{
                                        color: "#74788D",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {ArrSectionEditData[item].inputValidation}{" "}
                                    </p>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (
                              ArrSectionEditData[item].controlType == "RTE"
                            ) {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {ArrSectionEditData[item].label}
                                  </Label>

                                  <Col sm={6}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="textarea"
                                      rows="6"
                                      value={
                                        this.state.HelpForArrOfObjDataEdit[
                                          ArrSectionEditData[item].ref
                                        ]
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          "",
                                          this.state.ArrSectionEditName,
                                          this.state.indexOfPartData,
                                          ArrSectionEditData[item].ref,
                                          "",
                                          false,
                                          false,
                                          true,
                                          false,
                                          null,
                                          null,
                                          null
                                        )
                                      }
                                    ></Input>
                                  </Col>
                                </FormGroup>
                              );
                            } else {
                              <></>;
                            }
                          } else if (
                            typeof ArrSectionEditData[item] == "object" &&
                            Array.isArray(ArrSectionEditData[item])
                          ) {
                            console.log(
                              ArrSectionEditData[item],
                              "jskahdjkasdxcmnz"
                            );
                            //working here
                            return (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Label>
                                    <strong>{item}</strong>
                                  </Label>

                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      this.setState({
                                        open2orderArrOfEdit: true,
                                        dataOfOrder2ArrayEdit:
                                          ArrSectionEditData[item],
                                        order2ArrEditName: item,
                                      });
                                    }}
                                  >
                                    Add More
                                  </Button>
                                </div>
                                <Table
                                  hover
                                  responsive
                                  className="table mb-0"
                                  style={{ width: "100%" }}
                                >
                                  <thead className="table-light">
                                    <tr>
                                      <th>Sno</th>

                                      {Object.keys(
                                        ArrSectionEditData[item][0]
                                      ).map((item2: any, index2: any) => (
                                        <th key={index2}>
                                          {
                                            ArrSectionEditData[item][0][item2]
                                              .label
                                          }
                                        </th>
                                      ))}

                                      <th>Edit</th>
                                      <th>Delete</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {this.state.HelpForArrOfObjDataEdit[item] &&
                                      this.state.HelpForArrOfObjDataEdit[item]
                                        .length > 0 &&
                                      this.state.HelpForArrOfObjDataEdit[
                                        item
                                      ].map((iteml: any, indexl: any) => (
                                        <tr key={indexl}>
                                          <td>#</td>

                                          {Object.keys(iteml).map(
                                            (itemk: any, indexk: any) => {
                                              // console.log(iteml[itemk],'hamza checking this upp')
                                              // if(iteml && iteml[itemk] && iteml[itemk]?.includes('public/uploads/')){
                                              //   return(
                                              //       <td key={indexk}>
                                              //            <img src={ `https://api-news.nebrascorp.com/pivot-media/${iteml[itemk]}`  } height="50" width="50" />

                                              //       </td>
                                              //   )
                                              // }else{

                                              return (
                                                <td key={indexk}>
                                                  {iteml[itemk].toString()}
                                                </td>
                                              );
                                              // }
                                            }
                                          )}

                                          <td
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              this.setState({
                                                openHelpFor2OrderArrSectionEdit:
                                                  true,
                                                ArrSectionFor2OrderEditName:
                                                  item,
                                                ArrSection2OrderEditData:
                                                  ArrSectionEditData[item],
                                                indexessOfRows: indexl,
                                                dataofInnerObjectsOfArrEditSec:
                                                  this.state
                                                    .HelpForArrOfObjDataEdit[
                                                    item
                                                  ],
                                              });
                                            }}
                                          >
                                            <EditOutlined />
                                          </td>
                                          <td
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              this.setState({
                                                openDeleteFor2orderOfArr: true,
                                                rowIndexOf2Order: indexl,
                                                array2OrderDeletefieldName:
                                                  item,
                                                // ArrSectionEditName
                                              });
                                            }}
                                          >
                                            <DeleteOutlined />
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                                <br />
                                <br />
                              </>
                            );
                          } else {
                            <></>;
                          }
                        }
                      )}
                  </Row>
                </Container>
              </OffcanvasBody>
            </Offcanvas>

            {/* ends Here */}

            {/* 2 order Array Section Help Of Edit */}

            {/* ------- Add More  */}

            <Offcanvas
              fade={false}
              isOpen={this.state.open2orderArrOfEdit}
              style={{ width: "60%" }}
              scrollable
              direction="start"
              toggle={() => this.setState({ open2orderArrOfEdit: false })}
            >
              <OffcanvasHeader
                toggle={() => this.setState({ open2orderArrOfEdit: false })}
              >
                {this.state.order2ArrEditName}
              </OffcanvasHeader>

              <OffcanvasBody>
                <Container>
                  <Row>
                    {dataOfOrder2ArrayEdit &&
                      dataOfOrder2ArrayEdit.length > 0 &&
                      dataOfOrder2ArrayEdit.map((item: any) => {
                        return Object.keys(item).map(
                          (items: any, index: any) => {
                            if (item[items].controlType == "text") {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="text"
                                      onChange={(e) => {
                                        this.savingAddMoreForEditGrids(
                                          e,
                                          item[items].ref,
                                          this.state.ArrSectionEditName,
                                          this.state.order2ArrEditName,
                                          this.state.indexOfPartData,
                                          ""
                                        );
                                      }}
                                    ></Input>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (item[items].controlType == "image") {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="file"
                                      onChange={(e) => {
                                        this.savingAddMoreForEditGrids(
                                          e,
                                          item[items].ref,
                                          this.state.ArrSectionEditName,
                                          this.state.order2ArrEditName,
                                          this.state.indexOfPartData,
                                          "file"
                                        );
                                      }}
                                    ></Input>

                                    <p
                                      style={{
                                        color: "#74788D",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item[items].inputValidation}{" "}
                                    </p>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (item[items].controlType == "RTE") {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={6}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="textarea"
                                      rows="6"
                                      onChange={(e) => {
                                        this.savingAddMoreForEditGrids(
                                          e,
                                          item[items].ref,
                                          this.state.ArrSectionEditName,
                                          this.state.order2ArrEditName,
                                          this.state.indexOfPartData,
                                          ""
                                        );
                                      }}
                                    ></Input>
                                  </Col>
                                </FormGroup>
                              );
                            } else {
                              <></>;
                            }
                          }
                        );
                      })}
                    <Button
                      color="primary"
                      style={{ width: "10%" }}
                      onClick={() => {
                        this.saveAddMoreForEditInArr();
                      }}
                    >
                      Save
                    </Button>
                    <Alert
                      color="success"
                      isOpen={this.state.savingSuccessAlert}
                    >
                      Your Changes have been Saved Successfully ! Please wait
                      while Reload
                    </Alert>
                  </Row>
                </Container>
              </OffcanvasBody>
            </Offcanvas>

            {/* -------- */}

            {/* <Button color='primary' onClick={()=>{
                          this.setState({
                            open2orderArrOfEdit:true,
                            dataOfOrder2ArrayEdit:ArrSectionEditData[item],
                            order2ArrEditName:item
                          })
                        }}>Open</Button> */}

            <Offcanvas
              fade={false}
              isOpen={this.state.openHelpFor2OrderArrSectionEdit}
              style={{ width: "60%" }}
              scrollable
              direction="start"
              toggle={() =>
                this.setState({ openHelpFor2OrderArrSectionEdit: false })
              }
            >
              <OffcanvasHeader
                toggle={() =>
                  this.setState({ openHelpFor2OrderArrSectionEdit: false })
                }
              >
                {this.state.ArrSectionFor2OrderEditName}
              </OffcanvasHeader>

              <OffcanvasBody>
                <Container>
                  <Row>
                    {ArrSection2OrderEditData &&
                      ArrSection2OrderEditData.length > 0 &&
                      ArrSection2OrderEditData.map((item: any) => {
                        return Object.keys(item).map(
                          (items: any, index: any) => {
                            if (item[items].controlType == "text") {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="text"
                                      value={
                                        this.state
                                          .dataofInnerObjectsOfArrEditSec[
                                          this.state.indexessOfRows
                                        ][item[items].ref]
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          this.state.ArrSectionEditName,
                                          this.state
                                            .ArrSectionFor2OrderEditName,
                                          this.state.indexessOfRows,
                                          item[items].ref,
                                          "",
                                          false,
                                          false,
                                          false,
                                          true,
                                          null,
                                          null,
                                          null
                                        )
                                      }
                                    ></Input>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (item[items].controlType == "Boolean") {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={4}>
                                    <select
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        border: "1px solid #E9E9EF",
                                        borderRadius: "2px",
                                      }}
                                      id="exampleSelect"
                                      name="textField"
                                      value={
                                        this.state
                                          .dataofInnerObjectsOfArrEditSec[
                                          this.state.indexessOfRows
                                        ][item[items].ref]
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          this.state.ArrSectionEditName,
                                          this.state
                                            .ArrSectionFor2OrderEditName,
                                          this.state.indexessOfRows,
                                          item[items].ref,
                                          "",
                                          false,
                                          false,
                                          false,
                                          true,
                                          null,
                                          null,
                                          true
                                        )
                                      }
                                    >
                                      <option value="true" selected>
                                        true
                                      </option>
                                      <option value="false">false</option>
                                    </select>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (item[items].controlType == "image") {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="file"
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            isFileUploading: true,
                                          },
                                          () => {
                                            this.handleInputChange(
                                              e,
                                              this.state.ArrSectionEditName,
                                              this.state
                                                .ArrSectionFor2OrderEditName,
                                              this.state.indexessOfRows,
                                              item[items].ref,
                                              "file",
                                              false,
                                              false,
                                              false,
                                              true,
                                              null,
                                              null,
                                              null
                                            );
                                          }
                                        );
                                      }}
                                    ></Input>

                                    <p
                                      style={{
                                        color: "#74788D",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item[items].inputValidation}{" "}
                                    </p>
                                  </Col>
                                </FormGroup>
                              );
                            } else if (item[items].controlType == "RTE") {
                              return (
                                <FormGroup row key={index}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item[items].label}
                                  </Label>

                                  <Col sm={6}>
                                    <Input
                                      id="exampleSelect"
                                      name="textField"
                                      type="textarea"
                                      rows="6"
                                      value={
                                        this.state
                                          .dataofInnerObjectsOfArrEditSec[
                                          this.state.indexessOfRows
                                        ][item[items].ref]
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          this.state.ArrSectionEditName,
                                          this.state
                                            .ArrSectionFor2OrderEditName,
                                          this.state.indexessOfRows,
                                          item[items].ref,
                                          "",
                                          false,
                                          false,
                                          false,
                                          true,
                                          null,
                                          null,
                                          null
                                        )
                                      }
                                    ></Input>
                                  </Col>
                                </FormGroup>
                              );
                            } else {
                              <></>;
                            }
                          }
                        );
                      })}
                    <Button
                      color="primary"
                      style={{ width: "10%" }}
                      onClick={() => {
                        this.setState({});
                      }}
                    >
                      Close
                    </Button>
                  </Row>
                </Container>
              </OffcanvasBody>
            </Offcanvas>

            {/* Ends Here----- */}

            {/* Array Section Helps Ends Here-------------- */}

            {/* Object Section Helps */}

            {/* First order Array Help For Edit Object Section Only */}

            <Offcanvas
              fade={false}
              isOpen={this.state.openHelpArrayObj1}
              style={{ width: "60%" }}
              scrollable
              direction="end"
              toggle={() => this.setState({ openHelpArrayObj1: false })}
            >
              <OffcanvasHeader
                toggle={() => this.setState({ openHelpArrayObj1: false })}
              >
                {this.state.openHelpArrayObj1ParentArrName}
              </OffcanvasHeader>

              <OffcanvasBody>
                <Container>
                  <Row>
                    {openHelpArrayObj1Data &&
                      openHelpArrayObj1Data.length > 0 &&
                      openHelpArrayObj1Data.map((itemH1: any) => {
                        return Object.keys(itemH1).map(
                          (itemH2: any, indexH2: any) => {
                            if (
                              typeof itemH1[itemH2] == "object" &&
                              !Array.isArray(itemH1[itemH2])
                            ) {
                              if (itemH1[itemH2].controlType == "text") {
                                return (
                                  <FormGroup row key={indexH2}>
                                    <Label
                                      for="examplePassword"
                                      sm={2}
                                      style={{
                                        textAlign: "right",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {itemH1[itemH2].label}
                                    </Label>

                                    <Col sm={4}>
                                      <Input
                                        id="exampleSelect"
                                        name="textField"
                                        type="text"
                                        value={
                                          this.state.objectsArrDataForEdit[
                                            itemH1[itemH2].ref
                                          ]
                                        }
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            e,
                                            "",
                                            this.state.mainObjectName,
                                            this.state
                                              .openHelpArrayObj1ParentArrName,
                                            "",
                                            "",
                                            false,
                                            true,
                                            false,
                                            false,
                                            itemH1[itemH2].ref,
                                            this.state
                                              .openedInnerObjectOfArrIndex,
                                            null
                                          )
                                        }
                                      ></Input>
                                    </Col>
                                  </FormGroup>
                                );
                              } else if (
                                itemH1[itemH2].controlType == "Boolean"
                              ) {
                                return (
                                  <FormGroup row key={indexH2}>
                                    <Label
                                      for="examplePassword"
                                      sm={2}
                                      style={{
                                        textAlign: "right",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {itemH1[itemH2].label}
                                    </Label>

                                    <Col sm={4}>
                                      <select
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          border: "1px solid #E9E9EF",
                                          borderRadius: "2px",
                                        }}
                                        id="exampleSelect"
                                        name="textField"
                                        value={
                                          this.state.objectsArrDataForEdit[
                                            itemH1[itemH2].ref
                                          ]
                                        }
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            e,
                                            "",
                                            this.state.mainObjectName,
                                            this.state
                                              .openHelpArrayObj1ParentArrName,
                                            "",
                                            "",
                                            false,
                                            true,
                                            false,
                                            false,
                                            itemH1[itemH2].ref,
                                            this.state
                                              .openedInnerObjectOfArrIndex,
                                            true
                                          )
                                        }
                                      >
                                        <option value="true" selected>
                                          true
                                        </option>
                                        <option value="false">false</option>
                                      </select>
                                    </Col>
                                  </FormGroup>
                                );
                              } else if (
                                itemH1[itemH2].controlType == "image"
                              ) {
                                return (
                                  <FormGroup row key={indexH2}>
                                    <Label
                                      for="examplePassword"
                                      sm={2}
                                      style={{
                                        textAlign: "right",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {itemH1[itemH2].label}
                                    </Label>

                                    <Col sm={4}>
                                      <Input
                                        id="exampleSelect"
                                        name="textField"
                                        type="file"
                                        placeholder={
                                          this.state.objectsArrDataForEdit[
                                            itemH1[itemH2].ref
                                          ]
                                        }
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              isFileUploading: true,
                                            },
                                            () => {
                                              this.handleInputChange(
                                                e,
                                                "",
                                                this.state.mainObjectName,
                                                this.state
                                                  .openHelpArrayObj1ParentArrName,
                                                "",
                                                "file",
                                                false,
                                                true,
                                                false,
                                                false,
                                                itemH1[itemH2].ref,
                                                this.state
                                                  .openedInnerObjectOfArrIndex,
                                                null
                                              );
                                            }
                                          );
                                        }}
                                      ></Input>

                                      <p
                                        style={{
                                          color: "#74788D",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {itemH1[itemH2].inputValidation}{" "}
                                      </p>
                                    </Col>
                                  </FormGroup>
                                );
                              } else if (itemH1[itemH2].controlType == "RTE") {
                                return (
                                  <FormGroup row key={indexH2}>
                                    <Label
                                      for="examplePassword"
                                      sm={2}
                                      style={{
                                        textAlign: "right",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {itemH1[itemH2].label}
                                    </Label>

                                    <Col sm={6}>
                                      <Input
                                        id="exampleSelect"
                                        name="textField"
                                        type="textarea"
                                        rows="6"
                                        value={
                                          this.state.objectsArrDataForEdit[
                                            itemH1[itemH2].ref
                                          ]
                                        }
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            e,
                                            "",
                                            this.state.mainObjectName,
                                            this.state
                                              .openHelpArrayObj1ParentArrName,
                                            "",
                                            "",
                                            false,
                                            true,
                                            false,
                                            false,
                                            itemH1[itemH2].ref,
                                            this.state
                                              .openedInnerObjectOfArrIndex,
                                            null
                                          )
                                        }
                                      ></Input>
                                    </Col>
                                  </FormGroup>
                                );
                              } else {
                                <></>;
                              }
                            } else if (
                              typeof itemH1[itemH2] == "object" &&
                              Array.isArray(itemH1[itemH2])
                            ) {
                              return (
                                <FormGroup row key={indexH2}>
                                  <Label
                                    for="examplePassword"
                                    sm={2}
                                    style={{
                                      textAlign: "right",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {itemH1[itemH2].label}
                                  </Label>

                                  <Col sm={4}>
                                    <Button
                                      color="primary"
                                      style={{ width: "10%" }}
                                    >
                                      Open
                                    </Button>
                                  </Col>
                                </FormGroup>
                              );
                            } else {
                              <></>;
                            }
                          }
                        );
                      })}

                    <Button
                      color="primary"
                      style={{ width: "10%" }}
                      onClick={() =>
                        this.setState({
                          openHelpArrayObj1: false,
                        })
                      }
                    >
                      Close
                    </Button>
                  </Row>
                </Container>
              </OffcanvasBody>
            </Offcanvas>

            {/* Ends Here ---- */}

            {/* Object Section ADD More Help */}

            <Offcanvas
              fade={false}
              isOpen={this.state.openAddMoreObjOfSection1}
              style={{ width: "60%" }}
              scrollable
              direction="end"
              toggle={() => this.setState({ openAddMoreObjOfSection1: false })}
            >
              <OffcanvasHeader
                toggle={() =>
                  this.setState({ openAddMoreObjOfSection1: false })
                }
              >
                {this.state.addMoreOfObjSection1Name}
              </OffcanvasHeader>

              <OffcanvasBody>
                <Container>
                  <Row>
                    {Object.keys(addMoreOfObjSection1).map(
                      (itemH1: any, indexH1: any) => {
                        if (
                          typeof addMoreOfObjSection1[itemH1] == "object" &&
                          !Array.isArray(addMoreOfObjSection1[itemH1])
                        ) {
                          if (
                            addMoreOfObjSection1[itemH1].controlType == "text"
                          ) {
                            return (
                              <FormGroup row key={indexH1}>
                                <Label
                                  for="examplePassword"
                                  sm={2}
                                  style={{
                                    textAlign: "right",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {addMoreOfObjSection1[itemH1].label}
                                </Label>

                                <Col sm={4}>
                                  <Input
                                    id="exampleSelect"
                                    name="textField"
                                    type="text"
                                    onChange={(e) =>
                                      this.addMoreOnChange(
                                        e,
                                        addMoreOfObjSection1[itemH1].ref,
                                        ""
                                      )
                                    }
                                  ></Input>
                                </Col>
                              </FormGroup>
                            );
                          } else if (
                            addMoreOfObjSection1[itemH1].controlType == "image"
                          ) {
                            return (
                              <FormGroup row key={indexH1}>
                                <Label
                                  for="examplePassword"
                                  sm={2}
                                  style={{
                                    textAlign: "right",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {addMoreOfObjSection1[itemH1].label}
                                </Label>

                                <Col sm={4}>
                                  <Input
                                    id="exampleSelect"
                                    name="textField"
                                    type="file"
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          isFileUploading: true,
                                        },
                                        () => {
                                          this.addMoreOnChange(
                                            e,
                                            addMoreOfObjSection1[itemH1].ref,
                                            "file"
                                          );
                                        }
                                      );
                                    }}
                                  ></Input>

                                  <p
                                    style={{
                                      color: "#74788D",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {
                                      addMoreOfObjSection1[itemH1]
                                        .inputValidation
                                    }{" "}
                                  </p>
                                </Col>
                              </FormGroup>
                            );
                          } else if (
                            addMoreOfObjSection1[itemH1].controlType == "RTE"
                          ) {
                            return (
                              <FormGroup row key={indexH1}>
                                <Label
                                  for="examplePassword"
                                  sm={2}
                                  style={{
                                    textAlign: "right",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {addMoreOfObjSection1[itemH1].label}
                                </Label>

                                <Col sm={6}>
                                  <Input
                                    id="exampleSelect"
                                    name="textField"
                                    type="textarea"
                                    rows="6"
                                    onChange={(e) =>
                                      this.addMoreOnChange(
                                        e,
                                        addMoreOfObjSection1[itemH1].ref,
                                        ""
                                      )
                                    }
                                  ></Input>
                                </Col>
                              </FormGroup>
                            );
                          } else {
                            <></>;
                          }
                        } else if (
                          typeof addMoreOfObjSection1[itemH1] == "object" &&
                          Array.isArray(addMoreOfObjSection1[itemH1])
                        ) {
                          return (
                            <></>
                            // <FormGroup row key={indexH1}>
                            // <Label

                            //             for='examplePassword'
                            //             sm={2}
                            //             style={{
                            //               textAlign: 'right',
                            //               textTransform: 'capitalize',

                            //             }}
                            //           >
                            //             {addMoreOfObjSection1[itemH1].label}
                            //           </Label>

                            //               <Col sm={4}>
                            //                <Button color='primary' style={{width:"10%"}}>Open</Button>
                            //               </Col>
                            //             </FormGroup>
                          );
                        } else {
                          <></>;
                        }
                      }
                    )}

                    <Button
                      color="primary"
                      style={{ width: "10%" }}
                      onClick={() =>
                        this.savingAddmoreData(
                          this.state.addMoreOfObjSection1Name,
                          this.state.parrentDataNameForAddMore
                        )
                      }
                    >
                      Save
                    </Button>
                    <Alert
                      color="success"
                      isOpen={this.state.savingSuccessAlert}
                      style={{ marginTop: 10 }}
                    >
                      Your Changes have been Saved Successfully ! Please wait
                      while Reload
                    </Alert>
                  </Row>
                </Container>
              </OffcanvasBody>
            </Offcanvas>

            {/* Ends Here */}

            {/* Delete Modal For Object Section's Arrays 1 */}
            <Modal
              isOpen={openDeleteForObjSectionArrs}
              toggle={() =>
                this.setState({
                  openDeleteForObjSectionArrs:
                    !this.state.openDeleteForObjSectionArrs,
                })
              }
            >
              <ModalHeader
                toggle={() =>
                  this.setState({
                    openDeleteForObjSectionArrs:
                      !this.state.openDeleteForObjSectionArrs,
                  })
                }
                close={() =>
                  this.setState({ openDeleteForObjSectionArrs: false })
                }
              >
                Delete
              </ModalHeader>
              <ModalBody>
                Are You Sure You Want To Delete {this.state.ParrentFieldName}'s
                Row Number {this.state.indexOfRowForDelete + 1}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() =>
                    this.handleDeleteOfAll(
                      this.state.indexOfRowForDelete,
                      this.state.ParrentFieldName,
                      this.state.mainObjectNameForDelete,
                      true,
                      false,
                      false
                    )
                  }
                >
                  Confirm
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() =>
                    this.setState({ openDeleteForObjSectionArrs: false })
                  }
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            {/* Ends here */}

            {/* Delete Modal For Array Of Objects Section */}

            <Modal
              isOpen={openDeleteForArrSection}
              toggle={() =>
                this.setState({
                  openDeleteForArrSection: !this.state.openDeleteForArrSection,
                })
              }
            >
              <ModalHeader
                toggle={() =>
                  this.setState({
                    openDeleteForArrSection:
                      !this.state.openDeleteForArrSection,
                  })
                }
                close={() => this.setState({ openDeleteForArrSection: false })}
              >
                Delete
              </ModalHeader>
              <ModalBody>
                Are You Sure You Want To Delete{" "}
                {this.state.mainObjNamesForArrDelete}'s Row Number{" "}
                {this.state.tableRowIndexForArr + 1}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() =>
                    this.handleDeleteOfAll(
                      this.state.tableRowIndexForArr,
                      this.state.mainObjNamesForArrDelete,
                      "",
                      false,
                      true,
                      false
                    )
                  }
                >
                  Confirm
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() =>
                    this.setState({ openDeleteForArrSection: false })
                  }
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={openDeleteFor2orderOfArr}
              toggle={() =>
                this.setState({
                  openDeleteFor2orderOfArr:
                    !this.state.openDeleteFor2orderOfArr,
                })
              }
            >
              <ModalHeader
                toggle={() =>
                  this.setState({
                    openDeleteFor2orderOfArr:
                      !this.state.openDeleteFor2orderOfArr,
                  })
                }
                close={() => this.setState({ openDeleteFor2orderOfArr: false })}
              >
                Delete
              </ModalHeader>
              <ModalBody>
                Are You Sure You Want To Delete{" "}
                {this.state.array2OrderDeletefieldName}'s Row Number{" "}
                {this.state.rowIndexOf2Order + 1}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() =>
                    this.handleDeleteOfAll(
                      this.state.rowIndexOf2Order,
                      this.state.array2OrderDeletefieldName,
                      this.state.ArrSectionEditName,
                      false,
                      false,
                      true
                    )
                  }
                >
                  Confirm
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() =>
                    this.setState({ openDeleteFor2orderOfArr: false })
                  }
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={imageProgressModel}
              toggle={() =>
                this.setState({
                  imageProgressModel: !this.state.imageProgressModel,
                })
              }
            >
              <ModalHeader
                toggle={() =>
                  this.setState({
                    imageProgressModel: !this.state.imageProgressModel,
                  })
                }
                close={() => this.setState({ imageProgressModel: false })}
              >
                Image Uploading Progress
              </ModalHeader>
              <ModalBody>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Progress
                    type="circle"
                    percent={this.state.progressOfImgUploading}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                {this.state.progressOfImgUploading == 100 ? (
                  <Button
                    color="secondary"
                    onClick={() => this.setState({ imageProgressModel: false })}
                  >
                    Done
                  </Button>
                ) : (
                  <Button color="secondary" disabled>
                    Done
                  </Button>
                )}
              </ModalFooter>
            </Modal>

            {/* Ends Here */}

            {/* Object Section Helps Ends Here ----------------*/}

            {!this.state.isFileUploading ? (
              <Button
                color="primary"
                style={{ width: "100%" }}
                onClick={() => this.savingEditFormData()}
              >
                Save
              </Button>
            ) : (
              <Button color="primary" disabled style={{ width: "100%" }}>
                Save
              </Button>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(LayoutDetail);
