import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

//Import images
import giftBox from "../../assets/images/giftbox.png";

//i18n
import { withTranslation } from "react-i18next";
import axios from "axios";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation, useNavigate } from "react-router-dom";

import withRouter from "../../../src/components/Common/withRouter";


var parrentPagesArr: any = [];
var roleswork: any = [];

const SidebarContent = (props: any) => {

  var userDataS = localStorage.getItem("authUser");
  var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;

  console.log(userChecks, "asdlasdjiasd");

  const [layout, setLayout] = useState<any[]>([]);
  const [forms, setFormData] = useState<any[]>([]);
  const [allowedRoutes, setAllowedRoutes] = useState<any[]>([]);
  const [allowedSubRoutes, setAllowedSubRoutes] = useState<any[]>([]);
  const [rolesData, setrolesData] = useState<any[]>([]);

  const [userFirmCol, setUsersFirmCollection] = useState<any[]>([]);

  const navigate = useNavigate();
  const ref: any = useRef();

  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    console.log(parent,'skuyweirwe')
    const parent2El = parent.childNodes[1];

    console.log(parent2El, "dsdvshdf")

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        console.log(parent3,'checking parent 3')
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items: any) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();

  const activeMenu = useCallback(() => {

    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  useEffect(() => {
    axios
      .get(`https://api-news.nebrascorp.com/api/pages-get?data=${userChecks.companyColName}`)
      .then((res) => {
        console.log(res, "checking layout data");
        setLayout(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://api-news.nebrascorp.com/api/get-specific-roles?data=${userChecks.role}&orgName=${userChecks.companyColName}`
      )
      .then((res) => {
        console.log(res, "checking roles data in sidebar page");
        setrolesData(res.data.data);
        rolesWork(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios.get(`https://api-news.nebrascorp.com/api/form/:_id?data=${userChecks.companyColName}`).then((res) => {
      console.log(res, "ikhkjsdhfaiodWER3F");
      setFormData(res.data.data);
    });
  }, []);

  // useEffect(()=>{
  //   axios.get("https://api-news.nebrascorp.com/api/get-users").then((res)=>{

  //   console.log(res,'ikhkjsdhfaiodWER3Fasdlaskjd3892293389')
  //   setUsersFirmCollection(res.data.data)

  // })
  // },[])

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  console.log(layout, "jkasdh823y92dis892389");

  const handleClick = (value: any) => {
    // This will navigate to the new route.
    navigate("/layout");
  };
  const handleClickDetail = (value: any, value2: any) => {
    navigate("/layout-detail", {
      replace: true,
      state: {
        data: value,
        pageName: value2,
      },
    });
  };

  const formPageHandler = (name: any, value: any) => {
    navigate("/form-validation", {
      replace: true,
      state: {
        name: name,
        data: value,
      },
    });
  };

  const userFirmRole = (name: any) => {
    navigate("/setting-user", {
      replace: true,
      state: {
        name: name,
      },
    });
  };

  const rolesWork = (data: any) => {
    var arr1: any = [];
    console.log(data, "aweqiowdaskd2943");

    if (data && data[0] !== undefined) {
      console.log(data,'askhdjnckneeu8r928492348')
      data[0].RolesAllocation.map((item: any) => {
        arr1.push(item.Type);
      });

      console.log(arr1,'sakdhauirh8932492urjek')

      setAllowedRoutes(
        arr1.filter((item: any, index: any) => arr1.indexOf(item) === index)
      );
      // rolesWorkSubMenus(data)
    }
  };

  // const rolesWorkSubMenus = (data:any)=> {
  //   var arr2:any=[]

  //   if(data && data[0]!==undefined){

  //     data[0].RolesAllocation.map((item:any)=>{
  //         if(item.Type == 'Pages'){
  //           arr2.push({ Pages: item.Data })
  //         }
  //         else if( item.Type == 'layouts'){
  //           arr2.push({ layouts: item.Data })
  //         }
  //         else if( item.Type == 'Forms' ){
  //           arr2.push({ Forms: item.Data })
  //         }
  //     })
  //     console.log(arr2,'aisduw89qeu923423')

  //   }

  //   setAllowedSubRoutes
  // }

  console.log(layout, "layoutobject");

  var childrenObjArray:any=[]
  var parentsObjArray: any=[]

  for (const objects in layout){

    const obj = layout[objects]

    console.log(obj, "objectsextracted")

    if(!obj.isChildren && obj.visibility == true){

      parentsObjArray.push(obj)

    }else if(obj.isChildren && obj.visibility == true){
      childrenObjArray.push(obj)
    }

  }
  
  console.log(parentsObjArray, "sortedObjects", childrenObjArray)

  const [expand, setExpand] = useState(null);

  const handleExpand = (index:any) => {
    setExpand(index === expand ? null : index);
  };
  
  

  


  // const Pages = allowedRoutes && allowedRoutes.length>0 && allowedRoutes.map((item)=> item === 'Pages'?true:false)[0]
  // const Layouts= allowedRoutes && allowedRoutes.length>0 && allowedRoutes.map((item)=> item === 'layouts'?true:false)[0]
  // const Forms= allowedRoutes && allowedRoutes.length>0 && allowedRoutes.map((item)=> item === 'Forms'?true:false)[0]
  // const Settings= allowedRoutes && allowedRoutes.length>0 && allowedRoutes.map((item)=> item === 'Settings'?true:false)[0]

  // const sortFill = Object.keys(layout).map((items:any)=> layout[items].pageActivity!==undefined && layout[items].pageActivity.length>0 && layout[items].pageActivity.filter((itemd:any)=> parrentPagesArr.push(itemd.childrenPage) ) )

  console.log('asdiajdowqe',allowedRoutes)

  return (
    <React.Fragment>

      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>

        <div id="sidebar-menu">

          <ul className="metismenu list-unstyled" id="side-menu">

            <li className="menu-title">{props.t("Menu")} </li>

            <li><Link to="/dashboard" className=""><Icon name="home" /><span>{props.t("Dashboard")}</span></Link></li>

            {
            allowedRoutes && allowedRoutes.length > 0 && allowedRoutes
            .map((item: any, index: any) => {

              if (item === "Pages") {
                return (
                  <li key={index}>
                    <Link to="/pages">
                      <Icon name="file-text" />
                      {/* <span>{props.t("Apps")}</span> */}
                    <span>Pages</span>
                    </Link>
                  </li>
                );
              }
            })
            }

            {



allowedRoutes && allowedRoutes.length > 0 && allowedRoutes
.map((item: any, index: any) => {

  if (item === "Settings") {
    return (
     <li key={index}>
          <Link to="#" className="has-arrow">
            <Icon name="users" />
            {/* <span>{props.t("Authentication")}</span> */}
            <span>Schemas</span>
          </Link>

          <ul className="sub-menu">
            <li><Link to="/page-schema">Page Schema</Link></li>
            <li><Link to="/form-schema">Form Schema</Link></li>
          </ul>
        </li>
    );
  }
})




            // userChecks && userChecks !== undefined && userChecks.role === "SPA" 
            // ? (
            //   <li>
            //     <Link to="/#" className="has-arrow">
            //       <Icon name="users" />
            //       {/* <span>{props.t("Authentication")}</span> */}
            //       <span>Schemas</span>
            //     </Link>

            //     <ul className="sub-menu">
            //       {/* <li><Link to="/page-schema">Page Schema</Link></li> */}
            //       <li><Link to="/form-schema">Form Schema</Link></li>
            //     </ul>
            //   </li>
            // ) 
            // : null
            }

            {/* // allowedRoutes && allowedRoutes.length>0 && allowedRoutes.map((item:any,index:any)=>{
            // if(item === 'layouts'){
            // return( */}

            
            {/* ------ */}
            
            {/* <li>

              <Link to={"/layout"} className="has-arrow " onClick={() => handleClick("")}>
                <Icon name="file-text" />
                <span>Layouts</span>
              </Link>

              <ul className="sub-menu"> */}


              {/* -------- */} 

                {/* {
                Object.keys(layout).map((item: any, index: any) => {
                  return (
                    <li key={index}>
                      <Link
                        key={index}
                        to="/layout-detail"
                        state={{
                          data: layout[item].ref,
                          pageName: layout[item].pageName,
                        }}
                        onClick={() =>
                          handleClickDetail(
                            layout[item].ref,
                            layout[item].pageName
                          )
                        }
                      >
                        {layout[item].pageName}
                      </Link>
                    </li>
                  ); */}

{
  allowedRoutes && allowedRoutes.length > 0 && allowedRoutes
  .map((item: any, index: any) => {

    if(item === "layouts"){

   
   return(

   
  
              <li key={index}>
                <Link to={"/layout"} className="has-arrow " onClick={() => handleClick("")}> <Icon name="file-text" /> <span>Layouts</span></Link>

                <ul className="sub-menu">
                  {
                  parentsObjArray?.sort((a:any,b:any)=>a?.order - b?.order).map((p:any, index: any)=>{
                    return (
                    <>
                    <li key={index} >
                      <Link key={index} to="/layout-detail"
                      state={{ data: p.ref,pageName: p.pageName,}}
                      onClick={() =>{handleClickDetail( p.ref, p.pageName ); handleExpand(index)}}
                      className={`${p.hasChildren && 'has-arrow'}  `}


                      >
                        {p.pageName}
                      </Link>

                      {
                      expand === index &&
                      <ul 
                        className="sub-menu"
                      >
                      {
                      childrenObjArray?.sort((a:any,b:any)=>a?.order - b?.order).map((c:any, index: any)=>{
                        console.log(c.childrenRef.parentRef,  "dsjbdjsd")
                        console.log(c.pageName, "dsdsnd")
                        if(c.childrenRef.parentRef === p.ref){
                          return ( 
                          <li key={index}>
                            <Link key={index} to="/layout-detail"
                            state={{ data: c.ref, pageName: c.pageName,}}
                            onClick={() => handleClickDetail(  c.ref, c.pageName ) }
                            >
                            {c.pageName}
                            </Link>
                          </li>
                          )
                        }
                      })
                      }
                      </ul>
                      }
                    </li>

                    </>
                    )
                  })
                  }
                </ul>
              </li>
   )

}
})
}

                  {/* // hamza work */}

                 {/* if(layout[item].pageActivity!==undefined && layout[item].pageActivity.length>0){
                 return(
                   <li key={index}>
                   <Link
                    key={index}
                    to='/layout-detail'
                    className='has-arrow'
                    state={{ data: item, values:layout[item] }}
                    onClick={() => handleClickDetail(item,layout[item])}
                   >
                   {item}
                   </Link>
                               <ul className='sub-menu' key={index}>
                                 {
                                   layout[item].pageActivity.length>0 &&
                                   layout[item].pageActivity.map((items:any,indexl:any)=>(
                               <li key={indexl}>
                               <Link
                               key={indexl}
                               to={'/layout-detail'}
                                state={{ data: items.childrenPage, values:layout[items.childrenPage] }}
                                onClick={() => handleClickDetail(items.childrenPage,layout[items.childrenPage])}
                               >
                                 {items.childrenPage}
                         </Link>
                               </li>
                   ))
                   }
                                         </ul>
                                     </li>
                                 )
                                     }else {
                                       if(!parrentPagesArr.includes(item)){
                                       return(
                                         <li key={index}>
                                         <Link
                                           to='/layout-detail'
                                           state={{ data: item, values:layout[item] }}
                                           onClick={() => handleClickDetail(item,layout[item])}
                                         >
                                           {item}
                                         </Link>
                                       </li>
                                       )
                                       }
                                     } */}

                 {/* // end hamza work  */}



                {/* })
                } */}


                {/* ------ */}
{/* 
              </ul>

            </li> */}

            {/* ---------- */}

            {/* // )
    //           }
    //         }) */}

            {allowedRoutes &&
              allowedRoutes.length > 0 &&
              allowedRoutes.map((item: any, index: any) => {
                if (item === "Forms") {
                  return (
                    <li key={index}>
                      <Link to="" className="has-arrow">
                        <Icon name="box" />

                        <span>{props.t("Forms")}</span>
                      </Link>

                      <ul className="sub-menu">
                        <li>
                          <Link to="/form-validation" className="has-arrow">
                            Form Data
                          </Link>

                          <ul className="sub-menu">
                            {Object.keys(forms).map((item: any, index: any) => (
                              <Link
                                to="/form-validation"
                                state={{ name: item, data: forms[item] }}
                                onClick={() =>
                                  formPageHandler(item, forms[item])
                                }
                                key={item}
                              >
                                {forms[item].FormName}
                              </Link>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </li>
                  );
                }
              })}

            {allowedRoutes &&
              allowedRoutes.length > 0 &&
              allowedRoutes.map((item: any, index: any) => {
                if (item === "Settings") {
                  return (
                    <>
                      <li className="menu-title">Administrator Section</li>

                      <>
                        <li>
                          <Link to="#" className="has-arrow ">
                            <Icon name="share-2" />
                            <span>Settings</span>
                          </Link>

                          <ul className="sub-menu">
                            <li>
                              <Link to="/setting-user">Users</Link>
                            </li>

                            <li>
                              <Link to="/setting-organization">
                                Organization
                              </Link>

                              {/* <ul className="sub-menu">
                                    <li>
                                    <Link to="/#">{props.t("Level 2.1")}</Link>
                                    </li>
                                    <li>
                                    <Link to="/#">{props.t("Level 2.2")}</Link>
                                    </li>
                                </ul> */}
                            </li>
                            <li>
                              <Link to="/setting-role">Roles</Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    </>
                  );
                }
              })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
