import React, { Component } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Container,
  Modal, ModalHeader, ModalBody, ModalFooter,


} from "reactstrap";
import { Select, Space } from "antd";
import { Switch } from "antd";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import withRouter from "../../../components/Common/withRouter";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "../../../../src/App.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import TableLoader from "src/components/Common/TableLoader";
var newArray = [];
var formArr = [];
var rowsDD = []
var rowDataVals=[];
var ActivityArrS=[]
export class Roles extends Component {
  constructor(props) {
    super(props);

    this.gridRef = React.createRef();
    this.gridRefTwo = React.createRef();
    this.gridRefThird = React.createRef();

    this.rowDataFunc = this.rowDataFunc.bind(this)
  
    this.state = {
      openRightPane: false,
      openEditPane: false,
      code: "",
      name: "",
      description: "",
      rolesData: [],
      editPaneData: [],
      validation: {
        code: "",
        name: "",
        description: "",
      },
      editCode: "",
      editName: "",
      editDescription: "",
      openGridSelectionPane: false,
      gridSelectedData: "",
      pageCreate: false,
      pageEdit: false,
      pageDelete: false,
      pageView: false,
      pagesActivitySaving: [],
      ComapnyCode:[],
      fomrsActivitySaving: [],
      formData: [],
      fomrView: false,
      layoutData: [],
      selectedType: "",
      pagesData:[],
      itemDataOfDelete: "",
      companyCodes:'',
      typeArr:'',
      rowIndexs:0,
      dataArr:[],
      ActivityArr:[],
      rowsDataArr:[],
      dataGrids:[],
      types:"",
      rowData: this.getInitialRowData(),


      loading: true,

      loaderRows:[1,2,3 ],
      loaderCol: [1,2,3,4,5,6],

    };
  }
  componentDidMount() {
    var userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;
  
   

    axios.get("https://api-news.nebrascorp.com/api/get-organization").then((res)=>{
      console.log(res.data.data,'sakdj389ur2092r')
      if( res.data.data ){
       
        this.setState({

          ComapnyCode:res.data.data

        })

      
      }
    })

    // axios.get("https://api-news.nebrascorp.com/api/get-roles").then((res) => {
    //   console.log(res.data.data, "sakdj389ur2092r");
    //   this.setState({
    //     rolesData: res.data.data,
    //     loading: false
    //   });
    // });

    
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.itemDataOfDelete !== prevState.itemDataOfDelete) {
    //  const obj = {
    //    ...this.state.itemDataOfDelete,
    //  };

     const obj= this.state.itemDataOfDelete
   
     console.log(obj, "ssjhdsjkdhsjdhsjd")
   
   
    //  axios.post(
    //    "https://api-news.nebrascorp.com/api/delete-role",
    //    {value: obj}
    //  );

     axios.post("https://api-news.nebrascorp.com/api/delete-role", {value: obj}).then((res)=>{

     console.log(res,'asdwur2983ui')
     window.location.reload();
   
   
     }).catch((err)=>{
       console.log(err)
     })
    }
   }

   getInitialRowData = () => {
    var rowsData=[]
    for(let i=0; i<15 ; i++){
      rowsData.push({id:i})
    }
    return rowsData
   }

  submitPagesForm = () => {
    var userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;
    const { companyCodes } = this.state;
    const dataofGrid = []
    
    const { code, name, description } = this.state;

    let isValid = true;
    const updatedvalidation = {
      code: "",
      name: "",
      description: "",
    };

    if (!code || !code.trim()) {
      updatedvalidation["code"] = "Code is Required*";
      isValid = false;
    }
    if (!name || !name.trim()) {
      updatedvalidation["name"] = "Name is Required*";
      isValid = false;
    }
    if (!description || !description.trim()) {
      updatedvalidation["description"] = "Description is Required*";
      isValid = false;
    }

    if (!isValid) {
      this.setState({ validation: updatedvalidation });
      console.log(updatedvalidation, "abcdefghikl");
      return;
    } else {

      this.gridRef.current.api.forEachNode(node => {
        console.log(node,'iuweqw98u29ueoiweq');
        if(node.data && node.data.id!==undefined){
          console.log('akjsdhsa')
        }else{
          dataofGrid.push(node.data)
        }
        
      });
      console.log(dataofGrid,'askjdh9eu82432')



      const Obj = {
        code: code,
        name: name,
        description: description,
        RolesAllocation: dataofGrid ? dataofGrid: []
      };
      console.log(Obj, "objectUser");

      axios.post("https://api-news.nebrascorp.com/api/create-roles", { Obj, orgName: companyCodes });

      window.location.reload();
    }

    // axios.post("https://api-news.nebrascorp.com/api/create-user",{Obj});

    // window.location.reload();
  };

  submitEditForm = () => {

   
   
    const { editCode, editName, editDescription, editPaneData } = this.state;

    console.log(editCode, "hamza", editName, "alksdj", editDescription);

    const Obj = {};

    Obj[`${editPaneData.name.replaceAll(" ", "")}`] = {
      code: editCode && editCode !== "" ? editCode : editPaneData.code,
      name: editPaneData.name,
      description:
        editDescription && editDescription !== ""
          ? editDescription
          : editPaneData.description,
    };





    // axios
    //   .post("https://api-news.nebrascorp.com/api/update-Roles", { Obj })
    //   .then((res) => {
    //     window.location.reload();
    //   });

    this.setState({
      editCode: "",
      editName: "",
      editDescription: "",
      editPaneData: [],
      openEditPane: false,
    });
  };
  onGridReady(params) {
    console.log(params, "askdajsld");
    this.gridRef = params.api;
    params.api.sizeColumnsToFit();
    console.log(this.gridRef);
  }
  onGridReadyTwo(params) {
    this.gridRefTwo = params.api;
    params.api.sizeColumnsToFit();
  }

  onGridReadyThird(params) {
    this.gridRefThird = params.api;
    params.api.sizeColumnsToFit();
  }
  savePagesGrid = (activityName, status, index) => {
    newArray[index] = { [`${activityName}`]: status };

    this.setState({
      pagesActivitySaving: newArray,
    });
    console.log(this.state.pagesActivitySaving, "asduiqw98e2u3e9q");
  };

  saveFormGrid = (activityName, status, index) => {
    console.log(activityName, "skaduoqwiuqo94u01293", status, index);

    formArr[index] = { [`${activityName}`]: status };

    this.setState({
      fomrsActivitySaving: formArr,
    });
    console.log(this.state.pagesActivitySaving, "asduiqw98e2u3e9q");
  };

  createNewRowData() {
    const newData = {
      Type: 'Toyota ',
     
    };
    return newData;
  }
  

  rowDataFunc = (typeName) => {

    


    this.gridRef.current.api.applyTransaction({add:[{"sno":"1",  "Type" : typeName, "Data":"jasadk", "Activity":"alskdjasld"  }],addIndex:0})
    
    // console.log(this.gridRef.current.api,'asdhioqwue9u09213423')
}
  
setRowsForGrids = (data,type) => {
  const { pagesData } = this.state;
  var ss = [];
  Object.keys(data).map((items,index)=>{
    ss.push({
       sno:index,
       Pages: type == 'pages' ? pagesData[items].pageName : type == 'layout'?  items : type == 'form' ? data[items].FormName : items
     })
   })
   return ss;
}








savingRowsData = (datas,type,colNam) => {

  if(type === 'Type'){

      const objs = {
        sno:"#",
        Type: datas
      }

    rowDataVals.push(objs)
    console.log(rowDataVals,'uiwqye892e')
  }
  else if(type === 'Data'){

    if(Array.isArray(datas) && rowDataVals.length>0){
      const findss = rowDataVals.filter((item)=> { return item.Type == colNam ? item.Type : null} )
     console.log(findss,'asdiwue923843',datas)
      datas.map((item,index)=>{

        rowsDD.push({
          sno:"#",
          Type: findss && findss[0]!==undefined && findss[0].Type,
          Data: item
        })


      })

      this.setState({
        dataGrids:[],
        types:''
      })

    }

    
    console.log('sadkjhasidoqwiou029312',rowsDD)

    rowsDD && rowsDD.length>0 &&
    rowsDD.filter((item)=> item.Type == colNam).map((items,index)=>{
      // this.gridRef.current.api.applyTransaction({add:[{"sno":"#","Type":items.Type,"Data":items.Data }],addIndex:index})
      this.gridRef.current.api.applyTransaction({add:[{"sno":"#","Type":items.Type, "Data":items.Data }],addIndex:index})
   
    })

  }
 
};

handleActivity = (validity,actName,actType) => {

  if(validity){

    ActivityArrS.push(actType)
  }



  // var itemsToUpdate = [];
  // this.gridRef.current.api.forEachNodeAfterFilterAndSort(function(rowNode, index) {
  //     if (!rowNode.selected) {
  //       return;
  //     }



  //     console.log(rowNode,'askdasiouqw9u9232')
  //     var data = rowNode.data;
  //     data[`${colName}`] = datas;
        
    
  //     itemsToUpdate.push(data);
  //   });

  //   var res = this.gridRef.current.api.updateRowData({ update: itemsToUpdate });
}


submitActivity = () => {



  var itemsToUpdate = [];
  this.gridRef.current.api.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      if (!rowNode.selected) {
        return;
      }



      console.log(rowNode,'askdasiouqw9u9232')
      var data = rowNode.data;
      data[`Activity`] = ActivityArrS && ActivityArrS.length>0 && ActivityArrS.map((item)=> item)
        
    
      itemsToUpdate.push(data);
    });

    var res = this.gridRef.current.api.updateRowData({ update: itemsToUpdate });
    this.setState({
      pageCreate:false,
      pageDelete:false,
      pageEdit:false,
      pageView:false
    },()=> ActivityArrS=[])
}



// console.log(data,'ioewu9349032',type)
//   this.setState(prevState => ({
//     rowsDataArr: [...prevState.rowsDataArr, {[`${type}`]:data}]
//   }),()=> console.log(this.state.rowsDataArr,'asjkdhoiqweuqwou39223') )
  

//   this.gridRef.current.api.applyTransaction({add:[{"sno":"#",  "Type" : data, "Data":'sakdssad', Activity:"sakdjuiwqe" }],addIndex:0})
//     console.log(data,'asdiqwueqiu98324',type)





// savingTypesData = (data,type) => {
//   console.log(this.gridRef.current,'asiduy8942934iqwoeqw')
  


//   this.setState({
   
//     typeArr:  data

//   },()=>{
//     // this.gridRef.current.api.setRowData([{"sno":"#",  "Type" : `${this.state.typeArr}` }])
//   })

  
    
// }

savingDataArr = (data,type) => {
  this.setState({
   
    dataArr: [...this.state.dataArr, data]

  },()=>{
    this.gridRef.current.api.applyTransaction({add:[{"Data":"jasadk", "Activity":"alskdjasld"  }],addIndex:0})
  
    
  })
}


// savingRowsActivity = (data,type,cat) => {

//   this.setState({
   
//     ActivityArr: [...this.state.ActivityArr, {[`${cat}`]:data}]

//   },()=>{
//     this.gridRef.current.api.setRowData([{"sno":"#",  "Type" : `${this.state.typeArr}`, "Data" : `${this.state.dataArr.map((item)=>item)}`, "Activity": `${this.state.ActivityArr.map((item)=>Object.keys(item).map((items)=>items))}` }])
 
//   })

// }

toggle = (data) =>{
  this.setState({
    modal: !this.state.modal,
    modalName: data
  })
}

getRoles = (value) => {
  console.log(value,'checking hamza values here frommm')
  this.setState({ coCode: value })

  axios.get(`https://api-news.nebrascorp.com/api/get-roles?orgName=${value}`).then((res) => {
      console.log(res.data.data, "sakdj389ur2092r");
      this.setState({
        rolesData: res.data.data,
        loading: false
      });
    });



}

setGridDatas = (value) => {

  this.setState({
    companyCodes : value
  })


  axios.get(`https://api-news.nebrascorp.com/api/pages-get?data=${value}`).then((res) => {
      console.log(res, "salkjdaoidwio983");
      this.setState({ pagesData: res.data.data });
    });

    axios.get(`https://api-news.nebrascorp.com/api/form/:_id?data=${value}`).then((res) => {
      console.log(res, "ikhkjsdhfaiodWER3F");
      this.setState({ formData: res.data.data });
    });

    axios.get(`https://api-news.nebrascorp.com/api/layout?data=${value}`)
      .then((res) => {
        console.log(res, "checking layout data");
        this.setState({ layoutData: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });



}

  render() {
    const { rolesData,companyCodes, editPaneData, gridSelectedData,ComapnyCode, formData, layoutData,selectedType,pagesData,rowData } =
      this.state;
    console.log(formData, "238u49824823rjsaikd");
   
    var columnDefs = [
      { headerName: "S.No", field: "sno",width: 100},
      { headerName: "Type", field: "Type" },
      { headerName: "Data", field: "Data" },
      { headerName: "Activity", field: "Activity" },
      { headerName: "id", field: "id" , hide: true },
    ];

    

   
     

     
    console.log(editPaneData, "askdha98diqwido3wd");

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Settings" breadcrumbItem="Roles" />

            <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Select Company
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='exampleSelect'
                              name='select'
                              type='select'
                              // placeholder='Enter Role Of User'
                              onChange={e =>{
                               
                                this.getRoles(e.target.value)
                              
                              }
                              }
                            >
                              <option disabled selected hidden>
                                Select Company
                              </option>

                              {
                              // ComapnyCode && Object.keys(ComapnyCode).map((items ,index )=>(
                              //   <option value={`${ComapnyCode[items].name.replaceAll(" ","")}`} key={index} >{ComapnyCode[items].name}</option>
                              // ))
                              ComapnyCode!==undefined && ComapnyCode.length>0 && ComapnyCode.map((item,index)=>{
                                return(

                                  Object.keys(item).map((items)=>{
                                      return(

 <option value={item[items].shortName} key={index} >{item[items].name}</option>
                                  )
                                  
                                })
                                )

                              })
                           }
                             
                            </Input>
                          </Col>
                        </FormGroup>



            <Button
              color="primary"
              style={{ float: "right" }}
              onClick={() => this.setState({ openRightPane: true })}
            >
              Create Role
            </Button>
            <br />
            <br />
            <br />

            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Role DATA</h4>

                    {/* <p className="card-title-desc">
                      Use one of two modifier className to make{" "}
                      <code>&lt;thead&gt;</code>s appear light or dark gray.
                    </p> */}
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">


                      {
                        !this.state.loading  ?



                      


                      <Table className="table mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>S.No</th>

                            <th> Role Code </th>
                            <th> Role Name </th>
                            <th> Role Description </th>
                            <th> Edit </th>
                            <th> Delete </th>
                          </tr>
                        </thead>

                        <tbody>
                          {Object.keys(rolesData).map((item, index) => {
                            console.log("askdjhasdasd", item);
                           
                            return ( 
                              <tr key={index + 1}>
                                <th scope="row">{index + 1}</th>

                                {Object.keys(rolesData[item]).map(
                                  (items, indexs) => {
                                    console.log(
                                      items,
                                      "asdiuy3289y2i3ude",
                                      index
                                    );
                                    if(!Array.isArray(rolesData[item][items])){
                                    return (
                                      <td key={indexs}>
                                        {rolesData[item][items]}
                                      </td>
                                    );
                                    }
                                  }
                                )} 


                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.setState({
                                      openEditPane: true,
                                      editPaneData: rolesData[item],
                                    })
                                  }
                                >
                                  <EditOutlined />
                                </td>
                                <td   style={{ cursor: "pointer" }}  onClick={() => this.toggle(rolesData[item].name)}>
                                  < DeleteOutlined />
                                </td> 

                                <Modal isOpen={this.state.modal} toggle={ ()=>this.setState({
                                  modal: !this.state.modal,
                                  // modalName: data,
                                })} >
                          <ModalHeader toggle={()=>this.setState({
                            modal: !this.state.modal,
                            // modalName: data,
                          })}>Delete</ModalHeader>
                          <ModalBody>
                           Are You sure You Want to Delete {this.state.modalName} ?
                          </ModalBody>
                          <ModalFooter>
                            <Button color="primary"  onClick={()=> this.setState({ itemDataOfDelete: item, modal:false })}>
                              Confirm
                            </Button>{' '}
                            <Button color="secondary" onClick={ ()=>this.setState({
                                modal: !this.state.modal,
                                // modalName: data,
                              })}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal> 

                            </tr>
                            );
                                
                          })}
                        </tbody> 
                      </Table>

                      :

                      <TableLoader rows={this.state.loaderRows} col={this.state.loaderCol} />

                                              
                      }


                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <Offcanvas
            fade={false}
            isOpen={this.state.openRightPane}
            style={{ width: "80%" }}
            scrollable
            direction="end"
            toggle={() => this.setState({ openRightPane: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ openRightPane: false })}
            >
              Role
            </OffcanvasHeader>

            <OffcanvasBody>
              <Container>
                <Form>
                  <Row>
                    <Col
                      md={6}
                      xs={{
                        offset: 2,
                      }}
                    >
                        <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Select Company
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='exampleSelect'
                              name='select'
                              type='select'
                              // placeholder='Enter Role Of User'
                              onChange={e =>
                               this.setGridDatas(e.target.value)
                              }
                            >
                              <option disabled selected hidden>
                                Select Company
                              </option>

                              {
                              // ComapnyCode && Object.keys(ComapnyCode).map((items ,index )=>(
                              //   <option value={`${ComapnyCode[items].name.replaceAll(" ","")}`} key={index} >{ComapnyCode[items].name}</option>
                              // ))
                              ComapnyCode && ComapnyCode.length>0 && ComapnyCode.map((item,index)=>{
                                return(

                                  Object.keys(item).map((items)=>{
                                      return(

 <option value={item[items].shortName} key={index} >{item[items].name}</option>
                                  )
                                  
                                })
                                )

                              })
                           }
                             
                            </Input>
                          </Col>
                        </FormGroup>


                      <FormGroup row>
                        <Label sm={2} for="examplePassword" style={{textAlign: "right", textTransform: "capitalize"}}>Code</Label >
                        <Col sm={10}>
                        <Input
                          id="examplePassword"
                          name="id"
                          placeholder="Enter Code"
                          type="text"
                          onChange={(e) =>
                            this.setState({ code: e.target.value })
                          }
                        />
                        {this.state.validation["code"] && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {this.state.validation["code"]}
                          </p>
                        )}
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label  sm={2} for="examplePassword" style={{textAlign: "right", textTransform: "capitalize"}}>Name</Label>
                        <Col sm={10}>
                        <Input
                          id="examplePassword"
                          name="id"
                          placeholder="Enter Name"
                          type="text"
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                          />
                        {this.state.validation["name"] && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {this.state.validation["name"]}
                          </p>
                        )}
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label sm={2} for="examplePassword" style={{textAlign: "right", textTransform: "capitalize"}}>Description</Label>
                        <Col sm={10}>
                        <Input
                          id="examplePassword"
                          name="id"
                          placeholder="Enter Description"
                          type="text"
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                        />
                        {this.state.validation["description"] && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {this.state.validation["description"]}
                          </p>
                        )}
                        </Col>

                      </FormGroup>

                      
                    </Col>
                  </Row>
                </Form>
              </Container>
              <Label for="examplePassword">
                        User Access Rights Allocation
                      </Label>
                    
                      <div
                        className="ag-theme-balham"
                        style={{ height: 700, width: "100%" }}
                      >
                        <AgGridReact
                          ref={this.gridRef}
                          rowData={rowData}
                          animateRows={true}
                          columnDefs={columnDefs}
                          overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                          }
                          rowHeight={55}
                          style={{
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                          }}
                          rowSelection={"single"}
                          onGridReady={this.onGridReady}
                          onCellDoubleClicked={(e) => {
                            console.log("hamzajakshdi3y432",e)
                            if (e.colDef.field == "sno") {
                              null;
                            } else {
                              this.setState({
                                openGridSelectionPane: true,
                                gridSelectedData: e.colDef.field,
                              });
                            }
                          }}
                          //  onCellClicked={(e)=>{ this.getUsersOfOrg(e.node.data)

                          //  }}
                        ></AgGridReact>



                      </div>

                      <br />
                      {
                        companyCodes && companyCodes!== '' ?
                      
                      <Button
                        color="success"
                        style={{ float: "right" }}
                        onClick={() => this.submitPagesForm()}
                      >
                        Submit Form
                      </Button>
  :
                        <p style={{color:'red'}}>Please Select Company</p>
  }

            </OffcanvasBody>
          </Offcanvas>





        {/* Roles Edit Here .... */}
        
          <Offcanvas
            fade={false}
            isOpen={this.state.openEditPane}
            style={{ width: "80%" }}
            scrollable
            direction="start"
            toggle={() => this.setState({ openEditPane: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ openEditPane: false })}
            >
              {/* Role 
              <EditOutlined /> */}
            </OffcanvasHeader>

            <OffcanvasBody>
              <Card>
                <CardBody>
                  <h4>Role Edit</h4>
                  <br />

              <Container>
                <Form>
                  <Row>
                    {/* <Col
                      md={6}
                      xs={{
                        offset: 2,
                      }}
                    > */}
                      <FormGroup row>
                        <Label sm={3} for="examplePassword" style={{textAlign: "right", textTransform: "capitalize"}}>Code</Label>
                        <Col sm={6}>
                        <Input
                          id="examplePassword"
                          name="id"
                          placeholder={editPaneData.code}
                          type="text"
                          onChange={(e) =>
                            this.setState({ editCode: e.target.value })
                          }
                        />
                        {this.state.validation["code"] && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {this.state.validation["code"]}
                          </p>
                        )}
                        </Col>
                      </FormGroup>

                      <FormGroup  row>
                        <Label sm={3} for="examplePassword" style={{textAlign: "right", textTransform: "capitalize"}}>
                          Name{" "}
                          <span style={{ color: "red" }}>
                            (Non-Editable Field)
                          </span>{" "}
                        </Label>
                        <Col sm={6}>
                        <Input
                          id="examplePassword"
                          disabled
                          name="id"
                          placeholder={editPaneData.name}
                          type="text"
                        />
                        {this.state.validation["name"] && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {this.state.validation["name"]}
                          </p>
                        )}
                        </Col>

                      </FormGroup>

                      <FormGroup row>
                        <Label sm={3} for="examplePassword" style={{textAlign: "right", textTransform: "capitalize"}}>Description</Label>
                        <Col sm={6}>
                        <Input
                          id="examplePassword"
                          name="id"
                          placeholder={editPaneData.description}
                          type="text"
                          onChange={(e) =>
                            this.setState({ editDescription: e.target.value })
                          }
                        />
                        {this.state.validation["description"] && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {this.state.validation["description"]}
                          </p>
                        )}
                        </Col>

                      </FormGroup>

                     

                    {/* </Col> */}
                  </Row>
                  <Row>
                  <div
                        className="ag-theme-balham"
                        style={{ height: 700, width: "100%" }}
                      >
                        <AgGridReact
                          ref={this.gridRef}
                          rowData={editPaneData.RolesAllocation}
                          animateRows={true}
                          columnDefs={columnDefs}
                          overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                          }
                          rowHeight={55}
                          style={{
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                          }}
                          rowSelection={"single"}
                          onGridReady={this.onGridReady}
                          onCellDoubleClicked={(e) => {
                            console.log("hamzajakshdi3y432",e)
                            if (e.colDef.field == "sno") {
                              null;
                            } else {
                              this.setState({
                                openGridSelectionPane: true,
                                gridSelectedData: e.colDef.field,
                              });
                            }
                          }}
                          //  onCellClicked={(e)=>{ this.getUsersOfOrg(e.node.data)

                          //  }}
                        ></AgGridReact>
                        </div>
                  </Row>
                </Form>
              </Container>
          
       
          <Col sm={12} style={{textAlign: "right"}}>
                      <Button
                        color="danger"
                        style={{ marginTop: 10 }}
                        onClick={() => this.submitEditForm()}
                      >
                        Edit Changes
                      </Button>
                      </Col>
                      </CardBody>
        </Card>
          
            </OffcanvasBody>
          </Offcanvas>


{/* Ends here ----- */}






          <Offcanvas
            fade={false}
            isOpen={this.state.openGridSelectionPane}
            style={{ width: "50%" }}
            scrollable
            direction="start"
            toggle={() => this.setState({ openGridSelectionPane: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ openGridSelectionPane: false })}
            >
              Access Rights Allocation
            </OffcanvasHeader>
           
            <OffcanvasBody>
              <Container>
                <Form>
                  <Row>
                    {gridSelectedData && gridSelectedData == "Type" ? (
                    <>
                      
                       <div
                       className="ag-theme-balham"
                       style={{ height: 300, width: "100%"}}
                     >

                     
                       <AgGridReact
                         ref={this.gridRefTwo}
                        //  rowData={[{ sno: "01" }]}
                        rowData={[
                          {sno: "01", Type: "Pages", TypeCode: "PGS"},
                          {sno: "03", Type: "layouts", TypeCode: "LYT" },
                          {sno: "04", Type: "Forms", TypeCode: "FMS" },
                          {sno: "05", Type: "Settings", TypeCode: "STNG" },
                          
                        ]}

                         columnDefs={[
                          { headerName: "S.No", field: "sno" },
                          { headerName: "Type", field: "Type" },
                          { headerName: "Type Code", field: "TypeCode" },

                        ]
                        }
                         overlayLoadingTemplate={
                           '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                         }
                         rowHeight={55}
                         style={{
                           width: "100%",
                           height: "100%",
                           overflow: "hidden",
                         }}
                          rowSelection={"single"}

                           onGridReady={this.onGridReadyTwo}
                           onCellValueChanged={(data) => {
                            console.log(data,'askjdhasudhwquiey123')
                           }}
                          onRowClicked={(e) => {
                          console.log(e, "98wq7e8q9we798q7isdaisdhsa")
                             this.setState({
                               openGridSelectionPane: false,
                               selectedType: e.data.Type
                             },()=> this.savingRowsData(e.data.Type,'Type',e.rowIndex));

                            //  this.savingRowsData(this.state.selectedType,'Type')
                         }}
                         
                       ></AgGridReact>
                     </div>

                         </>
                    ) : gridSelectedData && gridSelectedData == "Data" ? (
                      <>
                        {/* {formData &&
                          Object.keys(formData).map((items, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                              }}
                            >
                              <Label
                                style={{ fontSize: "15px", marginRight: 10 }}
                              >
                                {formData[items].FormName}
                              </Label>

                              <Switch
                                key={index}
                                style={{ marginTop: 5 }}
                                checked={this.state.fomrView}
                                onChange={() =>
                                  this.setState(
                                    { fomrView: !this.state.fomrView },
                                    () =>
                                      this.saveFormGrid(
                                        formData[items].FormName,
                                        this.state.fomrView,
                                        index
                                      )
                                  )
                                }
                              />
                            </div>
                          ))} */}

                          {

selectedType &&
selectedType == 'Pages'?

<>
{
  
    <div
    className="ag-theme-balham"
    style={{ height: 300, width: "100%"}}
  >

    <AgGridReact
    ref={this.gridRefThird}
   //  rowData={[{ sno: "01" }]}
   rowData={
    pagesData&&
    this.setRowsForGrids(pagesData,'pages')
   }

    columnDefs={[
     { headerName: "S.No", field: "sno" },
     { headerName: "Pages", field: "Pages" },
   

   ]
   }
    overlayLoadingTemplate={
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
    }
    rowHeight={55}
    style={{
      width: "100%",
      height: "100%",
      overflow: "hidden",
    }}
    rowSelection={'multiple'}
    rowMultiSelectWithClick={true}
  

      onGridReady={this.onGridReadyThird}
      onCellValueChanged={(data) => {
       console.log(data,'askjdhasudhwquiey123')
      }}
     onRowClicked={(e) => {
     console.log(e.data.Pages, "shazib")
     this.setState(prevState => ({
      dataGrids : [...prevState.dataGrids,e.data.Pages ],
      types:selectedType
    }))


    }}
    
  ></AgGridReact>

<Button color='primary' onClick={()=> this.setState({openGridSelectionPane:false},()=> this.savingRowsData(this.state.dataGrids,'Data',this.state.types))} >Save</Button>


</div>




  
}





</>
:

selectedType == 'layouts'?
<>
{
  
  <div
  className="ag-theme-balham"
  style={{ height: 300, width: "100%"}}
>

  <AgGridReact
  ref={this.gridRefThird}
 //  rowData={[{ sno: "01" }]}
 rowData={
  layoutData&&
  this.setRowsForGrids(layoutData,'layout')
 }

  columnDefs={[
   { headerName: "S.No", field: "sno" },
   { headerName: "Pages", field: "Pages" },
 

 ]
 }
  overlayLoadingTemplate={
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  }
  rowHeight={55}
  style={{
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }}
  rowSelection={'multiple'}
  rowMultiSelectWithClick={true}

    onGridReady={this.onGridReadyThird}
    onCellValueChanged={(data) => {
     console.log(data,'askjdhasudhwquiey123')
    }}
   onRowClicked={(e) => {
   console.log(e.data.Pages, "shazib")
    this.setState(prevState => ({
      dataGrids : [...prevState.dataGrids,e.data.Pages ],
      types:selectedType
    }))
     


  }}
  
></AgGridReact>
<Button color='primary' onClick={()=> this.setState({openGridSelectionPane:false},()=>  this.savingRowsData(this.state.dataGrids,'Data',this.state.types))} >Save</Button>
</div>





}

</>
:
selectedType == 'Forms' ?

<>
{
  
  <div
  className="ag-theme-balham"
  style={{ height: 300, width: "100%"}}
>

  <AgGridReact
  ref={this.gridRefThird}
 //  rowData={[{ sno: "01" }]}
 rowData={
  formData&&
  this.setRowsForGrids(formData,'form')
 }

  columnDefs={[
   { headerName: "S.No", field: "sno" },
   { headerName: "Pages", field: "Pages" },
 

 ]
 }
  overlayLoadingTemplate={
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  }
  rowHeight={55}
  
  style={{
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }}
  rowSelection={'multiple'}
  rowMultiSelectWithClick={true}

    onGridReady={this.onGridReadyThird}
    onCellValueChanged={(data) => {
     console.log(data,'askjdhasudhwquiey123')
    }}
   onRowClicked={(e) => {
   console.log(e.data.Pages, "shazib")
   this.setState(prevState => ({
    dataGrids : [...prevState.dataGrids,e.data.Pages ],
    types:selectedType
  }))


  }}
  
></AgGridReact>
<Button color='primary' onClick={()=> this.setState({openGridSelectionPane:false},()=> this.savingRowsData(this.state.dataGrids,'Data',this.state.types))} >Save</Button>

</div>





}
</>
:
selectedType == 'Settings' ?

<>

{
  
  <div
  className="ag-theme-balham"
  style={{ height: 300, width: "100%"}}
>

  <AgGridReact
  ref={this.gridRefThird}
 //  rowData={[{ sno: "01" }]}
 rowData={
  [
  {
    sno:1,
    Pages: 'User'
  },
  {
    sno:2,
    Pages: 'Organization'
  },
  {
    sno:3,
    Pages: 'Roles'
  }
]
 }

  columnDefs={[
   { headerName: "S.No", field: "sno" },
   { headerName: "Pages", field: "Pages" },
 

 ]
 }
  overlayLoadingTemplate={
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
  }
  rowHeight={55}
  style={{
    width: "100%",
    height: "100%",
    overflow: "hidden",
  }}
  rowSelection={'multiple'}
  rowMultiSelectWithClick={true}

    onGridReady={this.onGridReadyThird}
    onCellValueChanged={(data) => {
     console.log(data,'askjdhasudhwquiey123')
    }}
   onRowClicked={(e) => {
   console.log(e.data.Pages, "shazib")
   this.setState(prevState => ({
    dataGrids : [...prevState.dataGrids,e.data.Pages ],
    types:selectedType
  }))


  }}
  
></AgGridReact>
<Button color='primary' onClick={()=> this.setState({openGridSelectionPane:false},()=> this.savingRowsData(this.state.dataGrids,'Data',this.state.types))} >Save</Button>


</div>





}

</>
:
null
                          }

                      </>
                    ) : gridSelectedData && gridSelectedData == "Activity" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                          }}
                        >
                          <Label style={{ fontSize: "20px", marginRight: 10 }}>
                            View Rights
                          </Label>
                          <Switch
                            style={{ marginTop: 5 }}
                            checked={this.state.pageView}
                            onChange={() =>
                              this.setState(
                                { pageView: !this.state.pageView },
                                () =>  this.handleActivity(this.state.pageView,'Activity','View')
                                  // this.savePagesGrid(
                                  //   "view",
                                  //   this.state.pageView,
                                  //   0
                                  // )
                              )
                            }
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                          }}
                        >
                          <Label style={{ fontSize: "20px", marginRight: 10 }}>
                            Create Rights
                          </Label>
                          <Switch
                            style={{ marginTop: 5 }}
                            checked={this.state.pageCreate}
                            onChange={() =>
                              this.setState(
                                { pageCreate: !this.state.pageCreate },
                                () => this.handleActivity(this.state.pageCreate,'Activity','Create')

                                  // this.savePagesGrid(
                                  //   "create",
                                  //   this.state.pageCreate,
                                  //   1
                                  // )
                              )
                            }
                          />
                        </div>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                          }}
                        >
                          <Label style={{ fontSize: "20px", marginRight: 10 }}>
                            Edit Rights
                          </Label>
                          <Switch
                            style={{ marginTop: 5 }}
                            checked={this.state.pageEdit}
                            onChange={() =>
                              this.setState(
                                { pageEdit: !this.state.pageEdit },
                                () => this.handleActivity(this.state.pageEdit,'Activity','Edit')
                                 
                              )
                            }
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                          }}
                        >
                          <Label style={{ fontSize: "20px", marginRight: 10 }}>
                            Delete Rights
                          </Label>
                          <Switch
                            style={{ marginTop: 5 }}
                            checked={this.state.pageDelete}
                            onChange={() =>
                              this.setState(
                                { pageDelete: !this.state.pageDelete },
                                () => this.handleActivity(this.state.pageDelete,'Activity','Delete')
                                  
                              )
                            }
                          />
                        </div>

                        <Button color='primary' style={{width:80}} onClick={()=>this.setState({openGridSelectionPane:false},()=>this.submitActivity())} >Save</Button>
                      </>
                    ) : null}
                  </Row>
                </Form>
              </Container>
            </OffcanvasBody>
          </Offcanvas>
        </div>
      </React.Fragment>
    );
  }
}
export default Roles;
