import React, { useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert
} from 'reactstrap'
import { post } from 'src/helpers/api_helper'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import axios from 'axios'

import TableLoader from "../../../components/Common/TableLoader";


// var valArr : any = [];
// var typeLength : any =[];

firebase.initializeApp({
  apiKey: 'AIzaSyC6Qq4wX7cJnKRIqk2zx4QZzLy4kZe7_UA',
  authDomain: 'neb-cms.firebaseapp.com',
  projectId: 'neb-cms',
  storageBucket: 'neb-cms.appspot.com',
  messagingSenderId: '261403062939',
  appId: '1:261403062939:web:9e920cf249263f6671cc3e',
  measurementId: 'G-QSQK36QZR9'
})

// const storage = firebase.storage();

var Valuesss: any = []

const FormSchema = () => {
  const [openRightPane, setRightPane] = useState(false)
  const [openAlertError, setOpenAlertError] = useState(false)
  const [openAlertSucess, setOpenAlertSucess] = useState(false)
  const [formDynamicFieldAdd, setformDynamicFieldAdd] = useState<any[]>([]);
  var userDataS = localStorage.getItem("authUser");
  var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;

  const [keys, setKeys] = useState('')
  const [values, setValues] = useState('')
  const [valueLength, setValueLength] = useState('')
  const [keyRegex, setKeyRegex] = useState('');
  const [formName, setformName] = useState('')

  const [identifier, setIdentifier] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [order, setOrder] = useState<string | number>(0)
  

  const [formDescription, setformDescription] = useState('')
  const [FormsData, setFormsData] = useState<any[]>([])

  const [loading, setloading]= useState(true)

  
const loaderRows=[1,2,3,4]
const loaderCol=[1,2,3]


  //   const RemoveFields = (i:any) => {
  //     let createdobjs = [...formDynamicFieldAdd];
  //     createdobjs.splice(i);
  //     setformDynamicFieldAdd(createdobjs)
  //   }

  const submitFormData = () => {
    console.log('Form Dataaslkdaskld')
  }

  const sortAllValues = () => {
    console.log(keys, values, valueLength, 'sadlkjwidhq8u3')

    Valuesss.push({
      key: keys,
      value: values,
      identifier: identifier,
      placeholder:placeholder,
      order:order,
      fieldLength: valueLength,
      Regex: keyRegex

    })

    setKeys('')
    setValues('')
    setIdentifier('')
    setPlaceholder('')
    setOrder('')
    setValueLength('')
    setformDynamicFieldAdd(Valuesss)
    setKeyRegex('')
  }
  useEffect(() => {
    console.log('aslkdjasd', keys)
  }, [keys])

  useEffect(()=>{
   
    // fetchBlogs();

    axios.get(`https://api-news.nebrascorp.com/api/form/:_id?data=${userChecks.companyColName}`).then((res)=>{

      // console.log(res,'ikhkjsdhfaiodWER3F')
      setFormsData(res.data.data)
      setloading(false)

    })

    // post('https://api-news.nebrascorp.com/api/createFormData',{data:"kjash"});
    
  }, [])

  const fetchBlogs = async () => {

    // const db = firebase.firestore()

    // const response = db
    //   .collection('PIVOT')
    //   .doc('Forms')
    //   .collection('FeedBack-Page-Form')

    // const data = await response.get()

    // data.forEach(item => {
    //   setFormsData([...FormsData, item.data()])
    // })
    
  }

  const saving = () => {
    console.log(formDynamicFieldAdd,'saioude98whfikewnf',formName,formDescription)
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;
   
    const obj : any = {
        FormName: formName,
        FormDescription: formDescription
    };
    formDynamicFieldAdd && formDynamicFieldAdd.length>0 && formDynamicFieldAdd.map((item)=>{
        obj[`${item.key}`] = {
                    valueType: item.value,
                    fieldLength: item.fieldLength,
                    Placeholder: item.placeholder,
                    Order: item.order,
                    Identifier: item.identifier,
                    Regex: item.Regex,
                    ref: formName
        }
    })


 axios.post("https://api-news.nebrascorp.com/api/form",{obj, orgName: userChecks.companyColName})

    console.log(obj, 'askuehy8wuqy2873yrehukwaj')

   


  }
//   fetchBlogs();
console.log(FormsData,'asieu3298rywiofjnaskf')

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumbs title='FORM' breadcrumbItem='FORM SCHEMA' />

          <Button
            color='primary'
            style={{ float: 'right' }}
            onClick={() => setRightPane(true)}
          >
            Create Forms Schema
          </Button>
          <br />
          <br />
          <br />

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <h4 className='card-title'>FORM DATA</h4>
                  {/* <p className="card-title-desc">
                      Use one of two modifier className to make{" "}
                      <code>&lt;thead&gt;</code>s appear light or dark gray.
                    </p> */}
                </CardHeader>
                <CardBody>
                  <div className='table-responsive'>

                    {
                      !loading ?
                    


                    <Table className='table mb-0'>
                      <thead className='table-light'>
                        <tr>
                          <th>#</th>
                          <th>Form Name</th>
                          <th>Form Page</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                       Object.keys(FormsData).map((item:any, index:number) => (

                            <tr key={index}>
                              <th scope='row'>{index + 1}</th>
                              <td>{FormsData[item].FormName}</td>
                              <td>{FormsData[item].FormPage}</td>
                            </tr>

                          ))
                          }
                      </tbody>
                    </Table>

                    
                    :
                    
                    <TableLoader  rows={loaderRows} col={loaderCol}/>
                
                 }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <Offcanvas
          fade={false}
          isOpen={openRightPane}
          style={{ width: '60%' }}
          scrollable
          direction='end'
          toggle={() => setRightPane(false)}
        >
          <OffcanvasHeader toggle={() => setRightPane(false)}>
            {/* Form Schema */}
          </OffcanvasHeader>

          <OffcanvasBody>

            <Card>
              <CardBody>
                <h4>Form Schema</h4>
                <br />
              
            <Container>
              <Row>

              {/* <Col md={10}> */}
                <FormGroup row
                  
                >
                  <Label sm={3} style={{ textAlign: "right", textTransform: "capitalize"}} for='exampleEmail' >Form Name</Label>
                  <Col sm={6}>

                  <Input 
                    id='exampleEmail'
                    name='Fname'
                    placeholder='Enter Form Name'
                    type='text'
                    onChange={e => setformName(e.target.value)}
                    />
                    </Col>
                </FormGroup>
                <FormGroup
                    row
                >
                  <Label sm={3} style={{ textAlign: "right", textTransform: "capitalize"}} for='exampleEmail'>Form Description</Label>
                  <Col sm={6}>
                  <Input
                    id='exampleEmail'
                    name='Fdescription'
                    placeholder='Enter Form Description'
                    type='text'
                    onChange={e => setformDescription(e.target.value)}
                    />
                    </Col>
                </FormGroup>
              {/* </Col> */}
              </Row>

            </Container>
            <Container>
              <Row>
                <Col xl={12} sm={12}>
                  <Card>
                    <CardHeader>
                      <h4 className='card-title'>FORM DATA</h4>
                      {/* <p className="card-title-desc">
                      Use one of two modifier className to make{" "}
                      <code>&lt;thead&gt;</code>s appear light or dark gray.
                    </p> */}
                    </CardHeader>
                    <CardBody>
                      <div className='table-responsive'>
                        <Table className='table mb-0'>
                          <thead className='table-light'>
                            <tr>
                              <th>#</th>
                              <th>Keys</th>
                              <th>Values</th>
                              <th>Field Length</th>
                              <th>Regex</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formDynamicFieldAdd &&
                              formDynamicFieldAdd.length > 0 &&
                              formDynamicFieldAdd.map(
                                (item: any, index: number) => (
                                  <tr key={index}>
                                    <th scope='row'>{index + 1}</th>
                                    <td>{item.key}</td>
                                    <td>{item.value}</td>
                                    <td>{item.fieldLength}</td>
                                    <td>{item.Regex}</td>
                                  </tr>
                                )
                              )}

                            {/* <tr>
                          <th scope='row'>2</th>
                          <td>Jacob</td>
                          <td>Thornton</td>
                          <td>@fat</td>
                        </tr>
                        <tr>
                          <th scope='row'>3</th>
                          <td>Larry</td>
                          <td>the Bird</td>
                          <td>@twitter</td>
                        </tr> */}
                          </tbody>
                        </Table>
                        
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Button
                  color='danger'
                  style={{ float: 'right' }}
                  onClick={() => saving()}
                >
                  Save Form
                </Button>
            </Container>
            <br />
            <Container style={{marginTop:10}}>
              <Form>
                <Row>
                  {/* <Col md={4}> */}
                    <h4>Schemma</h4>
                    <br />
                    <FormGroup row>
                      <Label sm={3} style={{ textAlign: "right", textTransform: "capitalize"}} for='exampleEmail'>Key</Label>
                      <Col sm={7}>
                      <Input
                        id='exampleEmail'
                        name='Key'
                        placeholder='Enter Key'
                        type='text'
                        value={keys}
                        onChange={e => setKeys(e.target.value)}
                        />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label sm={3} style={{ textAlign: "right", textTransform: "capitalize"}} for='examplePassword'>Value</Label>
                      <Col sm={7}>
                      <Input
                        id='exampleSelect'
                        name='select'
                        type='select'
                        value={values}
                        onChange={e => setValues(e.target.value)}
                      >
                        <option>Select a Value</option>
                        <option>text</option>
                        <option>Number</option>
                        <option>Dropdown</option>
                        <option>file</option>
                        <option>Boolean</option>
                        <option>Date</option>
                      </Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label sm={3} style={{ textAlign: "right", textTransform: "capitalize"}} for='examplePassword'>Identifier</Label>
                      <Col sm={7}>
                      <Input
                        id='examplePassword'
                        name='Field Length'
                        placeholder='Enter Field Identifier'
                        type='text'
                        value={identifier}
                        onChange={e => setIdentifier(e.target.value)}
                      />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label sm={3} style={{ textAlign: "right", textTransform: "capitalize"}} for='examplePassword'>Order</Label>
                      <Col sm={7}>
                      <Input
                        id='examplePassword'
                        name='Field Length'
                        placeholder='Enter Field Order'
                        type='number'
                        value={order}
                        onChange={e => setOrder(parseInt(e.target.value))}
                        />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label sm={3} style={{ textAlign: "right", textTransform: "capitalize"}} for='examplePassword'>Placeholder</Label>
                      <Col sm={7}>
                      <Input
                        id='examplePassword'
                        name='Field Length'
                        placeholder='Enter Field Placeholder'
                        type='text'
                        value={placeholder}
                        onChange={e => setPlaceholder(e.target.value)}
                        />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label sm={3} style={{ textAlign: "right", textTransform: "capitalize"}} for='examplePassword'>Field Length</Label>
                      <Col sm={7}>
                      <Input
                        id='examplePassword'
                        name='Field Length'
                        placeholder='Enter Field Length'
                        type='text'
                        value={valueLength}
                        onChange={e => setValueLength(e.target.value)}
                        />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label sm={3} style={{ textAlign: "right", textTransform: "capitalize"}} for='examplePassword'>Regex</Label>
                      <Col sm={7}>
                      <Input
                        id='examplePassword'
                        name='Field Length'
                        placeholder='Enter Field Regex'
                        type='text'
                        value={keyRegex}
                        onChange={e => setKeyRegex(e.target.value)}
                        />
                        </Col>
                    </FormGroup>

                  {/* </Col> */}

                <Col sm={10} style={{textAlign: "right"}}>

                <Button
                  color='primary'
                  style={{ marginRight: 10 }}
                  onClick={() => sortAllValues()}
                  >
                  Add Values
                </Button>
                  </Col>
                    </Row>
              </Form>
            </Container>
            

            </CardBody>
            </Card>
          </OffcanvasBody>

          <Alert color='danger' isOpen={openAlertError}>
            Error: All Fields Are Mandatory!
          </Alert>
          <Alert color='primary' isOpen={openAlertSucess}>
            Sucess: Pages Form Submit Sucessfully!
          </Alert>
        </Offcanvas>
      </div>
    </React.Fragment>
  )
}

export default FormSchema

{
  /* <Select.Option value="String">String</Select.Option>
<Select.Option value="Number">Number</Select.Option>
<Select.Option value="Date">Date</Select.Option>
<Select.Option value="Boolean">Boolean</Select.Option>
<Select.Option value="Buffer">Buffer</Select.Option>
<Select.Option value="ObjectId">ObjectId</Select.Option>

<Select.Option value="Mixed">Mixed</Select.Option>

<Select.Option value="Array">Array</Select.Option> */
}
