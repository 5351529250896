import React, { useState, useEffect, Component } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Container,
} from "reactstrap";
import _ from "lodash";
import { post } from "src/helpers/api_helper";
import RichTextEditor from "react-rte";
import axios from "axios";

import { ConfigProvider, Skeleton } from "antd";

// import { message, Upload } from 'antd'
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import withRouter from "../../../src/components/Common/withRouter";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
firebase.initializeApp({
  apiKey: "AIzaSyC6Qq4wX7cJnKRIqk2zx4QZzLy4kZe7_UA",
  authDomain: "neb-cms.firebaseapp.com",
  projectId: "neb-cms",
  storageBucket: "neb-cms.appspot.com",
  messagingSenderId: "261403062939",
  appId: "1:261403062939:web:9e920cf249263f6671cc3e",
  measurementId: "G-QSQK36QZR9",
});

// const uploadButton = (
//   <div>
//     <PlusOutlined />
//     <div style={{ marginTop: 8 }}>Upload</div>
//   </div>
// )
var incrementOnSave = 0;
var pushingMulObjData: any = [];
var plainObjS: any = {};
var plainObjectSaving: any = [];
var innerArraysData: any = {};
var plainInnerArraySaving: any = [];
var settingArraysKeys: any = {};
var savingArrayss: any = [];
var plainInnerObjectSaving: any = [];
var finalArrayOfObjects: any = [];
var innerObjectsOfArr: any = {};
var order2Array: any = {};
var order2ArrayDatasSAving: any = [];
var savingMultipleInnerObjs: any = [];
var sk: any = {};
var dataTables: any = [];
var objArr: any = [];
var objectsSaving: any = [];
var kklm: any = [];
var objs: any = {};

class Layouts extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      SchemaData: [],
      pageData: "",
      textHeadingInput: [],
      InputFieldsDataOnly: {},
      fileList: [],
      ImageInputControl: [],
      BooleanInputControl: [],
      numberInputCtrl: [],
      rteValue: RichTextEditor.createEmptyValue(),
      numberFieldsData: {},
      booleandataSaving: {},
      imageOnlyDataSave: {},
      selectedObJKEY: {},
      openPaneForArrayOfObjectSection: false,
      innerArrayData: [],
      innerArrayName: "",
      plainObjSaving: [],
      innerArrayDataMaintain: {},
      InnerArraySavingss: {},
      arrayInputsOnly: {},
      openPaneForArraySection: false,
      arraySectionData: [],
      arrayName: "",
      arrayIndexInParentBody: 0,
      arraysObjectSavings: [],
      AllArraysOfObj: [],
      InnerObjsSavingss: {},
      presistDataForTable: {},
      innerObjectDataMaintain: {},
      open2orderArray: false,
      order2arrayData: [],
      order2parrentArrayName: "",
      order2ArrayObj: {},
      order2ArrayMainSavingss: [],
      pagesData: null,
      objectNameRec: "",
      prevArrName: "",
      create: false,
    };
  }
  componentDidMount(): void {
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;
  
    // post('https://api-news.nebrascorp.com/api/collection',{datas:"askldjaiewho"});
    console.log("sadjalskd");
    axios.get(`https://api-news.nebrascorp.com/api/collection?data=${userChecks.companyColName}`).then((res) => {
      console.log(res, "salkjdaoidwio983");

      this.setState(
        {
          pagesData: res.data.data,
        },
        () => console.log(this.state.pagesData, "asjkhdiuwqhriu")
      );
    });
    this.loadImages();
  }

  loadImages = async () => {
    const promises = await this.fetchImages();

    let points = promises[0].docs.map((doc) => {
      return doc.data();
    });

    // const collectionName = points.path.split("/").slice(0, -1).join("/");

    console.log(points, "getting points data in shcema");

    this.setState({
      SchemaData: points,
    });
  };

  fetchImages = async () => {
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;
   
    const db = firebase.firestore();

    const points = db
      .collection(`${userChecks.companyColName}`)
      .doc("Schemas")
      .collection("AboutUs-Schema")
      .get();

    return Promise.all([points]);
  };

  inputOnChangeValues = (value: any, index: number) => {
    var obj = {};
    const getDatas = [...this.state.textHeadingInput];
    getDatas[index] = value;

    this.setState(
      {
        textHeadingInput: getDatas,
      },
      () => {
        console.log("cjka");
      }
    );
  };

  BooleanDataInput = (value: any, index: number) => {
    const getDatas = [...this.state.BooleanInputControl];
    getDatas[index] = value;

    this.setState({
      BooleanInputControl: getDatas,
    });
  };
  numberInputControl = (value: any, index: number) => {
    const getDatas = [...this.state.numberInputCtrl];
    getDatas[index] = value.target.value;

    this.setState({
      numberInputCtrl: getDatas,
    });
  };

  savingNumberInputObject = () => {
    const { numberInputCtrl, SchemaData } = this.state;
    let objs: any = {};
    let gettingKeysOfTextInputs =
      SchemaData && SchemaData.length > 0 && SchemaData[0];

    Object.keys(gettingKeysOfTextInputs).map((item, index) => {
      var checkTypeOf = typeof gettingKeysOfTextInputs[item];
      if (checkTypeOf == "string") {
        if (gettingKeysOfTextInputs[item] == "number") {
          objs[item] = numberInputCtrl[index];
        }
      }
    });

    console.log(objs, "checking02981390213ihidlkadklas");

    this.setState({
      numberFieldsData: objs,
    });
  };

  savingBooleanObjects = () => {
    const { BooleanInputControl, SchemaData } = this.state;
    let objs: any = {};
    let gettingKeysOfTextInputs =
      SchemaData && SchemaData.length > 0 && SchemaData[0];

    Object.keys(gettingKeysOfTextInputs).map((item, index) => {
      var checkTypeOf = typeof gettingKeysOfTextInputs[item];
      if (checkTypeOf == "string") {
        if (gettingKeysOfTextInputs[item] == "Boolean") {
          objs[item] = BooleanInputControl[index];
        }
      }
    });
    this.setState({
      booleandataSaving: objs,
    });

    console.log(objs, "checking02981390213ihidlkadklas");
  };

  setInputObject = () => {
    const { textHeadingInput, SchemaData } = this.state;
    let objs: any = {};
    let gettingKeysOfTextInputs =
      SchemaData && SchemaData.length > 0 && SchemaData[0];

    Object.keys(gettingKeysOfTextInputs).map((item, index) => {
      var checkTypeOf = typeof gettingKeysOfTextInputs[item];
      if (checkTypeOf == "string") {
        if (gettingKeysOfTextInputs[item] == "text") {
          objs[item] = textHeadingInput[index];
        }
      }
    });

    this.setState({
      InputFieldsDataOnly: objs,
    });

    console.log(objs, "checking02981390213ihidlkadklas");
  };
  setImageObject = () => {
    const { ImageInputControl, SchemaData } = this.state;
    let objs: any = {};
    let gettingKeysOfTextInputs =
      SchemaData && SchemaData.length > 0 && SchemaData[0];

    Object.keys(gettingKeysOfTextInputs).map((item, index) => {
      var checkTypeOf = typeof gettingKeysOfTextInputs[item];
      if (checkTypeOf == "string") {
        if (gettingKeysOfTextInputs[item] == "image") {
          objs[item] = ImageInputControl[index];
        }
      }
    });
    this.setState({
      imageOnlyDataSave: objs,
    });

    console.log(objs, "checking02981390213ihidlkadklas");
  };

  beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      console.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      console.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  saving = () => {
    Promise.all([
      this.setInputObject(),
      this.setImageObject(),
      this.savingBooleanObjects(),
      this.savingNumberInputObject(),
    ]).then((values) => {
      // console.log(values);
      this.combineSaving();
    });
  };

  combineSaving = () => {
    //   const saveToFirebase = firebase.firestore();
    var arr2: any = [];
    const {
      SchemaData,
      InnerArraySavingss,
      order2ArrayMainSavingss,
      InputFieldsDataOnly,
      imageOnlyDataSave,
      plainObjSaving,
      RTEdataSaving,
      selectedPage,
      booleandataSaving,
      numberFieldsData,
      AllArraysOfObj,
    } = this.state;
    var plainObjects: any = {};
    var palinArrOfObj: any = {};
    var arr3ForUse : any = [];
    var order2ArrObjs: any = {};

    var loop: any = [1,2,3,4,5]

    console.log(AllArraysOfObj, "asdkaoidhwq9u3jijo", plainObjSaving);

    // if (order2ArrayMainSavingss && order2ArrayMainSavingss.length > 0) {
    //   order2ArrayMainSavingss.map((item: any) => {
    //     Object.keys(item).map((itemX: any) => {
    //       order2ArrObjs[`${itemX}`] = item[itemX]
    //     })
    //   })
    // }

    if (plainObjSaving && plainObjSaving.length > 0) {
      plainObjSaving.map((item: any, index: number) => {
        Object.keys(item).map((itemS) => {
          console.log(itemS, "asdasdkj3kl8o3ur2293", item[itemS]);
          plainObjects[`${itemS}`] = item[itemS];
        });
      });
    }

    if (AllArraysOfObj && AllArraysOfObj.length > 0) {
      AllArraysOfObj.map((item: any, indexK: any) => {
        var ss = { ...item };
        console.log(ss, "askljdoi9329ru23oir", item);
        Object.keys(item).map((itemX: any, indexL: any) => {
          console.log("oiu3289rfhkasjdns", order2ArrayMainSavingss);

          // palinArrOfObj[`${itemX}`] = [ss[itemX]]

          if (order2ArrayMainSavingss && order2ArrayMainSavingss.length > 0) {
            order2ArrayMainSavingss.map((item: any, indexP: any) => {
              Object.keys(item).map((itemD: any, indexS: number) => {
                // console.log(item[itemD][0].parrentObjectId ,'askjdh8i23y982', indexS, indexP, indexL, indexK)

                // if(item[itemD][0].parrentObjectId == indexL){

                if (arr2 && arr2.length > 0) {
                  arr2.map((itemBB: any) => {
                    if (
                      itemBB.parrentObjectId !== item[itemD][0].parrentObjectId
                    ) {
                      arr2 = [];
                    }
                  });
                }
                console.log(
                  item[itemD][0].parentArrayName,
                  "asudhiwudhde",
                  itemX
                );
                if (item[itemD][0].parentArrayName === itemX) {
                  console.log(ss[itemX], "jksahdaskjdh", item[itemD][0]);
                  arr2.push(item[itemD][0]);

                  ss[itemX][item[itemD][0].parrentObjectId][`${itemD}`] = [
                    ...arr2,
                  ];

                  console.log(ss[itemX],'check 1144')
                  Object.keys(ss[itemX]).map((items:any)=> arr3ForUse.push(ss[itemX][items]));
                  palinArrOfObj[`${itemX}`] = arr3ForUse;
                  arr3ForUse=[];
                } else {
                  Object.keys(ss[itemX]).map((items:any)=> arr3ForUse.push(ss[itemX][items]));
                  console.log(ss[itemX],'check 11')
                  palinArrOfObj[`${itemX}`] =arr3ForUse;
                  arr3ForUse=[]

                }
              });
            });
          } else {
            Object.keys(ss[itemX]).map((items:any)=> arr3ForUse.push(ss[itemX][items]));
            console.log('check 11',arr3ForUse)
            palinArrOfObj[`${itemX}`] = arr3ForUse;
            arr3ForUse=[];
          }
        });
      });
    }

    // if(order2ArrayMainSavingss && order2ArrayMainSavingss.length > 0){

    // console.log(order2ArrayMainSavingss,'aisuld932r09uoslaijd',palinArrOfObj);

    // order2ArrayMainSavingss && order2ArrayMainSavingss.length>0 && order2ArrayMainSavingss.map((item:any,index:number)=>{

    //  console.log(item,'sakdj23oirj3o2')

    // })

    // }

    const MainObj = {
      ...imageOnlyDataSave,
      ...InputFieldsDataOnly,
      ...RTEdataSaving,
      ...booleandataSaving,
      ...numberFieldsData,
      ...plainObjects,
      ...palinArrOfObj,
    };

    console.log(MainObj, "aksjdou329uroi",palinArrOfObj);

    
    // post("https://api-news.nebrascorp.com/api/layout", {
    //   obj: MainObj,
    //   title: this.state.pageData.replaceAll(" ", ""),
    // });
    post("https://api-news.nebrascorp.com/api/layout", {
      obj: MainObj,
      title: this.state.pageData.replaceAll(" ", ""),
    });
    window.location.reload();

    // here are saving ---

    // saveToFirebase.collection("PIVOT").doc('PagesData').collection(`${selectedPage.replaceAll(' ', '')}-Page-Data`).add({

    //     ...MainObj

    // });
    // window.alert("saving Forms data sucessfully")
  };

  //plain object saving functions.....

  objectPlain = (
    value: any,
    index: number,
    type: any,
    objectKey: any,
    fieldName: any
  ) => {
    console.log(value, "hamza o32u924890asklas", index);

    if (type == "text/Number") {
      plainObjS[`${fieldName}`] = value.target.value;

      // const getDatas = [...this.state.objectPlainData];

      // getDatas[index] = value.target.value;

      // this.setState({
      //     objectPlainData: getDatas
      // })
    }
    if (type == "file") {
      var datas = new FormData();
      datas.append(`${fieldName}`, value.target.files[0]);
      axios({
        method: "post",
        url: "https://api-news.nebrascorp.com/api/image-url-path",
        data: datas,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          Object.keys(res.data).map((item) => {
            plainObjS[`${item}`] = res.data[item];
          });
        })
        .catch((err) => console.log(err, "errpr"));
    }
    if (type == "richtextbox") {
      plainObjS[`${fieldName}`] = value.target.value;

      // const getDatas = [...this.state.objForRTE];

      // getDatas[index] = value.target.value;

      // this.setState({
      //     objForRTE: getDatas
      // })
    }
    if (type == "bool") {
      plainObjS[`${fieldName}`] = value;

      // const getDatas = [...this.state.objForBoolean];

      // getDatas[index] = value;

      // this.setState({
      //     objForBoolean: getDatas
      // })
    }

    this.setState({
      selectedObJKEY: plainObjS,
    });

    // console.log(this.state.selectedObJKEY,'checking plain objects',plainObjS)
  };

  innerArrayObject = (
    value: any,
    index: number,
    fieldName: any,
    isFile: any
  ) => {
    if (isFile && isFile == "file") {
      // this.getBase647(value.file.originFileObj, (url) => {

      // innerArraysData[`${fieldName}`] = value.file

      var datas = new FormData();
      datas.append(`${fieldName}`, value.target.files[0]);
      axios({
        method: "post",
        url: "https://api-news.nebrascorp.com/api/image-url-path",
        data: datas,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          Object.keys(res.data).map((item) => {
            innerArraysData[`${item}`] = res.data[item];
          });
        })
        .catch((err) => console.log(err, "errpr"));

      // console.log(value,'askjdhiuhdi3hkjwnf')

      // });
    } else {
      // innerObjectsOfArr[`${fieldName}`] = value.target.value;
      innerArraysData[`${fieldName}`] = value.target.value;
    }

    // const getDatas = [...this.state.innerObjectDataMaintain];
    // getDatas[index] = value.target.value

    this.setState({
      innerArrayDataMaintain: innerArraysData,
    });
  };

  savingIneerArray = (value: any) => {
    innerArraysData = {};
    // objArr

    const { innerArrayDataMaintain, SchemaData, innerArrayName, prevArrName } =
      this.state;

    console.log(value, "aksdjasdlk", innerArrayDataMaintain);

    if (innerArrayName == prevArrName) {
      plainInnerArraySaving.push(innerArrayDataMaintain);
      objs[`${innerArrayName}`] = plainInnerArraySaving;
    } else {
      plainInnerArraySaving = [];
      plainInnerArraySaving.push(innerArrayDataMaintain);
      objs[`${innerArrayName}`] = plainInnerArraySaving;
    }

    // objs[`${innerArrayName}`] = { ...innerArrayDataMaintain, parentArrName:innerArrayName };
    // plainInnerArraySaving.push(objs);

    console.log(objs, "9434839348934893489");
    this.setState(
      {
        InnerArraySavingss: objs,
        prevArrName: innerArrayName,
        innerArrayDataMaintain: {},
        openPaneForArrayOfObjectSection: false,
      },
      () => {
        console.log(this.state.InnerArraySavingss, "checking objects data");
      }
    );
  };

  savingPlainObjectData = (objName: any, orignalObj: any) => {
    var setInnerArr: any = {};
    var innerObJSs: any = [],
      keysF: any = [],
      savingObjs: any = [];
    var ss: any = {};
    const { innerArrayName, InnerArraySavingss } = this.state;

    // console.log(ss, 'askjdhaiuhwiduqwh')

    plainObjS = {};
    var objs: any = {};

    objs[`${objName}`] = {
      ...this.state.selectedObJKEY,
      ...InnerArraySavingss,
    };

    plainObjectSaving.push(objs);

    console.log(plainObjectSaving, "aksdj3o2j902iwkdq");

    this.setState(
      {
        plainObjSaving: plainObjectSaving,
        innerArrayName: "",
        InnerArraySavingss: {},
      },
      () => {
        plainInnerArraySaving = [];
        console.log(this.state.plainObjSaving, "kasdhjkqoiu39r2243");
      }
    );
  };

  //
  //arrays saving start

  setArraysValues = (value: any, index: number, fieldName: any) => {
    settingArraysKeys[`${fieldName}`] = value.target.value;

    this.setState(
      {
        arrayInputsOnly: settingArraysKeys,
      },
      () => console.log("hamza 123")
    );
  };

  // savingArraysOnlyValues = (arrName: any) => {
  //   const objs: any = {}
  //   settingArraysKeys = {}
  //   const { arrayIndexInParentBody, arrayInputsOnly, openPaneForArraySection } =
  //     this.state

  //   objs[`${arrName}`] = arrayInputsOnly
  //   // var obj = {};

  //   // obj[arrayName] = arrayInputsOnly;

  //   savingArrayss.push(objs)

  //   console.log(objs, 'checking finalizing arrays', savingArrayss)

  //   this.setState(
  //     {
  //       arraysObjectSavings: savingArrayss,
  //       arrayInputsOnly: {},
  //       openPaneForArraySection: false
  //     },
  //     () => {
  //       plainInnerObjectSaving = []
  //       savingArrayss = []
  //       settingArraysKeys = {}

  //       this.saveValues(arrName)
  //     }
  //   )
  // }

  saveValues = (arrayName: any) => {
    const {
      InnerObjsSavingss,
      arraysObjectSavings,
      order2ArrayMainSavingss,
      objectNameRec,
    } = this.state;
    const keyValues: any = [];
    const saveMainObj: any = {};

    // console.log(arraysObjectSavings, 'asjkdhkhwir23', InnerObjsSavingss)

    // arraysObjectSavings &&
    //   arraysObjectSavings.length > 0 &&
    //   arraysObjectSavings.map((item: any, index: number) => {
    //     keyValues.push(item[`${arrayName}`])
    //   })

    console.log(InnerObjsSavingss, "laksjdo32u90sdc1234", keyValues);

    saveMainObj[`${arrayName}`] = {
      ...InnerObjsSavingss[`${arrayName}`],
    };

    console.log(saveMainObj, "iu23y8923y98husaj");

    if (finalArrayOfObjects && finalArrayOfObjects.length > 0) {
      finalArrayOfObjects.map((item: any, index: number) => {
        Object.keys(item).map((itemS: any) => {
          if (itemS == arrayName) {
            finalArrayOfObjects.splice(index);
          }
        });
      });
      finalArrayOfObjects.push({ ...saveMainObj });
    } else {
      finalArrayOfObjects.push({ ...saveMainObj });
    }

    console.log(finalArrayOfObjects, "2983ur8idhkjefnksjaf");

    this.setState(
      {
        AllArraysOfObj: finalArrayOfObjects,
        InnerObjsSavingss: {},
        // arraysObjectSavings: []
      }
      // () => console.log(this.state.AllArraysOfObj, 'kalsj932u90234092840')
    );
  };

  innerObject = (value: any, index: number, fieldName: any, isFile: any) => {
    if (isFile && isFile == "file") {
      // this.getBase647(value.file.originFileObj, (url) => {

      // innerObjectsOfArr[`${fieldName}`] = value.file

      var datas = new FormData();
      datas.append(`${fieldName}`, value.target.files[0]);
      axios({
        method: "post",
        url: "https://api-news.nebrascorp.com/api/image-url-path",
        data: datas,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          Object.keys(res.data).map((item) => {
            innerObjectsOfArr[`${item}`] = res.data[item];
          });
        })
        .catch((err) => console.log(err, "errpr"));

      // console.log(value,'askjdhiuhdi3hkjwnf')

      // });
    } else {
      innerObjectsOfArr[`${fieldName}`] = value.target.value;
    }

    // const getDatas = [...this.state.innerObjectDataMaintain];
    // getDatas[index] = value.target.value
    console.log("jkashdi924384", innerObjectsOfArr);
    this.setState({
      innerObjectDataMaintain: innerObjectsOfArr,
    });
  };

  savingIneerObj = (objectData: any) => {
    const objsT: any = {};
    innerObjectsOfArr = {};

    const {
      innerObjectDataMaintain,

      arrayName,
    } = this.state;

    // console.log('checking objects data in main saving', order2ArrayMainSavingss)

    if (arrayName != this.state.objectNameRec) {
      plainInnerObjectSaving = [];
    }

    plainInnerObjectSaving.push(innerObjectDataMaintain);

    objsT[`${arrayName}`] = { ...plainInnerObjectSaving };

    console.log(objectData, "askdh388ur298hkdsndkx");

    this.setState(
      {
        InnerObjsSavingss: { ...objsT },
        objectNameRec: arrayName,
        innerObjectDataMaintain: {},
        openPaneForArraySection: false,
      },
      () => {
        this.saveValues(arrayName);
        let work = Object.keys(objectData).filter((item: any) =>
          Array.isArray(objectData[item])
        );
        console.log(work, "asiuey39q83ruqowi");
        if (work && work.length > 0) {
          ++incrementOnSave;
        }
      }
    );
  };

  setDataForTable = (value: any) => {
    dataTables.push(value);
    console.log(dataTables, "aiudy9832ur9q");
  };

  order2ArrayValues = (value: any, fieldName: any, isFile: any) => {
    if (isFile && isFile == "file") {
      // this.getBase647(value.file.originFileObj, (url) => {

      // order2Array[`${fieldName}`] = value.file

      var datas = new FormData();
      datas.append(`${fieldName}`, value.target.files[0]);
      axios({
        method: "post",
        url: "https://api-news.nebrascorp.com/api/image-url-path",
        data: datas,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          Object.keys(res.data).map((item) => {
            order2Array[`${item}`] = res.data[item];
          });
        })
        .catch((err) => console.log(err, "errpr"));

      // console.log(value,'askjdhiuhdi3hkjwnf')

      // });
    } else {
      order2Array[`${fieldName}`] = value.target.value;
    }

    // const getDatas = [...this.state.innerObjectDataMaintain];
    // getDatas[index] = value.target.value

    this.setState({
      order2ArrayObj: order2Array,
    });
  };

  saveOrder2ArraySaving = () => {
    const objs: any = {};
    order2Array = {};

    const { order2ArrayObj, order2parrentArrayName, arrayName } = this.state;

    objs[`${order2parrentArrayName}`] = [
      {
        ...order2ArrayObj,
        parentArrayName: arrayName,
        parrentObjectId: incrementOnSave,
      },
    ];

    order2ArrayDatasSAving.push(objs);

    // objs[`${innerArrayName}`] = { ...plainInnerObjectSaving };

    this.setState(
      {
        order2ArrayMainSavingss: order2ArrayDatasSAving,
        order2ArrayObj: {},
        open2orderArray: false,
      },
      () => {
        console.log(
          this.state.order2ArrayMainSavingss,
          "checking objects data"
        );
      }
    );
  };

  //
  getSchema = (value: any) => {
    // ${{optionalQueryObject : value.target.value}}x
    // console.log(value, 'asldjquiw')
    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;
  
    axios
      .get(`https://api-news.nebrascorp.com/api/schema`, {
        params: {
          collection: value.target.value,
          orgName: userChecks.companyColName
        },
      })
      .then((res) => {
        console.log(res, "sadkjahsduiew");

        this.setState({
          SchemaData: [res.data.data],
          pageData: value.target.value,
        });
      });
  };

  render() {
    // if(this.props.router!==undefined && this.props.router.location.state){

    //   this.getSchema(this.props.router.location.state.data);
    // }

    const {
      pageData,
      SchemaData,
      fileList,
      pagesData,
      AllArraysOfObj,
      create,
    } = this.state;
    const ValuesOfSchemaObj =
      SchemaData && SchemaData.length > 0 && SchemaData[0];
    console.log(ValuesOfSchemaObj, "ajskhdiu3e23e89wq", SchemaData);

    const handleChange = (info: any, index: number) => {
      const getDatas = [...this.state.ImageInputControl];
      getDatas[index] = info.file;

      this.setState({
        ImageInputControl: getDatas,
      });
    };

    console.log(pagesData, "asdkjhwa8duh398rfq", ValuesOfSchemaObj);
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* <Breadcrumbs title='Pages' breadcrumbItem='Page Layout' /> */}
            <Breadcrumbs
              title="Pages"
              breadcrumbItem={
                pageData || (
                  <Skeleton.Input active={true} size="small" block={false} />
                )
              }
            />

            <br />

            <Button
              color="primary"
              style={{ float: "right" }}
              onClick={() => this.setState({ create: true })}
            >
              Create Layout
            </Button>
            {create && (
              <>
                <FormGroup row>
                  <Label
                    sm={1}
                    style={{ textTransform: "capitalize", textAlign: "right" }}
                    for="examplePassword"
                  >
                    Select Page{" "}
                  </Label>
                  <Col sm={11}>
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      // value={pageData}
                      style={{ width: "50%" }}
                      onChange={(e) => this.getSchema(e)}
                    >
                      <option disabled selected hidden>Select Page Layout</option>
                      {pagesData !== undefined ? (
                        Object.keys(pagesData).map((item, index) => (
                          <option key={index}>
                            {pagesData[item].pageName || (
                              <Skeleton active paragraph={{ rows: 5 }} />
                            )}
                          </option>
                        ))
                      ) : (
                        <Skeleton active paragraph={{ rows: 5 }} />
                      )}
                    </Input>
                  </Col>
                </FormGroup>

                <Container fluid className="dynamic-rend-comp">
                  <Row>
                    {pageData && pageData !== undefined ? (
                      <Card>
                        {/* // <div> */}

                        {/* <CardHeader>
                      <h3> {pageData} Page </h3>
                    </CardHeader> */}
                        <CardBody>
                          <>
                            {Object.keys(ValuesOfSchemaObj).map(
                              (item: any, index: number) => {
                                var schemaType = typeof ValuesOfSchemaObj[item];

                                console.log(
                                  schemaType,
                                  "askjdhasidsasahawi",
                                  ValuesOfSchemaObj[item]
                                );

                                if (ValuesOfSchemaObj[item] == "text") {
                                  return (
                                    <Container fluid key={index}>
                                      <Card>
                                        <CardBody>
                                          <FormGroup key={index}>
                                            <Label for="examplePassword">
                                              {item}
                                            </Label>

                                            <Input
                                              id="examplePassword"
                                              name="Field Length"
                                              placeholder="Enter Field Length"
                                              type="text"
                                              onChange={(e) =>
                                                this.inputOnChangeValues(
                                                  e.target.value,
                                                  index
                                                )
                                              }
                                            />
                                          </FormGroup>
                                        </CardBody>
                                      </Card>
                                    </Container>
                                  );
                                } else if (ValuesOfSchemaObj[item] == "image") {
                                  return (
                                    <Container fluid key={index}>
                                      <Card>
                                        <CardBody>
                                          <FormGroup key={index}>
                                            <Label for="examplePassword">
                                              {item}
                                            </Label>

                                            <Input
                                              id="exampleFile"
                                              name="file"
                                              type="file"
                                              onChange={(e) =>
                                                handleChange(e, index)
                                              }
                                            />


                                            {/* <Upload
                                          name='avatar'
                                          listType='picture-card'
                                          className='avatar-uploader'
                                          showUploadList={false}
                                          action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                          beforeUpload={this.beforeUpload}
                                          onChange={e => handleChange(e, index)}
                                        >
                                          {uploadButton}
                                        </Upload> */}
                                          </FormGroup>
                                        </CardBody>
                                      </Card>
                                    </Container>
                                  );
                                } else if (
                                  ValuesOfSchemaObj[item] == "boolean"
                                ) {
                                  return (
                                    <Container fluid key={index}>
                                      <Card>
                                        <CardBody>
                                          <FormGroup>
                                            <Label for="examplePassword">
                                              {item}
                                            </Label>
                                            <Input
                                              id="exampleSelect"
                                              name="select"
                                              type="select"
                                              onChange={(e) =>
                                                this.BooleanDataInput(e, index)
                                              }
                                            >
                                              <option>Select a Value</option>
                                              <option>True</option>
                                              <option>false</option>
                                            </Input>
                                          </FormGroup>
                                        </CardBody>
                                      </Card>
                                    </Container>
                                  );
                                } else if (
                                  ValuesOfSchemaObj[item] == "number"
                                ) {
                                  return (
                                    <Container fluid key={index}>
                                      <Card>
                                        <CardBody>
                                          <FormGroup>
                                            <Label for="examplePassword">
                                              {item}
                                            </Label>
                                            <Input
                                              id="exampleSelect"
                                              name="select"
                                              type="number"
                                              onChange={(e) =>
                                                this.numberInputControl(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                          </FormGroup>
                                        </CardBody>
                                      </Card>
                                    </Container>
                                  );
                                } else if (
                                  ValuesOfSchemaObj[item] == "richTextBox"
                                ) {
                                  return (
                                    <Container fluid key={index}>
                                      <Card>
                                        <CardBody>
                                          <Label for="examplePassword">
                                            {item}
                                          </Label>
                                          {/* <RichTextEditor
                                      value={this.state.rteValue}
                                      onChange={e => this.rteOnChange(e, index)}
                                    /> */}
                                        </CardBody>
                                      </Card>
                                    </Container>
                                  );
                                }

                                //   else if (
                                //     schemaType == 'object' &&
                                //     !Array.isArray(ValuesOfSchemaObj[item])
                                //   ) {
                                //     return <>pure obj saving</>
                                //   } else if (
                                //     schemaType == 'object' &&
                                //     Array.isArray(ValuesOfSchemaObj[item])
                                //   ) {
                                //     return <>array saving</>
                                //   }
                              }
                            )}

                            {Object.keys(ValuesOfSchemaObj).map((item) => {
                              var schemaType = typeof ValuesOfSchemaObj[item];
                              console.log(
                                Array.isArray(ValuesOfSchemaObj[item]),
                                "checking whether it is array or not",
                                ValuesOfSchemaObj[item],
                                "asdhiuwd",
                                item,
                                ValuesOfSchemaObj
                              );
                              if (
                                schemaType == "object" &&
                                !Array.isArray(ValuesOfSchemaObj[item])
                              ) {
                                return (
                                  <>
                                    <Card>
                                      <CardBody>
                                        {/* <h4>{item}</h4> */}
                                        <div style={{}}>
                                          <>
                                            {Object.keys(
                                              ValuesOfSchemaObj[item]
                                            ).map(
                                              (items: any, index: number) => (
                                                <div key={index}>
                                                  <br />
                                                  {ValuesOfSchemaObj[item][
                                                    items
                                                  ].controlType == "text" ||
                                                  ValuesOfSchemaObj[item][items]
                                                    .controlType == "number" ||  ValuesOfSchemaObj[item][items]
                                                    .controlType == "RTE" ? (
                                                    <FormGroup row>
                                                      <Label
                                                        for="examplePassword"
                                                        sm={2}
                                                        style={{
                                                          textAlign: "right",
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        {items}
                                                      </Label>
                                                      <Col sm={4}>
                                                        <Input
                                                          id="exampleSelect"
                                                          name="select"
                                                          onChange={(e) =>
                                                            this.objectPlain(
                                                              e,
                                                              index,
                                                              "text/Number",
                                                              item,
                                                              items
                                                            )
                                                          }
                                                        />
                                                      </Col>
                                                    </FormGroup>
                                                  ) : ValuesOfSchemaObj[item][
                                                      items
                                                    ].controlType == "image" ? (
                                                    // <div
                                                    //   style={{
                                                    //     display: 'flex',
                                                    //     marginLeft: 10,
                                                    //     marginTop: 10
                                                    //   }}
                                                    // >
                                                    <FormGroup row>
                                                      <Label
                                                        for="examplePassword"
                                                        sm={2}
                                                        style={{
                                                          textAlign: "right",
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        {items}
                                                      </Label>
                                                      <Col sm={4}>
                                                        <Input
                                                          id="exampleFile"
                                                          name="file"
                                                          type="file"
                                                          onChange={(e) =>
                                                            this.objectPlain(
                                                              e,
                                                              index,
                                                              "file",
                                                              item,
                                                              items
                                                            )
                                                          }
                                                        />

                                            <p style={{color: "#74788D" , fontSize: "12px"}}>{ValuesOfSchemaObj[item][items].inputValidation} </p>

                                                      </Col>

                                                      {/* <Upload
                                                    name='avatar'
                                                    listType='picture-card'
                                                    className='avatar-uploader'
                                                    showUploadList={false}
                                                    action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                                    beforeUpload={
                                                      this.beforeUpload
                                                    }
                                                    onChange={e =>
                                                      this.objectPlain(
                                                        e,
                                                        index,
                                                        'file',
                                                        item,
                                                        items
                                                      )
                                                    }
                                                  >
                                                    {uploadButton}
                                                  </Upload> */}
                                                    </FormGroup>
                                                  ) : // {/* </div> */}


                                                  ValuesOfSchemaObj[item][items]
                                                      .controlType ==
                                                    "richtextBox" ? (
                                                    <textarea
                                                      onChange={(e) =>
                                                        this.objectPlain(
                                                          e,
                                                          index,
                                                          "richtextbox",
                                                          item,
                                                          items
                                                        )
                                                      }
                                                    ></textarea>
                                                  ) 

                                                  : ValuesOfSchemaObj[item][
                                                      items
                                                    ].controlType ==
                                                    "Boolean" ? (
                                                    <FormGroup>
                                                      <Label
                                                        for="examplePassword"
                                                        sm={2}
                                                        style={{
                                                          textAlign: "right",
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        {item}
                                                      </Label>
                                                      <Col sm={4}>
                                                        <Input
                                                          id="exampleSelect"
                                                          name="select"
                                                          type="select"
                                                          onChange={(e) =>
                                                            this.objectPlain(
                                                              e,
                                                              index,
                                                              "bool",
                                                              item,
                                                              items
                                                            )
                                                          }
                                                        >
                                                          <option>
                                                            Select a Value
                                                          </option>
                                                          <option>True</option>
                                                          <option>false</option>
                                                        </Input>
                                                      </Col>
                                                    </FormGroup>
                                                  ) : Array.isArray(
                                                      ValuesOfSchemaObj[item][
                                                        items
                                                      ]
                                                    ) ? (
                                                    <>
                                                      {/* Table comes here  */}
                                                      {}
                                                      {/* ends here  */}
                                                      <FormGroup row>
                                                        <Label
                                                          for="examplePassword"
                                                          sm={2}
                                                          style={{
                                                            textAlign: "right",
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {items}
                                                        </Label>
                                                        <Col sm={10}>
                                                          <Button
                                                            color="primary"
                                                            onClick={() =>
                                                              this.setState({
                                                                openPaneForArrayOfObjectSection:
                                                                  true,
                                                                innerArrayData:
                                                                  ValuesOfSchemaObj[
                                                                    item
                                                                  ][items],
                                                                innerArrayName:
                                                                  items,
                                                              })
                                                            }
                                                          >
                                                            Add +
                                                          </Button>
                                                        </Col>
                                                      </FormGroup>
                                                      <br />
                                                    </>
                                                  ) : null}
                                                </div>
                                              )
                                            )}
                                            <br />
                                            <br />
                                            <Button
                                              color="primary"
                                              danger
                                              onClick={() =>
                                                this.savingPlainObjectData(
                                                  item,
                                                  ValuesOfSchemaObj[item]
                                                )
                                              }
                                            >
                                              Done
                                            </Button>
                                          </>
                                        </div>
                                        {/* </div> */}
                                      </CardBody>
                                    </Card>
                                  </>
                                );
                              }
                            })}

                            {Object.keys(ValuesOfSchemaObj).map((item) => {
                              var schemaType = typeof ValuesOfSchemaObj[item];
                              if (
                                schemaType == "object" &&
                                Array.isArray(ValuesOfSchemaObj[item])
                              ) {
                                return (
                                  <>
                                    <Card>
                                      <CardBody>
                                        <h4>{item} </h4>
                                        <div style={{}}>
                                          <>
                                            {ValuesOfSchemaObj[item].map(
                                              (items: any, index: number) => {
                                                if (
                                                  typeof items == "object" &&
                                                  !Array.isArray(items)
                                                ) {
                                                  return (
                                                    <>
                                                      {/* table Starts here */}
                                                      {AllArraysOfObj &&
                                                        AllArraysOfObj.length >
                                                          0 && (
                                                          <div className="table-responsive">
                                                            <Table className="table mb-0">
                                                              <thead className="table-light">
                                                                <tr>
                                                                  <th>#</th>
                                                                  {AllArraysOfObj &&
                                                                    AllArraysOfObj.length >
                                                                      0 &&
                                                                    AllArraysOfObj.map(
                                                                      (
                                                                        itemF: any,
                                                                        indexG: number
                                                                      ) =>
                                                                        //  console.log(itemF && itemF[item]!==undefined && itemF[item][0]!==undefined && itemF[item][0],'askjdhiew98')

                                                                        Object.keys(
                                                                          itemF[
                                                                            item
                                                                          ] !==
                                                                            undefined &&
                                                                            itemF[
                                                                              item
                                                                            ][0] !==
                                                                              undefined &&
                                                                            itemF[
                                                                              item
                                                                            ][0]
                                                                        ).map(
                                                                          (
                                                                            itemG: any,
                                                                            indexD: number
                                                                          ) => {
                                                                            console.log(
                                                                              itemG,
                                                                              "asldj2398ru293rjio"
                                                                            );

                                                                            return (
                                                                              <th
                                                                                key={
                                                                                  indexD +
                                                                                  1
                                                                                }
                                                                              >
                                                                                {
                                                                                  itemG
                                                                                }
                                                                              </th>
                                                                            );
                                                                          }
                                                                        )
                                                                    )}
                                                                </tr>
                                                              </thead>
                                                              <tbody>
                                                                {AllArraysOfObj &&
                                                                  AllArraysOfObj.length >
                                                                    0 &&
                                                                  AllArraysOfObj.map(
                                                                    (
                                                                      itemL: any,
                                                                      indexL: number
                                                                    ) =>
                                                                      Object.keys(
                                                                        itemL[
                                                                          item
                                                                        ] !==
                                                                          undefined &&
                                                                          itemL[
                                                                            item
                                                                          ] !==
                                                                            undefined &&
                                                                          itemL[
                                                                            item
                                                                          ]
                                                                      ).map(
                                                                        (
                                                                          itemO: any,
                                                                          indexP: number
                                                                        ) => (
                                                                          // console.log(itemL[ item ],'alskdjiwhieuw',itemO)
                                                                          <tr
                                                                            key={
                                                                              indexP
                                                                            }
                                                                          >
                                                                            <th scope="row">
                                                                              {indexP +
                                                                                1}
                                                                            </th>

                                                                            {Object.keys(
                                                                              itemL[
                                                                                item
                                                                              ][
                                                                                itemO
                                                                              ]
                                                                            ).map(
                                                                              (
                                                                                itemJ: any,
                                                                                indexH: number
                                                                              ) => (
                                                                                // console.log(itemJ,'asiduheir892ru3')

                                                                                <>
                                                                                  <td>
                                                                                    {
                                                                                      itemL[
                                                                                        item
                                                                                      ][
                                                                                        itemO
                                                                                      ][
                                                                                        itemJ
                                                                                      ]
                                                                                    }
                                                                                  </td>
                                                                                </>
                                                                              )
                                                                            )}
                                                                          </tr>
                                                                        )
                                                                      )
                                                                  )}
                                                              </tbody>
                                                            </Table>
                                                          </div>
                                                        )}

                                                      {/* ends here */}
                                                      <FormGroup>
                                                        <br />
                                                        <Button
                                                          outline
                                                          onClick={() =>
                                                            this.setState({
                                                              openPaneForArraySection:
                                                                true,
                                                              arraySectionData:
                                                                [items],
                                                              arrayName: item,
                                                              arrayIndexInParentBody:
                                                                index,
                                                            })
                                                          }
                                                          style={{
                                                            height: "10vh",
                                                            width: "6%",
                                                            margin:
                                                              "5px 5px 5px 5px",
                                                            // border: '2px dashed black'
                                                          }}
                                                        >
                                                          ➕
                                                        </Button>
                                                      </FormGroup>
                                                    </>
                                                  );
                                                }
                                              }
                                            )}
                                            <br />
                                            <br />

                                            {/* <Button
                                          color='primary'
                                          onClick={() =>
                                            this.savingArraysOnlyValues(item)
                                          }
                                        >
                                          Save Array
                                        </Button> */}
                                          </>
                                        </div>
                                      </CardBody>
                                    </Card>

                                    {/* </div> */}
                                  </>
                                );
                              }
                            })}
                          </>
                        </CardBody>

                        <Button color="primary" onClick={this.saving}>
                          Save
                        </Button>

                        {/* // </div> */}
                      </Card>
                    ) : 

                    //skeleton loader code
                    <ConfigProvider
                    theme={{
                      token: {
                      
                    
                        colorFill: "#edebeb",
                    
                        colorFillContent: "#faf5f5"
                      },
                    }}
                    >
                    <Card> 
                      <CardBody>

                        <Card>
                          <CardBody>
                            <br /><br />
                            <Container row>
                              <Row>
                                <Col sm={2} > <Skeleton  title={true}  active  paragraph={false}  /> </Col>
                                <Col sm={5} > <Skeleton.Input active  block /> </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col sm={2} > <Skeleton  title={true}  active  paragraph={false}  /> </Col>
                                <Col sm={5}> <Skeleton.Input active block /> </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col sm={2} > <Skeleton  title={true}  active  paragraph={false}  /> </Col>
                                <Col sm={5}> <Skeleton.Input active block /> </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col sm={2} > <Skeleton  title={true}  active  paragraph={false}  /> </Col>
                                <Col sm={5}>  <Skeleton.Button active size="default"  />   </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col sm={2} > <Skeleton  title={true}  active  paragraph={false}  /> </Col>
                                <Col sm={5}> <Skeleton.Input active block /> </Col>
                              </Row>
                             
                            </Container>
                            <br /> <br /><br /><br />
                            <Skeleton.Button active size="default"  />  
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <Skeleton.Input active size="small"  />  
                            <br /> <br /><br /> <br />
                            <Skeleton.Avatar active size={100} shape="square" />
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <Skeleton.Input active size="small"  />  
                            <br /><br /><br /><br />
                            <Skeleton.Avatar active size={100} shape="square" />
                          </CardBody>
                        </Card>

                        <br />
                        <Skeleton.Button active size="default" block  />  

                      </CardBody>
                    </Card>

      </ConfigProvider>


                    }
                  </Row>
                </Container>
              </>
            )}
          </Container>

          <Offcanvas
            fade={false}
            isOpen={this.state.openPaneForArraySection}
            style={{ width: "60%" }}
            scrollable
            direction="end"
            toggle={() => this.setState({ openPaneForArraySection: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ openPaneForArraySection: false })}
            ></OffcanvasHeader>

            <OffcanvasBody>
              <Container>
                <Row>
                  <Col xs={12}>
                    {this.state.arraySectionData &&
                      this.state.arraySectionData.length > 0 &&
                      this.state.arraySectionData.map(
                        (item: any, index: number) => {
                          //   console.log(this.state.arrayName, '9e0ru92ijdsi')
                          if (typeof item == "object" && !Array.isArray(item)) {
                            console.log(ValuesOfSchemaObj[item], "sdsddsdd");
                            return (
                              <Card key={index}>
                                <CardBody>
                                  <h4 style={{ textTransform: "capitalize" }}>
                                    {this.state.arrayName} Data
                                  </h4>
                                  <br />
                                  {Object.keys(item).map((itemX, indexX) => {
                                    console.log(
                                      item[itemX],
                                      "checking values of object"
                                    );
                                    if (
                                      // typeof item[itemX] !== 'object' &&
                                      !Array.isArray(item[itemX])
                                    ) {
                                      if (item[itemX].controlType == "image") {
                                        return (
                                          <FormGroup row key={indexX}>
                                            <Label
                                              sm={2}
                                              style={{
                                                textAlign: "right",
                                                textTransform: "capitalize",
                                              }}
                                              for="examplePassword"
                                            >
                                              {itemX}
                                            </Label>
                                            <Col sm={4}>
                                              <Input
                                                id="exampleFile"
                                                name="file"
                                                type="file"
                                                onChange={(e) =>
                                                  this.innerObject(
                                                    e,
                                                    index,
                                                    itemX,
                                                    "file"
                                                  )
                                                }
                                              />
                                            <p style={{color: "#74788D" , fontSize: "12px"}}>{item[itemX].inputValidation} </p>

                                            </Col>
                                          </FormGroup>
                                        );
                                      } else {
                                        return (
                                          <FormGroup row key={indexX}>
                                            <Label
                                              sm={2}
                                              style={{
                                                textAlign: "right",
                                                textTransform: "capitalize",
                                              }}
                                              for="examplePassword"
                                            >
                                              {itemX}
                                            </Label>
                                            <Col sm={4}>
                                              <Input
                                                id="examplePassword"
                                                name="Field Length"
                                                placeholder={`Enter ${itemX}`}
                                                type="text"
                                                onChange={(e) =>
                                                  this.innerObject(
                                                    e,
                                                    indexX,
                                                    itemX,
                                                    ""
                                                  )
                                                }
                                              />

                                              {
                                                item[itemX].inputValidation !== "text" &&



                                            <p style={{color: "#74788D" , fontSize: "12px"}}>{item[itemX].inputValidation} </p>
                                              }

                                            </Col>
                                          </FormGroup>
                                        );
                                      }
                                    } else if (
                                      typeof item[itemX] == "object" &&
                                      Array.isArray(item[itemX])
                                    ) {
                                      return (
                                        <FormGroup row key={indexX}>
                                          <Label
                                            for="examplePassword"
                                            sm={2}
                                            style={{
                                              textAlign: "right",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {itemX}
                                          </Label>
                                          {/* <br />
                                          <br /> */}
                                          <Col sm={4}>
                                            <Button
                                              color="primary"
                                              onClick={() =>
                                                this.setState({
                                                  open2orderArray: true,
                                                  order2arrayData: item[itemX],
                                                  order2parrentArrayName: itemX,
                                                })
                                              }
                                            >
                                              Open Array
                                            </Button>
                                          </Col>
                                        </FormGroup>
                                      );
                                    }
                                  })}
                                  <br />
                                  <Col sm={6} style={{ textAlign: "right" }}>
                                    <Button
                                      style={{ marginRight: 10 }}
                                      color="primary"
                                      onClick={() => this.savingIneerObj(item)}
                                    >
                                      Save
                                    </Button>
                                  </Col>
                                </CardBody>
                              </Card>
                            )
                          }
                        }
                      )}
                  </Col>
                </Row>
              </Container>
            </OffcanvasBody>
          </Offcanvas>

          <Offcanvas
            fade={false}
            isOpen={this.state.open2orderArray}
            style={{ width: "60%" }}
            scrollable
            direction="start"
            toggle={() => this.setState({ open2orderArray: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ open2orderArray: false })}
            ></OffcanvasHeader>

            <OffcanvasBody>
              {this.state.order2arrayData &&
                this.state.order2arrayData.length > 0 &&
                this.state.order2arrayData.map((item: any, index: number) => {
                  return (
                    // <div key={index}>
                    <Card key={index}>
                      <CardBody>
                        <h4>{this.state.order2parrentArrayName} Data</h4>
                        <br />
                        {Object.keys(item).map((itemS: any, indexX: number) => {
                          console.log(item[itemS], "dsddd");
                          if (item[itemS].controlType == "image") {
                            return (
                              <FormGroup row key={indexX}>
                                <Label
                                  for="examplePassword"
                                  sm={2}
                                  style={{
                                    textAlign: "right",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {itemS}
                                </Label>
                                <Col sm={4}>
                                  <Input
                                    id="exampleFile"
                                    name="file"
                                    type="file"
                                    onChange={(e) =>
                                      this.order2ArrayValues(e, itemS, "file")
                                    }
                                  />
                                  <p style={{color: "#74788D" , fontSize: "12px"}}>{item[itemS].inputValidation} </p>

                                </Col>
                              </FormGroup>
                            );
                          } else {
                            return (
                              <FormGroup row key={indexX}>
                                <Label
                                  for="examplePassword"
                                  sm={2}
                                  style={{
                                    textAlign: "right",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {itemS}
                                </Label>
                                <Col sm={4}>
                                  <Input
                                    id="examplePassword"
                                    name="Field Length"
                                    placeholder="Enter Data"
                                    type="text"
                                    onChange={(e) =>
                                      this.order2ArrayValues(e, itemS, "")
                                    }
                                  />
                                </Col>
                              </FormGroup>
                            );
                          }
                        })}
                        <Col sm={6} style={{ textAlign: "right" }}>
                          <Button
                            style={{ marginRight: 10 }}
                            onClick={() => this.saveOrder2ArraySaving()}
                            color="primary"
                          >
                            Save Array
                          </Button>
                        </Col>
                      </CardBody>
                    </Card>
                  );
                })}
            </OffcanvasBody>
          </Offcanvas>

          <Offcanvas
            fade={false}
            isOpen={this.state.openPaneForArrayOfObjectSection}
            style={{ width: "60%" }}
            scrollable
            direction="end"
            toggle={() =>
              this.setState({ openPaneForArrayOfObjectSection: false })
            }
          >
            <OffcanvasHeader
              toggle={() =>
                this.setState({ openPaneForArrayOfObjectSection: false })
              }
            ></OffcanvasHeader>

            <OffcanvasBody>
              <Container>
                <Row>
                  <Col xl={12}>
                    {/* <Card> */}
                    {/* <CardHeader> */}
                    {/* <h4>{this.state.innerArrayName}</h4> 
                        <br /> */}
                    {/* </CardHeader> */}
                    {/* <CardBody> */}
                    <div className="table-responsive">
                      {this.state.innerArrayData &&
                        this.state.innerArrayData.length > 0 &&
                        this.state.innerArrayData.map(
                          (item: any, index: number) => {
                            return (
                              <Card key={index}>
                                <CardBody>
                                  <h4>{this.state.innerArrayName}</h4>
                                  <br />
                                  {Object.keys(item).map((itemX, indexX) => {
                                    console.log(
                                      itemX,
                                      "checking values of object",
                                      item[itemX]
                                    );

                                    if (item[itemX].controlType == "image") {
                                      return (
                                        <FormGroup row key={index}>
                                          <Label
                                            sm={2}
                                            style={{
                                              textAlign: "right",
                                              textTransform: "capitalize",
                                            }}
                                            for="examplePassword"
                                          >
                                            {itemX}
                                          </Label>
                                          <Col sm={4}>
                                            <Input
                                              id="exampleFile"
                                              name="file"
                                              type="file"
                                              onChange={(e) =>
                                                this.innerArrayObject(
                                                  e,
                                                  indexX,
                                                  itemX,
                                                  "file"
                                                )
                                              }
                                            />

                                  <p style={{color: "#74788D" , fontSize: "12px"}}>{item[itemX].inputValidation} </p>


                                            
                                          </Col>
                                          {/* <Upload
                                                  name='avatar'
                                                  listType='picture-card'
                                                  className='avatar-uploader'
                                                  showUploadList={false}
                                                  action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                                                  beforeUpload={
                                                    this.beforeUpload
                                                  }
                                                  onChange={e =>
                                                    this.innerArrayObject(
                                                      e,
                                                      indexX,
                                                      itemX,
                                                      'file'
                                                    )
                                                  }
                                                >
                                                  {uploadButton}
                                                </Upload> */}
                                        </FormGroup>
                                      );
                                    } else {
                                      return (
                                        <FormGroup row key={indexX}>
                                          <Label
                                            sm={2}
                                            style={{
                                              textAlign: "right",
                                              textTransform: "capitalize",
                                            }}
                                            for="examplePassword"
                                          >
                                            {itemX}
                                          </Label>
                                          <Col sm={4}>
                                            <Input
                                              id="exampleSelect"
                                              name="select"
                                              onChange={(e) =>
                                                this.innerArrayObject(
                                                  e,
                                                  indexX,
                                                  itemX,
                                                  ""
                                                )
                                              }
                                            />

                                            {
                                                item[itemX].inputValidation !== "text" &&



                                            <p style={{color: "#74788D" , fontSize: "12px"}}>{item[itemX].inputValidation} </p>
                                              }
                                          </Col>
                                        </FormGroup>
                                      );
                                    }
                                  })}
                                  <br />
                                  <Col sm={6} style={{ textAlign: "right" }}>
                                    <Button
                                      style={{ marginRight: 10 }}
                                      color="primary"
                                      onClick={() =>
                                        this.savingIneerArray(item)
                                      }
                                    >
                                      Save Data
                                    </Button>
                                  </Col>
                                </CardBody>
                              </Card>
                            );
                          }
                        )}
                    </div>
                    {/* </CardBody> */}
                    {/* </Card> */}
                  </Col>
                </Row>
                {/* <Button
                  color='danger'
                  style={{ float: 'right' }}
                  onClick={() => this.saving()}
                >
                  Save Form
                </Button> */}
              </Container>
            </OffcanvasBody>

            {/* <Alert color='danger' isOpen={openAlertError}>
            Error: All Fields Are Mandatory!
          </Alert>
          <Alert color='primary' isOpen={openAlertSucess}>
            Sucess: Pages Form Submit Sucessfully!
          </Alert> */}
          </Offcanvas>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Layouts);
