import React,{useState,useEffect} from "react";

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

import PropTypes from "prop-types";
import logoSvg from "../../assets/images/logo.png";

import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";


import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from 'react-router-dom'

import { GoogleLogin } from "react-google-login";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { loginUser, socialLogin } from "../../store/actions";

import logo from "../../assets/images/logo-sm.svg";
import axios from 'axios';

import config from "../../config";
import CarouselPage from "../AuthenticationInner/CarouselPage";

interface LoginProps {
  history: object;
}

const Login = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [usersData, setUsersData] = useState([]);

  const validation = useFormik({
   
    enableReinitialize: true,

    initialValues: {

      email:  "",

      password: "",
      
    },

    validationSchema: Yup.object({

      email: Yup.string().required("Please Enter Your Email"),

      password: Yup.string().required("Please Enter Your Password"),

    }),
    onSubmit: (values) => {
    

       dispatch(loginUser({usersData,values}, props.router.navigate));
      
      // props.router.navigate('/dashboard');
      
    },
  });


  useEffect(() => {
    axios
      .get('https://api-news.nebrascorp.com/api/get-users')
      .then(res => {
        console.log(res, 'checking layout data')
        setUsersData(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])


  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));

  const signIn = (res: any, type: any) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };


  document.title = "Login | Nebras CMS Portal";
  console.log(usersData,'asdashdoiasdowq')

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logoSvg} alt="" width={200} />{" "}<br/><br />
                        <span className="logo-txt">Nebras Enterprise Web System</span>
                      </Link>
                    </div>
                    
                    <div className="auth-content my-auto">
                        <div className="text-center">
                            <h5  style={{fontSize:'20px'}}>Welcome To NEWS</h5>
                            <p className="text-muted mt-2" style={{fontSize:'15px'}} >
                            {/* Sign in to continue to NEWS. */}
                            Sign-in to continue...
                            </p>
                        </div>

                        <Form
                            className="custom-form mt-4 pt-2"
                            onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                            }}
                        >
                            {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                            <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                validation.touched.email &&
                                validation.errors.email
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                                <FormFeedback type="invalid">
                                {validation.errors.email}
                                </FormFeedback>
                            ) : null}
                            </div>

                            <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                                name="password"
                                value={validation.values.password || ""}
                                type="password"
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                validation.touched.password &&
                                validation.errors.password
                                    ? true
                                    : false
                                }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                                <FormFeedback type="invalid">
                                {validation.errors.password}
                                </FormFeedback>
                            ) : null}
                            </div>

                            <div className="row mb-4">
                            <div className="col">
                                <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="remember-check"
                                >
                                    Remember me
                                </label>
                                </div>

                                <div className="mt-3 d-grid">
                                <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                    // onClick={() => logIn()}
                                >
                                    Log In
                                </button>
                                </div>
                            </div>
                            </div>
                        </Form>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                            © {new Date().getFullYear()} Copyright Nebras Enterprise Software
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
Login.propTypes = {
  history: PropTypes.object,
};
