import React, { Component } from 'react'

// import React from 'react';
// import { Button, Modal } from 'antd';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Container
} from 'reactstrap'
import axios from 'axios'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import withRouter from '../../../../src/components/Common/withRouter'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import TableLoader from '../../../components/Common/TableLoader'

export class Organization extends Component<any, any> {
  constructor (props: any) {
    super(props)

    this.state = {
      openRightPane: false,

      name: '',
      shortName: '',
      companyUrl: '',
      phone: '',
      region: '',
      address: '',
      email: '',
      role: '',
      companyProfilePicture: '',

      openEditPane: false,
      orgEditData: [],
      organizationData: [],
      editName: '',
      editShortName: '',
      editCompanyUrl: '',
      editPhone: '',
      editRegion: '',
      editAddress: '',
      editEmail: '',
      editRole: '',
      editComapnyProfilePic: '',
      itemDataOfDelete: {},
      deleteSubmission: false,

      modal: false,
      modalName: '',

      loading: true,

      loaderRows: [1, 2, 3, 4, 5, 6],
      loaderCol: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  }
  componentDidMount (): void {
    axios.get('https://api-news.nebrascorp.com/api/get-organization').then(res => {
      console.log('checking datas for any..s', res)
      this.setState({ organizationData: res.data.data, loading: false })
    })
  }

  componentDidUpdate (prevProps: any, prevState: any): void {
    if (this.state.itemDataOfDelete !== prevState.itemDataOfDelete) {
      const obj = {
        ...this.state.itemDataOfDelete
      }

      console.log(obj, 'ssjhdsjkdhsjdhsjd')

      // axios.post(
      //   "https://api-news.nebrascorp.com/api/delete-organization",
      //   obj
      // );

      axios
        .post('https://api-news.nebrascorp.com/api/delete-organization', obj)
        .then(res => {
          console.log(res, 'asdwur2983ui')
          window.location.reload()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  submitPagesForm = () => {
    const {
      name,
      shortName,
      companyUrl,
      phone,
      region,
      address,
      email,
      role,
      companyProfilePicture
    } = this.state

    const Obj = {
      name: name,
      shortName: shortName,
      companyUrl: companyUrl,
      phone: phone,
      region: region,
      address: address,
      email: email,
      role: role,
      companyProfilePicture: companyProfilePicture
    }

    axios.post('https://api-news.nebrascorp.com/api/create-organization', {
      Obj
    })

    window.location.reload()
  }
  imageSetUpFunction = (value: any) => {
    console.log(value[0], 'ajskdhaskjdhih23uoweid')

    var datas = new FormData()
    datas.append(`OrganizationImage`, value[0])
    axios({
      method: 'post',
      url: 'https://api-news.nebrascorp.com/api/image-url-path',
      data: datas,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res.data, 'asdiu2983ue9disajkc')
        this.setState({
          companyProfilePicture: res.data.OrganizationImage
        })
      })
      .catch(err => console.log(err, 'error'))
  }

  editImageSetUpFunction = (value: any) => {
    console.log(value[0], 'ajskdhaskjdhih23uoweid')

    var datas = new FormData()
    datas.append(`OrganizationImage`, value[0])
    axios({
      method: 'post',
      url: 'https://api-news.nebrascorp.com/api/image-url-path',
      data: datas,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(res => {
        console.log(res.data, 'asdiu2983ue9disajkc')
        this.setState({
          editComapnyProfilePic: res.data.OrganizationImage
        })
      })
      .catch(err => console.log(err, 'errpr'))
  }

  submitEditOrg = () => {
    const {
      orgEditData,
      editName,
      editShortName,
      editCompanyUrl,
      editPhone,
      editRegion,
      editAddress,
      editEmail,
      editRole,
      editComapnyProfilePic
    } = this.state

    const Obj: any = {}

    Obj[`${orgEditData.name.replaceAll(' ', '')}`] = {
      shortName:
        editShortName && editShortName !== ''
          ? editShortName
          : orgEditData.shortName,
      companyUrl:
        editCompanyUrl && editCompanyUrl !== ''
          ? editCompanyUrl
          : orgEditData.companyUrl,
      phone: editPhone && editPhone !== '' ? editPhone : orgEditData.phone,
      region: editRegion && editRegion !== '' ? editRegion : orgEditData.region,
      address:
        editAddress && editAddress !== '' ? editAddress : orgEditData.address,
      email: editEmail && editEmail !== '' ? editEmail : orgEditData.email,
      role: editRole && editRole !== '' ? editRole : orgEditData.role,
      companyProfilePicture:
        editComapnyProfilePic && editComapnyProfilePic !== ''
          ? editComapnyProfilePic
          : orgEditData.companyProfilePicture
    }

    axios
      .post('https://api-news.nebrascorp.com/api/update-Organization', { Obj })
      .then(res => {
        window.location.reload()
      })

    this.setState({
      editShortName: '',
      editCompanyUrl: '',
      editPhone: '',
      editRegion: '',
      editAddress: '',
      editEmail: '',
      editRole: '',
      editComapnyProfilePic: '',
      orgEditData: [],
      openEditPane: false
    })
  }

  toggle = (data: any) => {
    this.setState({
      modal: !this.state.modal,
      modalName: data.name
    })
  }
  render () {
    const {
      orgEditData,
      editName,
      editShortName,
      editCompanyUrl,
      editPhone,
      editRegion,
      editAddress,
      editEmail,
      editRole,
      editComapnyProfilePic,
      itemDataOfDelete
    } = this.state

    console.log('slkdjsakdqw', this.state.orgEditData)

    console.log('skldnskndskd', this.state.organizationData)

    return (
      <React.Fragment>
        <div className='page-content'>
          <div className='container-fluid'>
            <Breadcrumbs title='Settings' breadcrumbItem='Organization' />
            <Button
              color='primary'
              style={{ float: 'right' }}
              onClick={() => this.setState({ openRightPane: true })}
            >
              {' '}
              Create Organization{' '}
            </Button>
            <br /> <br />
            <br />
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader>
                    {' '}
                    <h4 className='card-title'>Organization Data</h4>{' '}
                  </CardHeader>

                  <CardBody>
                    <div className='table-responsive'>
                      {!this.state.loading ? (
                        <Table className='table mb-0'>
                          <thead className='table-light'>
                            <tr>
                              <th>#</th>
                              {this.state.organizationData &&
                                this.state.organizationData.length > 0 &&
                                this.state.organizationData.map(
                                  (item: any, index: any) => {
                                    console.log(index, 'checking item s index')
                                    if (index == 0) {
                                      return Object.keys(item).map(
                                        (items: any, indexs: any) => {
                                          return Object.keys(item[items]).map(
                                            (itemx: any, indexx: any) => {
                                              return (
                                                <th key={indexx}>{itemx}</th>
                                              )
                                            }
                                          )
                                        }
                                      )
                                    }
                                  }
                                )}
                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                          </thead>

                          <tbody>
                            {

                              this.state.organizationData && this.state.organizationData.length > 0 && this.state.organizationData.map((item:any,index:any)=>{
                                return(
                                Object.keys(item).map((items:any,indexs:any)=>(

                                  <>
                                  <tr>
                                    <th scope='row'>{index + 1}</th>

                                    {
                                      Object.keys(item[items]).map((item1:any,index1:any)=>(

                                        <td key={index}>
                                          {
                                            item[items][item1]
                                          }

                                      </td>
                                        ))
                                    }


<td style={{ cursor: "pointer" }}
                                      onClick={() => this.setState({
                                        orgEditData: item[items],
                                        openEditPane: true,})
                                        }
                                      >
                                      <EditOutlined />
                                      </td>

                                      <td style={{ cursor: "pointer" }}
                                      onClick={() =>this.toggle(
                                        item[items]
                                      )}
                                      >
                                      <DeleteOutlined />
                                      </td>
                                      <Modal isOpen={this.state.modal} toggle={this.toggle}>
                                        <ModalHeader toggle={this.toggle}>Delete</ModalHeader>
                                        <ModalBody>Are You sure You Want to Delete {this.state.modalName} ?</ModalBody>
                                        <ModalFooter>
                                          <Button color="primary"
                                          onClick={() =>this.setState({
                                            itemDataOfDelete: [item],
                                            modal: false,
                                          })}
                                          >
                                          Confirm
                                          </Button>
                                          <Button color="secondary" onClick={this.toggle} >Cancel</Button>
                                        </ModalFooter>
                                      </Modal>


                                  </tr>
                                  </>


                                ))
                                )

                              })

                              // this.state.organizationData && this.state.organizationData.length>0 && this.state.organizationData.map((item:any,index:any)=>{
                              //   Object.keys(item).map((item1:any,index1:any)=>(
                              //     Object.keys()
                              //   ))
                              // })
                              // Object.keys(this.state.organizationData).map(
                              //   (item: any, index: number) => (
                              //     <>
                              //       <tr>
                              //         <th scope="row">{index + 1}</th>
                              //         {
                              //         Object.keys(
                              //           this.state.organizationData[item]
                              //         ).map((items: any, indexs: any) => (
                              //           <td key={indexs}>
                              //             {this.state.organizationData[item][items]}
                              //           </td>
                              //         ))
                              //         }
                              //         <td style={{ cursor: "pointer" }}
                              //         onClick={() => this.setState({
                              //           orgEditData: this.state.organizationData[item],
                              //           openEditPane: true,})
                              //           }
                              //         >
                              //         <EditOutlined />
                              //         </td>
                              //         <td style={{ cursor: "pointer" }}
                              //         onClick={() =>this.toggle(
                              //         this.state.organizationData[item]
                              //         )}
                              //         >
                              //         <DeleteOutlined />
                              //         </td>
                              //         <Modal isOpen={this.state.modal} toggle={this.toggle}>
                              //           <ModalHeader toggle={this.toggle}>Delete</ModalHeader>
                              //           <ModalBody>Are You sure You Want to Delete {this.state.modalName} ?</ModalBody>
                              //           <ModalFooter>
                              //             <Button color="primary"
                              //             onClick={() =>this.setState({
                              //               itemDataOfDelete: [item],
                              //               modal: false,
                              //             })}
                              //             >
                              //             Confirm
                              //             </Button>
                              //             <Button color="secondary" onClick={this.toggle} >Cancel</Button>
                              //           </ModalFooter>
                              //         </Modal>
                              //       </tr>
                              //     </>
                              //   )
                              // )
                            }
                          </tbody>
                        </Table>
                      ) : (
                        <TableLoader
                          rows={this.state.loaderRows}
                          col={this.state.loaderCol}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <Offcanvas
            fade={false}
            isOpen={this.state.openRightPane}
            style={{ width: '60%' }}
            scrollable
            direction='end'
            toggle={() => this.setState({ openRightPane: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ openRightPane: false })}
            ></OffcanvasHeader>

            <OffcanvasBody>
              <Card>
                <CardBody>
                  <h4>Organization</h4>
                  <br />

                  <Container>
                    <Form>
                      <Row>
                        {/* <Col  md={6} xs={{ offset: 2, }}> */}
                        <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Name
                          </Label>
                          <Col sm={6}>
                            <Input
                              sm={5}
                              id='examplePassword'
                              name='id'
                              placeholder='Enter Name'
                              type='text'
                              onChange={e =>
                                this.setState({ name: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Short Name
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder='Enter Short Name'
                              type='text'
                              onChange={e =>
                                this.setState({ shortName: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Company URL
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder='Enter Company URL'
                              type='text'
                              onChange={e =>
                                this.setState({ companyUrl: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Phone
                          </Label>

                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder='Enter Phone'
                              type='number'
                              onChange={e =>
                                this.setState({ phone: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Select Region
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='exampleSelect'
                              name='select'
                              type='select'
                              // placeholder='Enter Role Of User'
                              onChange={e =>
                                this.setState({ region: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                                Select Any Region
                              </option>
                              <option value='UAE'>UAE</option>
                              {/* <option value="def">def</option> */}
                              {/* <option value="ghi">ghi</option> */}
                            </Input>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Address
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder='Enter Address'
                              type='text'
                              onChange={e =>
                                this.setState({ address: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Email
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder='Enter Email'
                              type='email'
                              onChange={e =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        {/* <FormGroup row>
                      <Label sm={4} for='examplePassword'>Role</Label>
                      <Input
                        id='examplePassword'
                        name='id'
                        placeholder='Enter Role'
                        type='text'
                        onChange={e => this.setState({role:e.target.value})}
                      />
                  </FormGroup> */}

                        <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Company Profile Picture
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              // placeholder='Enter Name'
                              type='file'
                              onChange={e =>
                                this.imageSetUpFunction(e.target.files)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <Col sm={10} style={{ textAlign: 'right' }}>
                          <Button
                            color='success'
                            style={{ marginRight: 10 }}
                            onClick={() => this.submitPagesForm()}
                          >
                            Submit Form
                          </Button>
                        </Col>
                        {/* </Col> */}
                      </Row>
                    </Form>
                  </Container>
                </CardBody>
              </Card>
            </OffcanvasBody>
          </Offcanvas>

          <Offcanvas
            fade={false}
            isOpen={this.state.openEditPane}
            style={{ width: '80%' }}
            scrollable
            direction='start'
            toggle={() => this.setState({ openEditPane: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ openEditPane: false })}
            >
              {/* Organization Edit */}
            </OffcanvasHeader>

            <OffcanvasBody>
              <Card>
                <CardBody>
                  <h4>Organization Edit</h4>
                  <br />

                  <Container>
                    <Form>
                      <Row>
                        {/* <Col
                      md={6}
                      xs={{
                        offset: 2,
                      }}
                    > */}
                        <FormGroup row>
                          <Label
                            sm={3}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Name{' '}
                            <span style={{ color: 'red' }}>(non-editable)</span>{' '}
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder={orgEditData.name}
                              disabled
                              type='text'
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Short Name
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder={orgEditData.shortName}
                              type='text'
                              onChange={e =>
                                this.setState({ editShortName: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Company URL
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder={orgEditData.companyUrl}
                              type='text'
                              onChange={e =>
                                this.setState({
                                  editCompanyUrl: e.target.value
                                })
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Phone
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder={orgEditData.phone}
                              type='number'
                              onChange={e =>
                                this.setState({ editPhone: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Select Region
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='exampleSelect'
                              name='select'
                              type='select'
                              placeholder={orgEditData.region}
                              onChange={e =>
                                this.setState({ editRegion: e.target.value })
                              }
                            >
                              <option disabled selected hidden>
                                Select Any Region
                              </option>
                              <option value='UAE'>UAE</option>

                              {/* <option value="abc">abc</option>
                          <option value="def">def</option>
                          <option value="ghi">ghi</option> */}
                            </Input>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Address
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder={orgEditData.address}
                              type='text'
                              onChange={e =>
                                this.setState({ editAddress: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            sm={3}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Email
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='examplePassword'
                              name='id'
                              placeholder={orgEditData.email}
                              type='email'
                              onChange={e =>
                                this.setState({ editEmail: e.target.value })
                              }
                            />
                          </Col>
                        </FormGroup>

                        {/* <FormGroup row>
                        <Label sm={3} for="examplePassword" style={{textAlign: "right", textTransform: "capitalize"}}>Role</Label>
                        <Input
                          id="examplePassword"
                          name="id"
                          placeholder={orgEditData.role}
                          type="text"
                          onChange={(e) =>
                            this.setState({ editRole: e.target.value })
                          }
                        />
                      </FormGroup> */}

                        <FormGroup row>
                          <Label
                            sm={3}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Company Profile Picture
                            <span></span>
                          </Label>
                          {/* <div>
                          <img
                            src={`https://api-news.nebrascorp.com/pivot-media/${orgEditData.companyProfilePicture}`}
                            width={150}
                          />
                        </div> */}

                          <Col sm={1}>
                            {/* <div style={{
                            backgroundImage: `url(https://api-news.nebrascorp.com/pivot-media/${orgEditData.companyProfilePicture})`,
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            height: "40px",
                            width: "100%"
                            
                          }}>

                          </div> */}
                            <img
                              src={`https://api-news.nebrascorp.com/pivot-media/${orgEditData.companyProfilePicture}`}
                              height={40}
                              width={80}
                            />
                          </Col>

                          <Col sm={5}>
                            <Input
                              id='examplePassword'
                              name='id'
                              type='file'
                              onChange={e =>
                                this.editImageSetUpFunction(e.target.files)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <Col sm={9} style={{ textAlign: 'right' }}>
                          <Button
                            color='danger'
                            style={{ marginRight: 10 }}
                            onClick={() => this.submitEditOrg()}
                          >
                            Edit Changes
                          </Button>
                        </Col>
                        {/* </Col> */}
                      </Row>
                    </Form>
                  </Container>
                </CardBody>
              </Card>
            </OffcanvasBody>
          </Offcanvas>
        </div>
      </React.Fragment>
    )
  }
}

export default Organization
