import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "src/pages/Dashboard";

import Pages from 'src/pages/Pages'

import FormSchema  from 'src/pages/Schema/formSchema'
import PageSchema from 'src/pages/Schema/pageSchema'

//Utility
import PageStarter from "src/pages/Utility/PageStarter";
import PageFaqs from "src/pages/Utility/PageFAQs";
import Error404 from "src/pages/Utility/Error404";
import Error500 from "src/pages/Utility/Error500";

//Forms pages
import FormElements from "src/pages/Forms/FormElements";
import FormValidation from "src/pages/Forms/FormValidation";
import AdvancedPlugins from "src/pages/Forms/AdvancedPlugins";
import FormEditors from "src/pages/Forms/FormEditors";
import FormUpload from "src/pages/Forms/FormUpload";
import FormWizard from "src/pages/Forms/FormWizard";
import FormMask from "src/pages/Forms/FormMask";

import Layout from 'src/pages/Layouts';

//AuthenticationInner pages
import PageLogin from "src/pages/AuthenticationInner/PageLogin";
import PageRegister from "src/pages/AuthenticationInner/PageRegister";
import RecoverPassword from "src/pages/AuthenticationInner/RecoverPassword";
import LockScreen from "src/pages/AuthenticationInner/LockScreen";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import ForgetPassword from "src/pages/Authentication/ForgetPassword";
import UserProfile from "src/pages/Authentication/user-profile";
import AuthLogout from "../pages/AuthenticationInner/Logout";
import FormDetail from "src/pages/Forms/FormDetail";
import Users from "src/pages/Settings/Users";
import Roles from "src/pages/Settings/Roles";

import Organization from "src/pages/Settings/Organization";
import LayoutDetail from "src/pages/LayoutDetail";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  // Dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Pages
  { path: "/pages", component: <Pages />},
  {path : "/layout" , component: <Layout />},
  {path : "/layout-detail" , component: <LayoutDetail />},

  //Schema
  { path: "/form-schema", component: <FormSchema />},
  { path: "/page-schema", component: <PageSchema />},

  // Forms pages
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-validation", component: <FormValidation /> },
  { path:"/form-detail", component: <FormDetail /> },

  //Utility
  { path: "/pages-starter", component: <PageStarter /> },
  { path: "/pages-faqs", component: <PageFaqs /> },

  //Settings.
  { path: "/setting-user", component: <Users /> },
  { path: "/setting-organization", component: <Organization /> },
  { path: "/setting-role", component: <Roles /> },



  

  // User Profile
  { path: "/profile", component: <UserProfile /> },


  
 
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/login" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/recoverpw", component: <ForgetPassword /> },  

  //utility page
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
];

export { userRoutes, authRoutes };
