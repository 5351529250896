import React, { useState, useEffect } from "react";

import { Container } from "reactstrap";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Modal, ModalHeader, ModalBody, ModalFooter,

} from "reactstrap";

import { Skeleton } from "antd";


import axios, { AxiosResponse } from "axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import TableLoader from "../../components/Common/TableLoader";




const Pages = () => {
  document.title = "Pages | Nebras CMS Portal";
  const [openRightPane, setRightPane] = useState(false);
  const [openEditRightPane, setEditRightPane] = useState(false);
  const [itemDataOfEdit, setItemDataForEdit] = useState([]);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [isChilderen, setisChilderen] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [openAlertSucess, setOpenAlertSucess] = useState(false);
  const [pagesData, setPagesData] = useState<any[]>([]);

  const [loading, setloading]= useState(true)

  const [itemDataOfDelete, setItemDataForDelete] = useState({});

  const [formSubmit ,setFormSubmission] = useState(false);

  const [modal, setModal]=useState(false);
  const [modalName, setModalName]=useState("")


const loaderRows=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
const loaderCol=[1,2,3,4,5,6]
  
  const submitPagesForm = () => {
    if (id == "" || name == "" || description == "" || url == "") {
      setOpenAlertError(true);
      setTimeout(() => {
        setOpenAlertError(false);
      }, 3000);
    } else {
      const obj = {
        pageId: id,
        pageName: name,
        pageDescription: description,
        pageUrl: url,
        isChilderen: isChilderen,
      };

      console.log(obj, "askjdhaiu");

      setOpenAlertSucess(true);
      setTimeout(() => {
        setOpenAlertSucess(false);
      }, 2000);
    }
  };

  useEffect(() => {

    let userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;
    

    axios.get(`https://api-news.nebrascorp.com/api/pages-get?data=${userChecks.companyColName}`).then((res) => {
      console.log(res, "salkjdaoidwio983");
      setPagesData(res.data.data);
      setloading(false)
    });
  }, []);


  console.log(pagesData, "sdsjkdsjhfsjhsf")

  console.log(itemDataOfEdit, "askdjhawiduh3r");
  console.log(itemDataOfDelete, "DSSDSDaskdjhawiduh3r");


  // if(itemDataOfDelete !== null ){
  //   console.log(itemDataOfDelete, "shahzaubbdelt")
  //   axios.post(
  //     "http://localhost://3004/api/page-delete",
  //     itemDataOfDelete
  //   );

  // }

  useEffect(() => {
    var userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;
    
    if (formSubmit !== false) {
     
    
      const obj = {
        ...itemDataOfDelete,
      };
    

      // axios.post(
      //   "https://api-news.nebrascorp.com/api/page-delete",
      //   obj
      // )

      axios.post("https://api-news.nebrascorp.com/api/page-delete",{ data : obj , orgName: userChecks.companyColName}).then((res)=>{

    console.log(res,'asdwur2983ui')
    window.location.reload();


    }).catch((err)=>{
      console.log(err)
    })

      console.log(obj, "shahzaib")
    }
  }, [formSubmit]);

  const toggle = () =>{
    setModal(!modal)

    // console.log(data.pageName, "sjksdjkgjkgsjhghjfg")
    // setModalName(data.pageName)
  }


  console.log(modalName, "dsjdsdbd")

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
        
          <Breadcrumbs title="Pages" breadcrumbItem="Pages" />

          <Button
            color="primary"
            style={{ float: "right" }}
            onClick={() => setRightPane(true)}
          >
            Create Page
          </Button>
          <br />
          <br />
          <br />

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader> <h4 className="card-title">PAGES DATA</h4> </CardHeader>
                <CardBody>
                  <div className="table-responsive">


                    {
                    !loading   ?
  
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          {/* <th>#</th> */}
                          <th>id</th>
                          <th>Page Name</th>
                          <th>URL</th>
                          <th>Description</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      
                      <tbody>
                        {Object.keys(pagesData).map(
                          (item: any, index: number) => (
                            <>
                              <tr >
                                {/* <th scope="row">{index + 1}</th> */}

                                <td>{pagesData[item].id  }</td>
                                <td>{pagesData[item].pageName  }</td>
                                <td>{pagesData[item].url  }</td>
                                <td>{pagesData[item].description  }</td>

                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setItemDataForEdit(pagesData[item]),
                                      setEditRightPane(true);
                                  }}
                                >
                                  <EditOutlined />
                                </td>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={ ()=>{
                                    toggle();
                                    setItemDataForDelete([item]);
                                    setModalName(pagesData[item].pageName )
                                  }}
                                  >
                                  <DeleteOutlined />
                                </td>

                              </tr>
                            </>

                          )
                        )}
                      </tbody>

                    </Table>

                      :
                    
                         <TableLoader  rows={loaderRows} col={loaderCol}/>
                     
                      }
              
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal isOpen={modal} toggle={toggle} >
           <ModalHeader toggle={toggle}>Delete</ModalHeader>
           <ModalBody> Are You sure You Want to Delete {modalName} Page ?</ModalBody>
           <ModalFooter>
            <Button color="primary"  onClick={ ()=>{ setFormSubmission(true) } }> Confirm </Button>
            <Button color="secondary" onClick={toggle}>  Cancel</Button>
          </ModalFooter>
        </Modal>

        <Offcanvas
          fade={false}
          isOpen={openRightPane}
          style={{ width: "60%" }}
          scrollable
          toggle={() => setRightPane(false)}
        >
          <OffcanvasHeader toggle={() => setRightPane(false)}>
            {/* Pages Form */}
          </OffcanvasHeader>

          <OffcanvasBody>
            <Card>
              <CardBody>
                <h4>Pages Form</h4>
                <br />

              
            <Container>
              <Form>
                <Row>
                  {/* <Col md={4}>
                  <FormGroup>
                    <Label for='exampleEmail'>Key</Label>
                    <Input
                      id='exampleEmail'
                      name='Key'
                      placeholder='Enter Key'
                      type='text'
                    />
                  </FormGroup>
                </Col> */}
                  {/* <Col
                  sm={12}
                    md={6}
                    xs={{
                      offset: 2,
                      // order: 2,
                      // size: 9
                    }}
                  > */}
                    <FormGroup row>
                      <Label for="examplePassword" 
                       sm={3} style={{  textAlign: 'right', textTransform: 'capitalize', }}
                       >ID</Label>
                      <Col sm={6}>
                      <Input
                        id="examplePassword"
                        name="id"
                        placeholder="Enter Page ID"
                        type="text"
                        onChange={(e) => setId(e.target.value)}
                      />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="examplePassword"
                       sm={3} style={{  textAlign: 'right', textTransform: 'capitalize', }}
                      >Name</Label>
                       <Col sm={6}>
                      <Input
                        id="examplePassword"
                        name="name"
                        placeholder="Enter Page Name"
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                      />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="examplePassword"
                       sm={3} style={{  textAlign: 'right', textTransform: 'capitalize', }}
                      >Description</Label>
                       <Col sm={6}>
                      <Input
                        id="examplePassword"
                        name="Description"
                        placeholder="Enter Description"
                        type="text"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      </Col>
                    </FormGroup>

                    <FormGroup row
                       
                    >
                      <Label for="examplePassword"
                       sm={3} style={{  textAlign: 'right', textTransform: 'capitalize', }}
                      
                      >Url</Label>
                       <Col sm={6}>
                      <Input
                        id="examplePassword"
                        name="Url"
                        placeholder="Enter Url"
                        type="text"
                        onChange={(e) => setUrl(e.target.value)}
                      />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="examplePassword"
                       sm={3} style={{  textAlign: 'right', textTransform: 'capitalize', }}
                      
                      >isChildren</Label>
                       <Col sm={6}>
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={(e) =>
                          setisChilderen(
                            e.target.value == "true" ? true : false
                          )
                        }
                      >
                        <option>true</option>
                        <option>false</option>
                      </Input>
                      </Col>
                    </FormGroup>
                    {/* <br /> */}
                    <Col sm={9} style={{textAlign: "right"}}>
                    <Button
                    style={{marginRight: 10}}
                      color="success"
                      // style={{ float: "right" }}
                      onClick={() => submitPagesForm()}
                      >
                      Submit Form
                    </Button>
                      </Col>
                  {/* </Col> */}
                  {/* <Col md={4}>
                  <FormGroup>
                    <Label for='examplePassword'>Field Length</Label>
                    <Input
                      id='examplePassword'
                      name='Field Length'
                      placeholder='Enter Field Length'
                      type='text'
                    />
                  </FormGroup>
                </Col> */}
                </Row>
              </Form>
            </Container>
            </CardBody>
            </Card>
          </OffcanvasBody>

          <Alert color="danger" isOpen={openAlertError}>
            Error: All Fields Are Mandatory!
          </Alert>
          <Alert color="primary" isOpen={openAlertSucess}>
            Sucess: Pages Form Submit Sucessfully!
          </Alert>
        </Offcanvas>

        <Offcanvas
          fade={false}
          isOpen={openEditRightPane}
          style={{ width: "60%" }}
          scrollable
          toggle={() => setEditRightPane(false)}
        >
          <OffcanvasHeader toggle={() => setEditRightPane(false)}>
            {/* Pages Form */}
          </OffcanvasHeader>

          <OffcanvasBody>
            <Card>
              <CardBody>
                <h4> Pages Form</h4>
                <br />

                
            <Container>
              <Form>
                <Row>
                  {/* <Col
                    md={6}
                    xs={{
                      offset: 2,
                    }}
                  > */}
                    {Object.keys(itemDataOfEdit).map(
                      (item: any, index: any) => (
                        <FormGroup row key={index}>
                          <Label sm={3} style={{textAlign: "right", textTransform: "capitalize"}} for="examplePassword">{item}</Label>
                          <Col sm={6}>
                          <Input
                            id="examplePassword"
                            name="id"
                            placeholder={itemDataOfEdit[item]}
                            type="text"
                            onChange={(e) => setId(e.target.value)}
                            />
                            </Col>
                        </FormGroup>
                      )
                    )}

                    <Col sm={9} style={{textAlign: "right"}}>

                    <Button
                      color="danger"
                      style={{ marginRight: 10 }}
                      onClick={() => submitPagesForm()}
                      >
                      Edit Changes
                    </Button>
                      </Col>
                  {/* </Col> */}
                </Row>
              </Form>
            </Container>
            
              </CardBody>
            </Card>
          </OffcanvasBody>

          <Alert color="danger" isOpen={openAlertError}>
            Error: All Fields Are Mandatory!
          </Alert>
          <Alert color="primary" isOpen={openAlertSucess}>
            Sucess: Pages Form Submit Sucessfully!
          </Alert>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default Pages;
