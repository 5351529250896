import React, { Component } from 'react'
import withRouter from 'src/components/Common/withRouter'
import axios from 'axios'
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Container
} from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'


import { MdDone, MdCancel } from "react-icons/md";

import { GrClose } from "react-icons/gr";
import * as XLSX from 'xlsx';
// const obj = {}
export class FormValidation extends Component {
  constructor (props) {
    super(props)
    this.gridRef = React.createRef()

    if (this.props.router !== undefined && this.props.router.location.state) {
      this.stateData(
        this.props.router.location.state.name,
        this.props.router.location.state.data
      )
    }

    this.onGridReady = this.onGridReady.bind(this)
    this.onCellClicked = this.onCellClicked.bind(this)

    this.state = {
      gridData: [],
      name: '',
      filterKeyword: '',
      schemaData: {}
    }
  }
  componentDidUpdate (prevProps, prevState) {
    if (
      this.props.router.location?.state?.data !==
      prevProps.router.location?.state?.data
    ) {
      if (
        this.props.router !== undefined &&
        this.props.router.location !== undefined &&
        this.props.router.location.state !== undefined &&
        this.props.router.location.state !== null &&
        this.props.router.location.state.data !== undefined &&
        this.props.router.location.state.data !== null
      ) {
        this.stateData(
          this.props.router.location.state.name,
          this.props.router.location.state.data
        )
      }
    }
  }
  stateData = (name, data) => {
    let userDataS = localStorage.getItem('authUser')
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null

    console.log(data.FormName, 'asdije9uru2039ro')

    axios
      .get('https://api-news.nebrascorp.com/api/form-submission', {
        params: {
          collection: data.FormName.replaceAll(' ', ''),
          orgName: userChecks.companyColName
        }
      })
      .then(res => {
        console.log(res, 'aslidu3iwour98uriowasndk')
        this.setState({
          name: name,
          gridData: res.data.data,
          schemaData: this.props.router.location.state.data
        })
      })
  }
  // getSelectedRowData = () => {
  //   let selectedData = this.gridApi.getSelectedRows();
  //   alert(`Selected Nodes:\n${JSON.stringify(selectedData)}`);
  //   return selectedData;
  // };

  // onSelectionChanged = () => {
  //   const selectedData = this.gridApi.getSelectedRows();
  //   console.log('Selection Changed', selectedData);
  // };

  onGridReady (params) {
    console.log(params, 'askdajsld')
    this.gridRef = params
    console.log(this.gridRef)
    // this.gridColumnApi.autoSizeAllColumns();
  }

  onCellClicked = event => {
    console.log('askdjhwqiudhaskjdnasjkc', event)
    const selectedRows = gridOptions.api.getSelectedNodes()
    window.alert(JSON.stringify(selectedRows))
  }


  exportToExcel = () => {
    console.log(this.state.gridData, "griddara")
    const ws = XLSX.utils.json_to_sheet(this.state.gridData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${this.state.gridData[0].FormName.replaceAll(' Form','')}.xlsx`);
  };

  render () {
    const { gridData, schemaData } = this.state
    console.log(this.props.router, 'askdhiud23hy98r2y3hr82', gridData)


    const StatusCellRenderer = (props) => {
      const { value: status, node: { data: rowData } } = props;
      console.log('dwedwedwedwded', props );

      const handleAction = (e,status) => {
        e.preventDefault();
        e.stopPropagation();

        console.log(rowData,"wefnlewwkfnjbge")

        axios.patch('https://api-news.nebrascorp.com/api/form-update',  {status} ,

      {
        params: {
            collection: rowData?.FormName.replaceAll(' ', ''),
            orgName: rowData?.orgName,
            uid:  rowData?.uniqueFormId,
          }
        }
      )
          .then(response => {
            console.log('Action taken:', status);
            window.location.reload();
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };


     

      return status === 'Pending' ? (
        <div >
          <span>{status}</span>
          {/* <button  onClick={() => handleAction('Approved')}><MdDone size={20} color={'white'} /></button> */}
          {/* <button style={{borderRadius: '50%', border: 0 , background: 'red'}} onClick={() => handleAction('Rejected')}><MdCancel /></button> */}
          <MdDone  onClick={(e) => handleAction(e,'Approved')} color='white' style={{cursor: 'pointer' , marginLeft: '.25rem' ,background: 'green', padding: '.25rem', borderRadius: '50%'}}  size={18} />
          <GrClose  onClick={(e) => handleAction(e,'Rejected')} color='white' style={{cursor: 'pointer' , marginLeft: '.25rem' ,background: 'red', padding: '.25rem', borderRadius: '50%'}}  size={18} />
      </div>
      ) : <span>{status}</span>;
    };



    
    const rowData =
      gridData &&
      gridData.length > 0 &&
      gridData
        .sort(
          (a, b) =>
            new Date(b?.submissionDate?.split('-').reverse().join('-')) -
            new Date(a?.submissionDate?.split('-').reverse().join('-'))
        )
        .map(item => {
          console.log(item)
          const obj = {}
          Object.keys(item).map(items => {

            console.log(items)
            if (item[items] !== undefined && item[items] !== null){

              
              obj[`${items}`] = item[items]
            }
          })
          return {
            ...obj
          }
        })

    console.log(rowData, 'asjkdh32oie239')

    // const columnDefs:any = [
    //     { field: 'make', filter: 'agSetColumnFilter',
    //     filterParams: {
    //       applyMiniFilterWhileTyping: true,
    //     } },
    //     { field: 'model' },
    //     { field: 'price' }
    // ];

    const columnDefs = Object.keys(schemaData)
      .sort(
        (a, b) =>
          (schemaData[a]?.Order || Infinity) -
          (schemaData[b]?.Order || Infinity)
      )
      .map(item => {
        console.log(item, 'Dedededededede')
        //  if(item !== 'FormName' && item !== 'FormPage'){
        return {
          field: item,
          filter: 'agSetColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true
          },
          cellRendererFramework: StatusCellRenderer 

        }
      })

    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumbs title='Pages' breadcrumbItem='Page Layout' />
            <br />
            <div style={{textAlign: "right"}}>
              <button onClick={this.exportToExcel} style={{margin: "0.25rem", padding: "0.25rem 0.75rem", background: "#122042",color: "white", borderRadius: "4px", border: 0}}>Export to Excel</button>
            </div>

            <div
              className='ag-theme-balham'
              style={{ height: 650, width: '100%' }}
            >
              <AgGridReact
                ref={this.gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                overlayLoadingTemplate={
                  '<span class="ag-overlay-loading-center">Please Wait while Loading</span>'
                }
                rowHeight={40}
                style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                rowSelection={'single'}
                autoSizeColumns={true}
                onGridReady={this.onGridReady}
                // enableCellChangeFlash={false}
                // suppressColumnVirtualisation={true} // Add this line to disable column virtualisation

                onRowClicked={e => {
                  // e.stopPropagation();
                  // e.preventDefault();
                  console.log('row clicked has', e.node.data)
                  this.props.router.navigate('/form-detail', {
                    state: { rowData: e.node.data }
                  })
                }}




                defaultColDef={{
                  width: 170 ,
                  enableCellChangeFlash: false,

                }}
                pagination={true}
                paginationPageSize={60} 




              ></AgGridReact>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(FormValidation)
