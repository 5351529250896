import React, { Component } from 'react'
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Container
} from 'reactstrap'

import { PlusCircleOutlined } from '@ant-design/icons';
import { Collapse } from 'antd'

const { Panel } = Collapse

import _ from 'lodash'
import { post } from 'src/helpers/api_helper'
import RichTextEditor from 'react-rte'
import axios from 'axios'

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb'
var schemaTypeCreate: any = []
export class PageSchema extends Component<any, any> {
  constructor (props: any) {
    super(props)
    this.state = {
      openSchemaForm: false,
      noOfFields: [],
      selectScheamaType: [],
      noOfObjFields: [],
      textInputVal: '',
      selectInputVal: '',
      objTextInputVal: '',
      objSelectInputVal: '',
      pagesData:[],
      pageName:''
    }
  }
  componentDidMount (): void {
    var userDataS = localStorage.getItem("authUser");
    var userChecks = userDataS !== null ? JSON.parse(userDataS) : null;
  
    

    axios.get(`https://api-news.nebrascorp.com/api/pages-get?data=${userChecks.companyColName}`).then((res) => {
      console.log(res, "salkjdaoidwio983");
    this.setState({
      pagesData : res.data.data
    })  
    });


    axios.get(`https://api-news.nebrascorp.com/api/layout?data=${userChecks.companyColName}`).then(res => {
      console.log(res, 'sadkjahsduiew')
    })
  }

  addMoreFields = () => {
    const abcde = [...this.state.noOfFields, []]
    this.setState({
      noOfFields: abcde
    })
  }
  removeFields = (i: any) => {
    const createdobjs = [...this.state.noOfFields]
    createdobjs.splice(i)
    this.setState({
      noOfFields: createdobjs
    })
  }

  addMoreObjFields = () => {
    const abcde = [...this.state.noOfObjFields, []]
    this.setState({
      noOfObjFields: abcde
    })
  }

  removeObjFields = (i: any) => {
    const createdobjs = [...this.state.noOfObjFields]
    createdobjs.splice(i)
    this.setState({
      noOfObjFields: createdobjs
    })
  }

  handleTypesOfSchema = () => {
    const { textInputVal, selectInputVal } = this.state

    if (selectInputVal == 'Array') {
      schemaTypeCreate.push({
        typeName: textInputVal,
        selectedType: selectInputVal,
        typeArray: []
      })
    } else {
      schemaTypeCreate.push({
        typeName: textInputVal,
        selectedType: selectInputVal
      })
    }

    //console.log("Check handle type funcs", schemaTypeCreate)
    this.setState({
      selectScheamaType: schemaTypeCreate,
      typeName: '',
      selectInputVal: ''
    })
  }

  handleInnerObj = () => {
    const { objTextInputVal, objSelectInputVal } = this.state

    if (objSelectInputVal == 'Array') {
      schemaTypeCreate.push({
        typeName: objTextInputVal,
        selectedType: objSelectInputVal,
        typeArray: []
      })
    } else {
      schemaTypeCreate.push({
        typeName: objTextInputVal,
        selectedType: objSelectInputVal
      })
    }

    //console.log("Check handle type funcs", schemaTypeCreate)
    this.setState({
      selectObjScheamaType: schemaTypeCreate,
      objTextInputVal: '',
      objSelectInputVal: ''
    })
  }
  render () {
    const { noOfFields, selectInputVal,pagesData, selectScheamaType, noOfObjFields, objSelectInputVal } =
      this.state
    console.log(pagesData, 'kjaspageschecksh98ur3in')

    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumbs title='Pages' breadcrumbItem='Page Schema' />
            <Button
              color='primary'
              style={{ float: 'right', width: '15%' }}
              onClick={() => this.setState({ openSchemaForm: true })}
            >
              Create Schema
            </Button>
            <br />
            <br />
            <Table className='table mb-0'>
              <thead className='table-light'>
                <tr>
                  <th>#</th>
                  <th>Schema Id</th>
                  <th>Schema Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>1</td>
                  <td>Home Page</td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </div>
        <Offcanvas
          fade={false}
          isOpen={this.state.openSchemaForm}
          style={{ width: '100%' }}
          scrollable
          direction='end'
          toggle={() => this.setState({ openSchemaForm: false })}
        >
          <OffcanvasHeader
            toggle={() => this.setState({ openSchemaForm: false })}
          >
            Create Page Schema
          </OffcanvasHeader>

          <OffcanvasBody>

          <FormGroup row>
                          <Label
                            sm={4}
                            for='examplePassword'
                            style={{
                              textAlign: 'right',
                              textTransform: 'capitalize'
                            }}
                          >
                            Select Page
                          </Label>
                          <Col sm={6}>
                            <Input
                              id='exampleSelect'
                              name='select'
                              type='select'
                              onChange={e =>
                              this.setState({
                                pageName: e.target.value
                              })
                              
                              }
                            >
                              <option disabled selected hidden>
                                Select Page
                              </option>

                              {
                                pagesData!==undefined && Object.keys(pagesData).map((item:any,index:any)=>{

                                  return(

                                    <option key={index}>{pagesData[item].pageName}</option>
                                    )

                                
                                })
                           
                           }



                             
                            </Input>
                          </Col>

                        </FormGroup>

                        <Container fluid>
              {noOfFields &&
                noOfFields.length > 0 &&
                noOfFields.map((item: any, index: any) => (
                  <>
                    <FormGroup row key={index}>
                      <div style={{ display: 'flex' }}>
                        <Label for='examplePassword' style={{ marginRight: 5 }}>
                          <span
                            style={{
                              marginRight: 15,
                              cursor: 'pointer',
                              color: 'red'
                            }}
                            onClick={() => this.removeFields(index)}
                          >
                            X
                          </span>{' '}
                          Key
                        </Label>
                        <Col sm={4}>
                          <Input
                            id='exampleFile'
                            name='file'
                            type='text'
                            onChange={e =>
                              this.setState({ textInputVal: e.target.value })
                            }
                          />
                        </Col>

                        <Label
                          for='examplePassword'
                          style={{ marginRight: 5, marginLeft: 5 }}
                        >
                          Value
                        </Label>
                        <Col sm={4}>
                          <Input
                            id='exampleSelect'
                            name='select'
                            type='select'
                            // value={pageData}
                            // style={{ width: '50%' }}
                            onChange={e =>
                              this.setState({ selectInputVal: e.target.value })
                            }
                          >
                            <option>Select a Value</option>
                            <option>String</option>
                            <option>Number</option>
                            <option>Object</option>
                            <option>Array</option>
                            <option>Boolean</option>
                          </Input>
                        </Col>

                        <Button
                          color='primary'
                          onClick={() => this.handleTypesOfSchema()}
                        >
                          ➕
                        </Button>
                      </div>
                    </FormGroup>

                    {selectScheamaType &&
                      selectScheamaType.length > 0 &&
                      selectScheamaType.map((itemS: any, indexX: any) =>
                        itemS.selectedType == 'Object' && indexX == index ? (
                          <Collapse defaultActiveKey={['0']} key={indexX}>
                            <Panel header={'Object Pannel'} key={indexX}>
                              <FormGroup row key={index}>
                                {noOfObjFields &&
                                  noOfObjFields.length > 0 &&
                                  noOfObjFields.map(
                                    (items: any, indexs: any) => (
                                        <>
                                      <div
                                        style={{ display: 'flex' }}
                                        key={indexs}
                                      >
                                        <Label
                                          for='examplePassword'
                                          style={{ marginRight: 5 }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 15,
                                              cursor: 'pointer',
                                              color: 'red'
                                            }}
                                            onClick={() =>
                                              this.removeObjFields(index)
                                            }
                                          >
                                            X
                                          </span>{' '}
                                          Key
                                        </Label>
                                        <Col sm={4}>
                                          <Input
                                            id='exampleFile'
                                            name='file'
                                            type='text'
                                            onChange={e =>
                                              this.setState({
                                                objTextInputVal: e.target.value
                                              })
                                            }
                                          />
                                        </Col>

                                        <Label
                                          for='examplePassword'
                                          style={{
                                            marginRight: 5,
                                            marginLeft: 5
                                          }}
                                        >
                                          Value
                                        </Label>
                                        <Col sm={4}>
                                          <Input
                                            id='exampleSelect'
                                            name='select'
                                            type='select'
                                            // value={pageData}
                                            // style={{ width: '50%' }}
                                            onChange={e =>
                                              this.setState({
                                                objSelectInputVal:
                                                  e.target.value
                                              })
                                            }
                                          >
                                            <option>Select a Value</option>
                                            <option>String</option>
                                            <option>Number</option>
                                            <option>Array</option>
                                            <option>Boolean</option>
                                          </Input>
                                        </Col>

                                        <Button
                                          color='primary'
                                          onClick={() => this.handleInnerObj()}
                                        >
                                          ➕
                                        </Button>
                                      </div>
                                      {
                                        objSelectInputVal && objSelectInputVal!=='' && objSelectInputVal!=='Array' &&





                                        <FormGroup row key={indexX}>
                                            <Container>
                                            <Row> 
                                                <Col md={12}>                                          
                                            <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Control Type
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Control width
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Input Length
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Input Validation
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Is Mandatory
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Is Mandatory
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Key
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Label
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Order
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>

                                        </Col>

                                        </Row>

                                        </Container>
                                       
                                      </FormGroup>








                                      }
                                      </>
                                    )
                                  )}

                                <br />
                                <Button
                                  color='primary'
                                  style={{ float: 'right', width: '15%' }}
                                  danger
                                  onClick={() => this.addMoreObjFields()}
                                >
                                  < PlusCircleOutlined style={{
        fontSize: '25px',
      }}
 />
                                </Button>
                              </FormGroup>
                            </Panel>
                          </Collapse>
                        ) : null
                      )}
                  </>
                ))}
              <Button
                color='primary'
                style={{ float: 'right' }}
                danger
                onClick={() => this.addMoreFields()}
              >
                                  < PlusCircleOutlined style={{
        fontSize: '32px',
      }}
 />
            </Button>
              <br />
              <br />
              </Container>


          </OffcanvasBody>

          {/* <OffcanvasBody>
            <Container fluid>
              {noOfFields &&
                noOfFields.length > 0 &&
                noOfFields.map((item: any, index: any) => (
                  <>
                    <FormGroup row key={index}>
                      <div style={{ display: 'flex' }}>
                        <Label for='examplePassword' style={{ marginRight: 5 }}>
                          <span
                            style={{
                              marginRight: 15,
                              cursor: 'pointer',
                              color: 'red'
                            }}
                            onClick={() => this.removeFields(index)}
                          >
                            X
                          </span>{' '}
                          Key
                        </Label>
                        <Col sm={4}>
                          <Input
                            id='exampleFile'
                            name='file'
                            type='text'
                            onChange={e =>
                              this.setState({ textInputVal: e.target.value })
                            }
                          />
                        </Col>

                        <Label
                          for='examplePassword'
                          style={{ marginRight: 5, marginLeft: 5 }}
                        >
                          Value
                        </Label>
                        <Col sm={4}>
                          <Input
                            id='exampleSelect'
                            name='select'
                            type='select'
                            // value={pageData}
                            // style={{ width: '50%' }}
                            onChange={e =>
                              this.setState({ selectInputVal: e.target.value })
                            }
                          >
                            <option>Select a Value</option>
                            <option>String</option>
                            <option>Number</option>
                            <option>Object</option>
                            <option>Array</option>
                            <option>Boolean</option>
                          </Input>
                        </Col>

                        <Button
                          color='primary'
                          onClick={() => this.handleTypesOfSchema()}
                        >
                          ➕
                        </Button>
                      </div>
                    </FormGroup>

                    {selectScheamaType &&
                      selectScheamaType.length > 0 &&
                      selectScheamaType.map((itemS: any, indexX: any) =>
                        itemS.selectedType == 'Object' && indexX == index ? (
                          <Collapse defaultActiveKey={['0']} key={indexX}>
                            <Panel header={'Object Pannel'} key={indexX}>
                              <FormGroup row key={index}>
                                {noOfObjFields &&
                                  noOfObjFields.length > 0 &&
                                  noOfObjFields.map(
                                    (items: any, indexs: any) => (
                                        <>
                                      <div
                                        style={{ display: 'flex' }}
                                        key={indexs}
                                      >
                                        <Label
                                          for='examplePassword'
                                          style={{ marginRight: 5 }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 15,
                                              cursor: 'pointer',
                                              color: 'red'
                                            }}
                                            onClick={() =>
                                              this.removeObjFields(index)
                                            }
                                          >
                                            X
                                          </span>{' '}
                                          Key
                                        </Label>
                                        <Col sm={4}>
                                          <Input
                                            id='exampleFile'
                                            name='file'
                                            type='text'
                                            onChange={e =>
                                              this.setState({
                                                objTextInputVal: e.target.value
                                              })
                                            }
                                          />
                                        </Col>

                                        <Label
                                          for='examplePassword'
                                          style={{
                                            marginRight: 5,
                                            marginLeft: 5
                                          }}
                                        >
                                          Value
                                        </Label>
                                        <Col sm={4}>
                                          <Input
                                            id='exampleSelect'
                                            name='select'
                                            type='select'
                                            // value={pageData}
                                            // style={{ width: '50%' }}
                                            onChange={e =>
                                              this.setState({
                                                objSelectInputVal:
                                                  e.target.value
                                              })
                                            }
                                          >
                                            <option>Select a Value</option>
                                            <option>String</option>
                                            <option>Number</option>
                                            <option>Array</option>
                                            <option>Boolean</option>
                                          </Input>
                                        </Col>

                                        <Button
                                          color='primary'
                                          onClick={() => this.handleInnerObj()}
                                        >
                                          ➕
                                        </Button>
                                      </div>
                                      {
                                        objSelectInputVal && objSelectInputVal!=='' && objSelectInputVal!=='Array' &&





                                        <FormGroup row key={indexX}>
                                            <Container>
                                            <Row> 
                                                <Col md={12}>                                          
                                            <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Control Type
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Control width
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Input Length
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Input Validation
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Is Mandatory
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Is Mandatory
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Key
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Label
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                          <Label for='examplePassword' style={{ marginRight: 5 }}>
                                           
                                            Order
                                          </Label>
                                         
                  
                                        
                                          <Col sm={4}>
                                            <Input
                                              id='exampleSelect'
                                              name='select'
                                              type='text'
                                              // value={pageData}
                                              // style={{ width: '50%' }}
                                              onChange={e =>
                                                this.setState({ selectInputVal: e.target.value })
                                              }
                                            />
                                             
                                          </Col>
                  
                                          
                                        </div>

                                        </Col>

                                        </Row>

                                        </Container>
                                       
                                      </FormGroup>








                                      }
                                      </>
                                    )
                                  )}

                                <br />
                                <Button
                                  color='primary'
                                  style={{ float: 'right', width: '15%' }}
                                  danger
                                  onClick={() => this.addMoreObjFields()}
                                >
                                  < PlusCircleOutlined style={{
        fontSize: '25px',
      }}
 />
                                </Button>
                              </FormGroup>
                            </Panel>
                          </Collapse>
                        ) : null
                      )}
                  </>
                ))}
              <Button
                color='primary'
                style={{ float: 'right' }}
                danger
                onClick={() => this.addMoreFields()}
              >
                                  < PlusCircleOutlined style={{
        fontSize: '32px',
      }}
 />
            </Button>
              <br />
              <br />
              </Container>
          </OffcanvasBody> */}


          
        </Offcanvas>
      </React.Fragment>
    )
  }
}

export default PageSchema
